import { Text } from '@7shifts/sous-chef';
import { useEffect, useState } from 'react';

const RecordingTimer = () => {
    const initialState = {
        minutes: 0,
        seconds: 0
    };
    const [timer, setTimer] = useState(initialState);

    useEffect(() => {
        const interval = setInterval(
            () =>
                setTimer((prevState) => {
                    if (prevState.seconds === 59) {
                        return {
                            ...prevState,
                            minutes: prevState.minutes + 1,
                            seconds: 0
                        };
                    }
                    return { ...prevState, seconds: prevState.seconds + 1 };
                }),
            1000
        );
        return () => {
            clearInterval(interval);
        };
    }, []);

    const addZero = (n: number) => {
        return n < 10 ? '0' + n : n;
    };

    return (
        <Text as="h1" color="grey-300">
            {`${addZero(timer.minutes)}:${addZero(timer.seconds)}`}
        </Text>
    );
};

export default RecordingTimer;
