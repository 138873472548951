import {
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    IconClock
} from '@7shifts/sous-chef';
import { Queue, Setting } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteQueueModal from '../../DeleteQueueModal';
import ColorDisplay from 'sharedComponents/ColorDisplay';
import ManageQueueModal from '../../ManageQueueModal';
import { getScheduleTypeSetting } from 'domain/settings';
import EditQueueWorkingHoursModal from '../../EditQueueWorkingHoursModal';

type Props = { settings: Setting[] } & DataTableCustomComponent<Queue>;

const TableRow = ({ item, settings }: Props) => {
    const [isDeletingQueue, setIsDeletingQueue] = useState(false);
    const [isEditingQueue, setIsEditingQueue] = useState(false);
    const [isEditingWorkingHours, setIsEditingWorkingHours] = useState(false);
    const handleEdit = () => setIsEditingQueue(true);

    const actions: DataTableAction[] = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: handleEdit
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeletingQueue(true)
        }
    ];

    const scheduleType = getScheduleTypeSetting(settings);
    if (scheduleType === 'queue') {
        actions.push({
            action: 'workingHours',
            label: <IconClock />,
            onAction: (e) => {
                e.stopPropagation();
                setIsEditingWorkingHours(true);
            },
            showInKebab: false,
            buttonProps: {
                title: __('queuesPage.workingHours')
            }
        });
    }

    return (
        <>
            <DataTableRow actions={actions} onClick={handleEdit}>
                <DataTableCell columnIndex={0}>{item.name}</DataTableCell>
                <DataTableCell columnIndex={1}>
                    <ColorDisplay color={item.color} />
                </DataTableCell>
                <DataTableCell columnIndex={2}>
                    {item.greetingMessage}
                </DataTableCell>
            </DataTableRow>
            {isDeletingQueue && (
                <DeleteQueueModal
                    deletingQueue={item}
                    onClose={() => setIsDeletingQueue(false)}
                />
            )}
            {isEditingQueue && (
                <ManageQueueModal
                    editingQueue={item}
                    onClose={() => setIsEditingQueue(false)}
                />
            )}
            {isEditingWorkingHours && (
                <EditQueueWorkingHoursModal
                    queue={item}
                    onClose={() => setIsEditingWorkingHours(false)}
                />
            )}
        </>
    );
};

export default TableRow;
