import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import TemplatesPage from './pages/TemplatesPage';

export const ROUTES = [
    {
        path: '/templates',
        element: <TemplatesPage />,
        errorElement: <ErrorBoundary />
    }
];
