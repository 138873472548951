import { AsyncSelectField, SelectOption } from '@7shifts/sous-chef';
import { useEffect } from 'react';
import { getQueue, getQueues } from 'api/queuesApi';
import { getUser } from 'api/usersApi';
import { __ } from 'i18n/translate';

type Props = {
    userId?: number;
    initialQueueId?: number | null;
    onInitialOptionLoaded?: (option: SelectOption<number>) => void;
    name: string;
    label: string;
    caption?: string;
    key?: number;
};

const QueueSelectField = ({
    userId,
    initialQueueId,
    onInitialOptionLoaded,
    name,
    label,
    caption,
    key
}: Props) => {
    useEffect(() => {
        if (initialQueueId) {
            getQueue(initialQueueId).then((queue) => {
                if (onInitialOptionLoaded) {
                    onInitialOptionLoaded({
                        value: queue.id,
                        label: queue.name
                    });
                }
            });
        }
    }, [initialQueueId]);

    return (
        <AsyncSelectField
            name={name}
            label={label}
            caption={caption}
            placeholder={__("component.select.placeholder")}
            loadOptions={(search) => {
                if (userId) {
                    return getUser(userId).then((user) => {
                        return {
                            options: user.queues
                                .filter((queue) =>
                                    queue.name
                                        .toLocaleLowerCase()
                                        .includes(search.toLocaleLowerCase())
                                )
                                .map((queue) => ({
                                    value: queue.id,
                                    label: queue.name
                                })),
                            hasMore: false
                        };
                    });
                } else {
                    return getQueues().then((queues) => {
                        return {
                            options: queues
                                .filter((queue) =>
                                    queue.name
                                        .toLocaleLowerCase()
                                        .includes(search.toLocaleLowerCase())
                                )
                                .map((queue) => ({
                                    value: queue.id,
                                    label: queue.name
                                })),
                            hasMore: false
                        };
                    });
                }
            }}
            isClearable
            key={key}
        />
    );
};

export default QueueSelectField;
