import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    SelectOption,
    Stack,
    toast
} from '@7shifts/sous-chef';
import { getSchema } from './schema';
import { useFormik } from 'formik';
import QueueSelectField from 'sharedComponents/QueueSelectField';
import UserSelectField from 'sharedComponents/UserSelectField';
import { __ } from 'i18n/translate';
import { capitalizeFirstLetter } from 'utils/string';
import { transferTicketToQueue, transferTicketToUser } from 'api/ticketsApi';
import { handleError } from 'api/apiUtils';

type Props = {
    onClose: () => void;
    onTranferedTicket: () => void;
    ticketId: number;
};

const TransferTicketModal = ({
    onClose,
    onTranferedTicket,
    ticketId
}: Props) => {
    const formik = useFormik<{
        user: SelectOption<number> | null;
        queue: SelectOption<number> | null;
    }>({
        initialValues: {
            user: null,
            queue: null
        },
        validationSchema: getSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            if (values.user) {
                transferTicketToUser(ticketId, {
                    userId: values.user.value,
                    queueId: values.queue ? values.queue.value : undefined
                })
                    .then(() => {
                        toast(__('tickets.ticketTransferedToUser'));
                        onTranferedTicket();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else if (values.queue) {
                transferTicketToQueue(ticketId, values.queue.value)
                    .then(() => {
                        toast(__('tickets.ticketTransferedToQueue'));
                        onTranferedTicket();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        }
    });

    const selectedUserId = formik.values.user
        ? formik.values.user.value
        : undefined;

    return (
        <Modal header={__('tickets.transferTicket')} onClose={onClose}>
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <UserSelectField
                            name="user"
                            label={capitalizeFirstLetter(__('main.user'))}
                            onChange={() => {
                                formik.setFieldValue('queue', null);
                            }}
                        />
                        <QueueSelectField
                            name="queue"
                            label={capitalizeFirstLetter(__('main.queue'))}
                            key={selectedUserId}
                            userId={selectedUserId}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {__('tickets.transfer')}
                            </Button>
                        ),
                        secondary: <Button onClick={onClose}>{__('main.cancel')}</Button>
                    }}
                />
            </Form>
        </Modal>
    );
};

export default TransferTicketModal;
