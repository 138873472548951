import WhatsAppSidePane from '../WhatsAppSidePane';
import { Tag, Ticket } from 'types';
import './WhatsAppTagsPane.scss';
import {
    Avatar,
    Button,
    Dropdown,
    DropdownList,
    DropdownListItem,
    EmptyState,
    IconChevronDown,
    IconTrash,
    Inline,
    Text
} from '@7shifts/sous-chef';
import { assignTagsToTicket, listTags } from 'api/tagsApi';
import { useEffect, useState } from 'react';
import { handleError } from 'api/apiUtils';
import { __ } from 'i18n';
import tagsEmptyStateIllustration from 'assets/tags-empty-state.svg';

type Props = {
    onClose: () => void;
    ticket: Ticket;
};

const WhatsAppTagsPane = ({ onClose, ticket }: Props) => {
    const [selectedTags, setSelectedTags] = useState(ticket.tags);
    const [tags, setTags] = useState<Tag[]>([]);

    useEffect(() => {
        listTags()
            .then((data) => setTags(data))
            .catch(handleError);
    }, []);

    const handleAssignTag = (newTag: Tag) => {
        assignTagsToTicket({
            ticketId: ticket.id,
            tags: [newTag, ...ticket.tags]
        })
            .then(() => {
                setSelectedTags([newTag, ...selectedTags]);
            })
            .catch(handleError);
    };

    const handleRemoveTag = (tagIndex: number) => {
        const prevTags = [...selectedTags];
        prevTags.splice(tagIndex, 1);

        assignTagsToTicket({
            ticketId: ticket.id,
            tags: prevTags
        })
            .then(() => {
                setSelectedTags(prevTags);
            })
            .catch(handleError);
    };

    const selectedTagIds = selectedTags.map((tag) => tag.id);
    const availableTags = tags.filter(
        (tag) => !selectedTagIds.includes(tag.id)
    );

    return (
        <WhatsAppSidePane title={__('tickets.tags')} onClose={onClose}>
            <div className="whats-app-tags-pane__new-tag">
                <Dropdown
                    trigger={
                        <Button
                            theme="primary"
                            disabled={availableTags.length === 0}
                        >
                            {__('tickets.assignNewTag')}
                            <IconChevronDown size="medium" />
                        </Button>
                    }
                >
                    <DropdownList>
                        {availableTags.map((tag) => (
                            <DropdownListItem
                                key={tag.id}
                                onClick={() => handleAssignTag(tag)}
                            >
                                <Inline space={12} alignItems="center">
                                    <Avatar color={tag.color} size="medium">
                                        {tag.name[0].toUpperCase()}
                                    </Avatar>
                                    {tag.name}
                                </Inline>
                            </DropdownListItem>
                        ))}
                    </DropdownList>
                </Dropdown>
            </div>
            <div className="whats-app-tags-pane__tags">
                {selectedTags.length === 0 && (
                    <EmptyState
                        mediaUrl={tagsEmptyStateIllustration}
                        size="small"
                        title={__('tickets.tagsEmptyStateTitle')}
                    >
                        {__('tickets.tagsEmptyStateDescription')}
                    </EmptyState>
                )}
                {selectedTags.map((tag, index) => (
                    <div key={tag.id} className="whats-app-tags-pane__tag">
                        <Inline
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Inline alignItems="center" space={8}>
                                <Avatar color={tag.color} size="medium">
                                    {tag.name[0].toUpperCase()}
                                </Avatar>
                                <Text>{tag.name}</Text>
                            </Inline>
                            <Button
                                theme="link-icon"
                                onClick={() => handleRemoveTag(index)}
                            >
                                <IconTrash size="medium" />
                            </Button>
                        </Inline>
                    </div>
                ))}
            </div>
        </WhatsAppSidePane>
    );
};

export default WhatsAppTagsPane;
