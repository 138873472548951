import {
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    TimeField,
    Toggle
} from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import { WorkHoursItem } from '../types';
import { formatTimeAMPM } from 'utils/date';

type RowProps = {
    setFieldValue: (field: string, value: unknown) => void;
} & DataTableCustomComponent<WorkHoursItem>;
const WorkingHoursRow = ({ item, index, setFieldValue }: RowProps) => {
    const handleToggleChange = () => {
        setFieldValue(`workHours[${index}].open`, !item.open);
        if (item.open) {
            setFieldValue(`workHours[${index}].startTime`, '');
            setFieldValue(`workHours[${index}].endTime`, '');
        } else {
            setFieldValue(
                `workHours[${index}].startTime`,
                formatTimeAMPM('09:00')
            );
            setFieldValue(
                `workHours[${index}].endTime`,
                formatTimeAMPM('18:00')
            );
        }
    };

    return (
        <DataTableRow>
            <DataTableCell columnIndex={0}>
                <Toggle checked={item.open} onChange={handleToggleChange} />
            </DataTableCell>
            <DataTableCell columnIndex={1}>
                {__(`date.${item.weekdayEn}`)}
            </DataTableCell>
            <DataTableCell columnIndex={2}>
                <TimeField
                    name={`workHours[${index}].startTime`}
                    disabled={!item.open}
                    placeholder={!item.open ? '- - : - -' : ''}
                />
            </DataTableCell>
            <DataTableCell columnIndex={3}>
                <TimeField
                    name={`workHours[${index}].endTime`}
                    disabled={!item.open}
                    placeholder={!item.open ? '- - : - -' : ''}
                />
            </DataTableCell>
        </DataTableRow>
    );
};

export default WorkingHoursRow;
