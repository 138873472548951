import {
    Button,
    Chip,
    DataTable,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Inline,
    Modal,
    ModalBody,
    ModalFooter,
    Text,
    toast
} from '@7shifts/sous-chef';
import { handleError } from 'api/apiUtils';
import { sendTemplate } from 'api/metaTemplatesApi';
import { __ } from 'i18n/translate';
import { useMetaTemplates } from 'modules/Tickets/hooks/useMetaTemplates';
import { useState } from 'react';
import { MetaTemplate, Ticket } from 'types';

type Props = {
    ticket: Ticket;
    onClose: () => void;
};

const SendTemplateMessageModal = ({ ticket, onClose }: Props) => {
    console.log(ticket);
    const { loading, metaTemplates, cursor } = useMetaTemplates(ticket.whatsappId);
    const [selectedTemplate, setSelectedTemplate] = useState<
        MetaTemplate | undefined
    >();

    const [isSending, setIsSending] = useState(false);

    const handleSendTemplate = () => {
        if (!selectedTemplate) return;

        setIsSending(true);

        sendTemplate(selectedTemplate.name, ticket.uuid)
            .then(() => {
                toast('Template sent successfully');
                onClose();
            })
            .catch(handleError)
            .finally(() => setIsSending(false));
    };

    const ItemComponent = ({
        item
    }: DataTableCustomComponent<MetaTemplate>) => {
        return (
            <DataTableRow
                onClick={
                    item.status === 'APPROVED'
                        ? () => setSelectedTemplate(item)
                        : undefined
                }
                isSelected={
                    selectedTemplate && selectedTemplate.name === item.name
                }
                disabled={true}
            >
                <DataTableCell columnIndex={0}>
                    <Inline>
                        <Text
                            color={
                                item.status !== 'APPROVED'
                                    ? 'grey-300'
                                    : undefined
                            }
                        >
                            {item.name}
                        </Text>
                        <Chip
                            theme={
                                item.status === 'APPROVED'
                                    ? 'success'
                                    : 'marketing'
                            }
                        >
                            {item.status}
                        </Chip>
                    </Inline>
                </DataTableCell>
            </DataTableRow>
        );
    };

    return (
        <Modal
            header={__('tickets.sendTemplateMessage')}
            subHeader={__('tickets.sendTemplateMessageDetails')}
        >
            <ModalBody>
                <DataTable
                    items={metaTemplates}
                    columns={[{ name: 'name', label: __('tickets.template') }]}
                    isLoading={loading}
                    maxHeight={400}
                    itemComponent={ItemComponent}
                    {...cursor}
                />
            </ModalBody>
            <ModalFooter
                actions={{
                    primary: (
                        <Button
                            onClick={handleSendTemplate}
                            loading={isSending}
                            disabled={!selectedTemplate}
                        >
                            {__('tickets.send')}
                        </Button>
                    ),
                    secondary: (
                        <Button onClick={onClose}>{__('main.cancel')}</Button>
                    )
                }}
            />
        </Modal>
    );
};

export default SendTemplateMessageModal;
