import {
    IconCheckCircleIncomplete,
    IconMessages,
    IconSitemap,
    Inline
} from '@7shifts/sous-chef';
import './TicketsPage.scss';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TicketsContactsPane from '../../components/TicketsContactsPane';
import TicketsList from '../../components/TicketsList';
import WhatsAppChat from '../../components/WhatsAppChat';
import WhatsAppEmptyState from '../../components/WhatsAppEmptyState';
import { __ } from 'i18n'; 
import { useAuthContext } from 'contexts/AuthContext';

const TicketsPage = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const view = params.get('view') || 'working';
    const ticketUUId = params.get('ticket'); 
    const { user } = useAuthContext();
    const { profile } = user;
    const isAdmin = (profile === 'admin');  
    return (
        <div className="tickets-page">
            <div className="tickets-page__tabs">
                <div
                    className={classNames('tickets-page__tab-item', {
                        'tickets-page__tab-item--selected': view === 'working'
                    })}
                    onClick={() => navigate('?view=working')}
                >
                    <Inline space={8} alignItems="center">
                        <IconMessages size="medium" />
                        {__('tickets.working')}
                    </Inline>
                </div>
                <div
                    className={classNames('tickets-page__tab-item', {
                        'tickets-page__tab-item--selected':
                            view === 'waiting-room'
                    })}
                    onClick={() => navigate('?view=waiting-room')}
                >
                    <Inline space={8} alignItems="center">
                        <IconCheckCircleIncomplete size="medium" />
                        {__('tickets.waitingRoom')}
                    </Inline>
                </div>
                {isAdmin && (
                    <div
                        className={classNames('tickets-page__tab-item', {
                            'tickets-page__tab-item--selected':
                                view === 'onchatbot-room'
                        })}
                        onClick={() => navigate('?view=onchatbot-room')}
                    >
                        <Inline space={8} alignItems="center">
                            <IconSitemap size="medium" />
                            {__('tickets.onchatbotRoom')}
                        </Inline>
                    </div>
                )}
            </div>
            <div className="tickets-page__content">
                <TicketsContactsPane> 
                    <TicketsList
                        view={view} 
                    />
                </TicketsContactsPane>
                {ticketUUId ? (
                    <WhatsAppChat ticketUUId={ticketUUId} />
                ) : (
                    <WhatsAppEmptyState />
                )}
            </div>
        </div>
    );
};

export default TicketsPage;
