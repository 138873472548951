import { DataTable } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { getQuickMesssages } from 'api/quickmessagesApi';
import { QuickMessage } from 'types';
import { useRefetchList } from 'hooks/useRefetchList';
import { useAuthContext } from '../../../../contexts/AuthContext';
import emptyStateIllustration from 'assets/empty-state.svg';

const QuickMessagesTable = () => {
    const [quickMessages, setQuickMessages] = useState<QuickMessage[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { refreshToggle } = useRefetchList();

    const { user } = useAuthContext();

    useEffect(() => {
        getQuickMesssages(user.companyId, user.id)
            .then((data) => setQuickMessages(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setQuickMessages([]);
            })
            .finally(() => setIsLoading(false));
    }, [refreshToggle, user.companyId, user.id]);

    const COLUMNS = [
        {
            name: 'shortcut',
            label: __('quickMessagesPage.shortcut')
        },
        {
            name: 'message',
            label: __('quickMessagesPage.message')
        },
        {
            name: 'isGlobal',
            label: __('quickMessagesPage.global')
        }
    ];

    return (
        <DataTable
            columns={COLUMNS}
            items={quickMessages}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    context: 'female',
                    domainName: __('main.quickMessages')
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default QuickMessagesTable;
