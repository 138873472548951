import { Message } from 'types';

type Props = {
    index: number;
    amountOfMessages: number;
    message: Message;
    previousMessage: Message | null;
};

const MessageDivider = ({
    index,
    amountOfMessages,
    message,
    previousMessage
}: Props) => {
    if (index < amountOfMessages && index > 0 && previousMessage) {
        const messageUser = message.fromMe;
        const previousMessageUser = previousMessage.fromMe;

        if (messageUser !== previousMessageUser) {
            return (
                <span
                    style={{ marginTop: 16 }}
                    key={`divider-${message.id}`}
                ></span>
            );
        }
    }

    return null;
};

export default MessageDivider;
