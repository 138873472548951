import { Button, IconPlus } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageScheduleModal from '../ManageScheduleModal';

const SchedulePageActions = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconPlus size="medium" />
                {__('schedulesPage.scheduleMessage')}
            </Button>
            {showModal && (
                <ManageScheduleModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default SchedulePageActions;
