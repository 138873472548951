import { Spinner } from '@7shifts/sous-chef';
import { useMessages } from 'hooks/useMessages';
import { Message, Ticket } from 'types';
import MessageDisplay from '../MessageDisplay';
import { debounce } from 'lodash';

type Props = {
    ticketId: number;
    ticket: Ticket;
    onReplyMessage: (message: Message) => void;
};

const WhatsAppConversation = ({ ticketId, ticket, onReplyMessage }: Props) => {
    const { messagesList, loading, onScroll } = useMessages(ticketId, ticket);
    return (
        <div
            className="whats-app-chat__conversation-content"
            id="whats-app-chat-content"
            onScroll={debounce((e) => onScroll(e), 300)}
        >
            {loading && <Spinner block />}
            {messagesList.map((message, index) => (
                <MessageDisplay
                    message={message}
                    previousMessage={index > 0 ? messagesList[index - 1] : null}
                    key={message.id}
                    index={index}
                    amountOfMessages={messagesList.length}
                    isGroup={ticket.isGroup}
                    onReplyMessage={onReplyMessage}
                />
            ))}
        </div>
    );
};

export default WhatsAppConversation;
