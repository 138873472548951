import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';

export const ROUTES = [
    {
        path: '/login',
        element: <LoginPage />,
        errorElement: <ErrorBoundary />
    },
    {
        path: '/signup',
        element: <SignupPage />,
        errorElement: <ErrorBoundary />
    }
];
