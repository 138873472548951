import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import EditQueueOptionsPage from './pages/EditQueueOptionsPage';
import QueuesPage from './pages/QueuesPage';

export const ROUTES = [
    {
        path: '/queues',
        element: <QueuesPage />,
        errorElement: <ErrorBoundary />
    },
    {
        path: '/queues/:id/options',
        element: <EditQueueOptionsPage />,
        errorElement: <ErrorBoundary />
    }
];
