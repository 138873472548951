import api from '../services/api';
import { TemplateExtraInfo } from 'types';

export type GetTemplatesResponse = {
    templateExtraInfos: TemplateExtraInfo[];
    count: number;
    hasMore: boolean;
};

export const getTemplates = (
    searchParam: string,
    pageNumber: number
): Promise<GetTemplatesResponse> => {
    return api
        .get('/templateExtraInfos/', {
            params: { searchParam, pageNumber }
        })
        .then(({ data }) => data);
};

type TemplateData = {
    name: string;
    value: string;
};
export const addTemplate = (
    newTemplate: TemplateData
): Promise<TemplateExtraInfo> => {
    return api
        .post('/templateExtraInfos', newTemplate)
        .then(({ data }) => data);
};

type EditTemplateData = {
    companyId: number;
} & TemplateData;
export const editTemplate = (
    templateId: number,
    editingTemplate: EditTemplateData
): Promise<TemplateExtraInfo> => {
    return api
        .put('/templateExtraInfos/' + templateId, editingTemplate)
        .then(({ data }) => data);
};

export const deleteTemplate = (contactId: number): Promise<void> => {
    return api.delete('/templateExtraInfos/' + contactId);
};
