import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import DashboardPage from './pages/DashboardPage';

export const ROUTES = [
    {
        path: '/dashboard',
        element: <DashboardPage />,
        errorElement: <ErrorBoundary />
    }
];
