import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import SettingsPage from './pages/SettingsPage';

export const ROUTES = [
    {
        path: '/settings',
        element: <SettingsPage />,
        errorElement: <ErrorBoundary />
    }
];
