import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import SearchFilter from 'sharedComponents/SearchFilter';
import ContactsTable from '../components/ContactsTable';
import ContactsPageActions from '../components/ContactsPageActions';

const ContactsPage = () => {
    return (
        <Page
            title={__('main.contacts')}
            actions={<ContactsPageActions />}
            filterBar={<SearchFilter />}
        >
            <ContactsTable />
        </Page>
    );
};

export default ContactsPage;
