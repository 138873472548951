import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import QueuesTable from '../components/QueuesTable';
import QueuesPageActions from '../components/QueuesPageActions';

const QueuesPage = () => {
    return (
        <Page title={__('main.queues')} actions={<QueuesPageActions />}>
            <QueuesTable />
        </Page>
    );
};

export default QueuesPage;
