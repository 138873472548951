import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    SelectOption
} from '@7shifts/sous-chef';
import { handleError } from 'api/apiUtils';
import { reopenTicket } from 'api/ticketsApi';
import { useAuthContext } from 'contexts/AuthContext';
import { useFormik } from 'formik';
import { __ } from 'i18n/translate';
import { useNavigate } from 'react-router-dom';
import QueueSelectField from 'sharedComponents/QueueSelectField';
import { Ticket } from 'types';
import { capitalizeFirstLetter } from 'utils/string';
import { getSchema } from './schema';

type Props = {
    ticket: Ticket;
    onClose: () => void;
};

const ReopenTicketModal = ({ ticket, onClose }: Props) => {
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const formik = useFormik<{ queue: SelectOption<number> | null }>({
        initialValues: {
            queue: null
        },
        validationSchema: getSchema,
        onSubmit: ({ queue }, { setSubmitting }) => {
            if (!queue) {
                return;
            }
            setSubmitting(true);
            reopenTicket(ticket.id, queue.value)
                .then(() => {
                    onClose();
                    navigate('/tickets?view=working&ticket=' + ticket.uuid);
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={__('tickets.reopenTicket')}
            subHeader={__('tickets.reopenTicketMessage')}
            onClose={onClose}
        >
            <Form formik={formik} stackContent={false}>
                <ModalBody>
                    <QueueSelectField
                        name="queue"
                        label={capitalizeFirstLetter(__('main.queue'))}
                        userId={user.id}
                    />
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {__('tickets.reopenTicket')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ReopenTicketModal;
