import api from '../services/api';
import { Contact } from 'types';

export type GetContactsResponse = {
    contacts: Contact[];
    count: number;
    hasMore: boolean;
};

export const getContacts = (
    searchParam: string,
    pageNumber: number
): Promise<GetContactsResponse> => {
    return api
        .get('/contacts/', {
            params: { searchParam, pageNumber }
        })
        .then(({ data }) => data);
};

export const getContact = (contactId: number): Promise<Contact> => {
    return api.get('/contacts/' + contactId).then(({ data }) => data);
};

type ContactData = {
    name: string;
    number: string;
    email: string;
    attendantId: number | null;
    extraInfo: {
        name: string;
        value: string;
    }[];
};
export const addContact = (newContact: ContactData): Promise<Contact> => {
    return api.post('/contacts', newContact).then(({ data }) => data);
};

type EditContactData = {
    companyId: number;
} & ContactData;
export const editContact = (
    contactId: number,
    editingContact: EditContactData
): Promise<Contact> => {
    return api
        .put('/contacts/' + contactId, editingContact)
        .then(({ data }) => data);
};

export const deleteContact = (contactId: number): Promise<void> => {
    return api.delete('/contacts/' + contactId);
};

export const getContactByNumber = (phoneNumber: string): Promise<Contact> => {
    return api
        .get(`/contacts/getContactByNumber/${phoneNumber}`)
        .then(({ data }) => data);
};
