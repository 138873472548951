import { handleError } from 'api/apiUtils';
import { getMetaTemplates } from 'api/metaTemplatesApi';
import { useEffect, useState } from 'react';
import { MetaPaging, MetaTemplate } from 'types';

export const useMetaTemplates = (whatsappId?: number) => {
    const [metaTemplates, setMetaTemplates] = useState<MetaTemplate[]>([]);
    const [loading, setLoading] = useState(true);
    const [paging, setPaging] = useState<MetaPaging>();
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTemplates = async () => {
                getMetaTemplates(undefined, whatsappId)
                    .then((data) => {
                        setMetaTemplates(data.data);
                        setPaging(data.paging);
                    })
                    .catch(handleError)
                    .finally(() => setLoading(false));
            };
            fetchTemplates();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, []);

    const onPageNavigate = (cursor: string) => {
        setLoading(true);
        getMetaTemplates(cursor, whatsappId)
            .then((data) => {
                setMetaTemplates(data.data);
                setPaging(data.paging);
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    };

    return {
        metaTemplates,
        loading,
        cursor: {
            hasPrevious: !!paging?.previous,
            hasNext: !!paging?.next,
            onNextClick: () =>
                paging &&
                paging.cursors.after &&
                onPageNavigate(paging.cursors.after),
            onPreviousClick: () =>
                paging &&
                paging.cursors.before &&
                onPageNavigate(paging.cursors.before)
        }
    };
};
