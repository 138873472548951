import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import MyAccountPage from './pages/MyAccountPage';

export const ROUTES = [
    {
        path: '/my-account',
        element: <MyAccountPage />,
        errorElement: <ErrorBoundary />
    }
];
