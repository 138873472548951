import LoginForm from '../components/LoginForm';
import LogoFull from '../components/LogoFull';
import { LoginLayoutFrame } from 'app-layout-frame';
import LoginMarketingPane from '../components/LoginMarketingPane';

const LoginPage = () => {
    return (
        <LoginLayoutFrame
            logo={<LogoFull />}
            marketingPane={<LoginMarketingPane />}
        >
            <LoginForm />
        </LoginLayoutFrame>
    );
};
export default LoginPage;
