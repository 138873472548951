import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        user: Yup.object().nullable(),
        queue: Yup.object()
            .nullable()
            .when('user', {
                is: (value: object) => !value,
                then: (schema) =>
                    schema.required(
                        __('main.required', { fieldName: __('main.queue') })
                    )
            })
    });
};
