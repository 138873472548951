import {
    Chip,
    DataTable,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Inline,
    Text
} from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import { useMetaTemplates } from 'modules/Tickets/hooks/useMetaTemplates';
import { MetaTemplate } from 'types';

const MessageTemplateTable = () => {
    const { loading, metaTemplates, cursor } = useMetaTemplates();

    const COLUMNS = [
        { name: 'modelName', label: __('templatesPage.modelName') },
        { name: 'category', label: __('templatesPage.category') },
        { name: 'language', label: __('templatesPage.language') },
        { name: 'status', label: __('templatesPage.status') }
    ];

    const ItemComponent = ({
        item
    }: DataTableCustomComponent<MetaTemplate>) => {
        const getLanguage = (language: string) => {
            if (
                language === 'pt_BR' ||
                language === 'en_US' ||
                language.startsWith('en') ||
                language.startsWith('es')
            ) {
                return __(`main.${item.language}`);
            }
            return language;
        };

        return (
            <DataTableRow disabled={true} key={`${item.id}${Math.random()}`}>
                <DataTableCell columnIndex={0} key={`${item.id}-0`}>
                    <Text
                        color={
                            item.status !== 'APPROVED' ? 'grey-300' : undefined
                        }
                    >
                        <Text>{item.name}</Text>
                    </Text>
                </DataTableCell>
                <DataTableCell columnIndex={1} key={`${item.id}-1`}>
                    <Text>{item.category}</Text>
                </DataTableCell>
                <DataTableCell columnIndex={2} key={`${item.id}-2`}>
                    <Text>{getLanguage(item.language)}</Text>
                </DataTableCell>
                <DataTableCell columnIndex={3} key={`${item.id}-3`}>
                    <Inline>
                        <Chip
                            theme={
                                item.status === 'APPROVED'
                                    ? 'success'
                                    : 'marketing'
                            }
                        >
                            {__(
                                `templatesPage.${item.status.toLocaleLowerCase()}Status`
                            )}
                        </Chip>
                    </Inline>
                </DataTableCell>
            </DataTableRow>
        );
    };

    return (
        <DataTable
            items={metaTemplates}
            columns={COLUMNS}
            isLoading={loading}
            itemComponent={ItemComponent}
            {...cursor}
        />
    );
};
export default MessageTemplateTable;
