import { DataTable, DataTableColumn } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { useSearchParams } from 'react-router-dom';
import { useRefetchList } from 'hooks/useRefetchList';
import { GetContactsResponse, getContacts } from 'api/contactsApi';
import emptyStateIllustration from 'assets/empty-state.svg';

const ContactsTable = () => {
    const [contacts, setContacts] = useState<GetContactsResponse>();
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useSearchParams();
    const search = params.get('search') || '';
    const page = +(params.get('page') || 1);

    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        getContacts(search, page)
            .then((data) => setContacts(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setContacts(undefined);
            })
            .finally(() => setIsLoading(false));
    }, [search, page, refreshToggle]);

    const COLUMNS: DataTableColumn[] = [
        {
            name: 'contact',
            label: __('contactsPage.contact')
        },
        {
            name: 'whatsApp',
            label: __('contactsPage.whatsApp')
        },
        {
            name: 'email',
            label: __('contactsPage.email')
        },
        {
            name: 'prefferedAttendant',
            label: __('contactsPage.prefferedAttendant')
        }
    ];

    return (
        <DataTable
            items={contacts?.contacts || []}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            hasNext={contacts?.hasMore}
            hasPrevious={page > 1}
            onNextClick={() => {
                params.set('page', String(page + 1));
                setParams(params);
            }}
            onPreviousClick={() => {
                const previousPage = page - 1;
                if (previousPage <= 1) {
                    params.delete('page');
                } else {
                    params.set('page', String(page - 1));
                }
                setParams(params);
            }}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    domainName: __('contactsPage.contact').toLocaleLowerCase()
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default ContactsTable;
