import { Avatar, Inline, MultiSelectField } from '@7shifts/sous-chef';
import { useSearchParams } from 'react-router-dom';
import { CustomOptionProps } from '@7shifts/sous-chef/dist/forms/SelectField/types';
import { useEffect, useState } from 'react';
import { listTags } from 'api/tagsApi';
import { handleError } from 'api/apiUtils';
import { Tag } from 'types';
import { __ } from 'i18n/translate';

const TicketsFilterTags = () => {
    const [tags, setTags] = useState<Tag[]>([]);
    const [params, setParams] = useSearchParams();
    const tagsFilters = params.get('tags');
    const selectedTagsIds = tagsFilters ? tagsFilters.split(',') : [];
    const tagsMap = tags.reduce<{ [key: number]: Tag }>(
        (map, tag) => ({
            ...map,
            [tag.id]: tag
        }),
        {}
    );

    const selectedTagsOptions = tags
        .filter((tag) => selectedTagsIds.includes(tag.id.toString()))
        .map((tag) => ({
            value: tag.id,
            label: tag.name
        }));

    useEffect(() => {
        listTags()
            .then((data) => setTags(data))
            .catch(handleError);
    }, []);

    return (
        <MultiSelectField
            name="tags"
            label={__('main.tags')}
            placeholder={__('tickets.showAll')}
            value={selectedTagsOptions}
            options={tags.map((tag) => ({
                value: tag.id,
                label: tag.name
            }))}
            onChange={(selectedOptions) => {
                if (!selectedOptions) {
                    params.delete('tags');
                } else {
                    params.set(
                        'tags',
                        selectedOptions
                            .map((options) => options.value)
                            .join(',')
                    );
                }

                setParams(params);
            }}
            CustomOption={({ label, value }: CustomOptionProps<number>) => (
                <Inline alignItems="center" space={8}>
                    <Avatar
                        size="small"
                        color={
                            tagsMap[value] ? tagsMap[value].color : 'grey-200'
                        }
                    >
                        {' '}
                    </Avatar>
                    {label}
                </Inline>
            )}
        />
    );
};

export default TicketsFilterTags;
