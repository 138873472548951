import { DataTable } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { getQueues } from 'api/queuesApi';
import { Queue } from 'types';
import { useRefetchList } from 'hooks/useRefetchList';
import { useSettings } from 'hooks/useSettings';
import emptyStateIllustration from 'assets/empty-state.svg';

const QueuesTable = () => {
    const { isLoadingSettings, settings } = useSettings();
    const [queues, setQueues] = useState<Queue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        getQueues()
            .then((data) => setQueues(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setQueues([]);
            })
            .finally(() => setIsLoading(false));
    }, [refreshToggle]);

    const COLUMNS = [
        {
            name: 'name',
            label: __('queuesPage.name')
        },
        {
            name: 'color',
            label: __('queuesPage.color')
        },
        {
            name: 'greetingMessage',
            label: __('queuesPage.greetingMessage')
        }
    ];

    return (
        <DataTable
            items={queues}
            columns={COLUMNS}
            itemComponent={(args) => <TableRow {...args} settings={settings} />}
            showActionMenu
            isLoading={isLoading || isLoadingSettings}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    context: 'female',
                    domainName: __('main.queue')
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default QueuesTable;
