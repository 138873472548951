import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import UsersTable from '../components/UsersTable';
import UsersPageActions from '../components/UsersPageActions';
import SearchFilter from 'sharedComponents/SearchFilter';

const UsersPage = () => {
    return (
        <Page
            title={__('main.users')}
            actions={<UsersPageActions />}
            filterBar={<SearchFilter />}
        >
            <UsersTable />
        </Page>
    );
};

export default UsersPage;
