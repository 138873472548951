import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { handleError } from 'api/apiUtils';
import { TemplateExtraInfo } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import { useRefetchList } from 'hooks/useRefetchList';
import { addTemplate, editTemplate } from 'api/templatesApi';

type Props = {
    onClose: () => void;
    editingTemplate?: TemplateExtraInfo;
};

const ManageTemplatesModal = ({ onClose, editingTemplate }: Props) => {
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();

    const formik = useFormik({
        initialValues: {
            name: editingTemplate ? editingTemplate.name : '',
            value: editingTemplate ? editingTemplate.value : ''
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);

            const templateData = {
                name: values.name,
                value: values.value
            };

            const request = editingTemplate
                ? editTemplate(editingTemplate.id, {
                      companyId: user.company.id,
                      ...templateData
                  })
                : addTemplate(templateData);

            request
                .then(() => {
                    toast(
                        editingTemplate
                            ? __('templatesPage.templateEdited')
                            : __('templatesPage.templateAdded')
                    );
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingTemplate
                    ? __('templatesPage.editTemplate')
                    : __('templatesPage.addTemplate')
            }
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form stackContent={false} formik={formik}>
                    <ModalBody>
                        <Stack>
                            <TextField
                                name="name"
                                label={__('contactsPage.extraFieldName')}
                                placeholder={__('usersPage.typeName')}
                            />
                            <TextField
                                name="value"
                                label={__('contactsPage.extraFieldValue')}
                                placeholder={__('usersPage.typeName')}
                            />
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingTemplate
                                        ? __('templatesPage.editTemplate')
                                        : __('templatesPage.addTemplate')}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageTemplatesModal;
