import {
    Button,
    Card,
    Form,
    FormFooter,
    MultiSelectField,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useEffect, useState } from 'react';
import { getQueues } from 'api/queuesApi';
import { handleError } from 'api/apiUtils';
import { Queue } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { usePermission } from 'hooks/usePermission';
import { ACTIONS } from '../../../../permissions';
import { useFormik } from 'formik';
import { getSchema } from './schema';
import { editUser } from 'api/usersApi';

const MyAccountForm = () => {
    const [queues, setQueues] = useState<Queue[]>([]);
    const { user } = useAuthContext();

    const canEditQueues = usePermission(
        user.profile,
        ACTIONS['user-modal:editQueues']
    );

    useEffect(() => {
        getQueues()
            .then((data) => setQueues(data))
            .catch(handleError);
    }, []);

    const getInitialValues = () => {
        return {
            name: user.name,
            profile: {
                value: user.profile,
                label: user.profile
            },
            email: user.email,
            isActive: user.isActive,
            isAttendant: user.isAttendant,
            onVacation: user.onVacation,
            password: '',
            queues: user.queues.map((queue) => ({
                label: queue.name,
                value: queue.id
            }))
        };
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(),
        onSubmit: ({ profile, queues, ...values }, { setSubmitting }) => {
            setSubmitting(true);
            const queueIds = queues.map((queue) => queue.value);

            const userData = { profile: profile.value, queueIds, ...values };

            editUser(user.id, {
                companyId: user.company.id,
                ...userData
            })
                .then(() => {
                    toast(__('usersPage.userEdited'));
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Card>
            <Form formik={formik}>
                <TextField
                    name="name"
                    label={__('usersPage.name')}
                    placeholder={__('usersPage.typeName')}
                />
                <TextField
                    name="email"
                    label={__('loginPage.email')}
                    placeholder={__('loginPage.typeEmail')}
                />
                {canEditQueues && (
                    <MultiSelectField
                        name="queues"
                        label={__('main.queues')}
                        options={queues.map((queue) => ({
                            label: queue.name,
                            value: queue.id
                        }))}
                    />
                )}
                <FormFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {__('main.save')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Card>
    );
};

export default MyAccountForm;
