import { useSearchParams } from 'react-router-dom';

export const useRefetchList = () => {
    const REFETCH_QUERY_PARAM = 'rl';
    const [params, setParams] = useSearchParams();

    const refreshToggle = params.get(REFETCH_QUERY_PARAM);

    const refreshList = () => {
        if (params.get(REFETCH_QUERY_PARAM)) {
            params.delete(REFETCH_QUERY_PARAM);
        } else {
            params.set(REFETCH_QUERY_PARAM, 'true');
        }
        setParams(params);
    };

    return {
        refreshToggle,
        refreshList
    };
};
