import { DataTable, DataTableColumn } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { useSearchParams } from 'react-router-dom';
import { useRefetchList } from 'hooks/useRefetchList';
import { GetTemplatesResponse, getTemplates } from 'api/templatesApi';
import emptyStateIllustration from 'assets/empty-state.svg';

const TemplatesTable = () => {
    const [templates, setTemplates] = useState<GetTemplatesResponse>();
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useSearchParams();
    const search = params.get('search') || '';
    const page = +(params.get('page') || 1);

    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        getTemplates(search, page)
            .then((data) => setTemplates(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setTemplates(undefined);
            })
            .finally(() => setIsLoading(false));
    }, [search, page, refreshToggle]);

    const COLUMNS: DataTableColumn[] = [
        {
            name: 'name',
            label: __('contactsPage.extraFieldName')
        },
        {
            name: 'value',
            label: __('contactsPage.extraFieldValue')
        }
    ];

    return (
        <DataTable
            items={templates?.templateExtraInfos || []}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            hasNext={templates?.hasMore}
            hasPrevious={page > 1}
            onNextClick={() => {
                params.set('page', String(page + 1));
                setParams(params);
            }}
            onPreviousClick={() => {
                const previousPage = page - 1;
                if (previousPage <= 1) {
                    params.delete('page');
                } else {
                    params.set('page', String(page - 1));
                }
                setParams(params);
            }}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    domainName: __('templatesPage.template').toLocaleLowerCase()
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default TemplatesTable;
