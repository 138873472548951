import { Card, Inline, Page, Skeleton, Stack, Text } from '@7shifts/sous-chef';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { __ } from 'i18n';
import { useEffect, useState } from 'react';
import { DashboardCounts, getDashboardCounts } from 'api/dashboardApi';
import { handleError } from 'api/apiUtils';
import WeeklyOverview from 'modules/Dashboard/components/WeeklyOverview';
import UsersFilter from 'modules/Dashboard/components/UsersFilter';

const DashboardPage = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const selectedUserId = params.has('userId')
        ? parseInt(params.get('userId') as string)
        : undefined;

    const [counters, setCounters] = useState<DashboardCounts>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = () => {
                getDashboardCounts(selectedUserId)
                    .then(setCounters)
                    .catch(handleError);
            };
            fetchData();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [selectedUserId]);

    return (
        <Page title={__('dashboardPage.dashboard')} actions={<UsersFilter />}>
            <Stack>
                <Text mt={40} as="h2">
                    {__('dashboardPage.generalOverview')}
                </Text>
                <Inline flex={[1, 1, 1, 1]} alignItems="stretch">
                    <Card onClick={() => navigate('/tickets')}>
                        <Stack alignItems="center">
                            {counters ? (
                                <Text as="insight">
                                    {counters.totalAttendance}
                                </Text>
                            ) : (
                                <Skeleton width={40} height={30} />
                            )}
                            <Text as="h3" alignment="center">
                                {__('dashboardPage.workingTickets')}
                            </Text>
                        </Stack>
                    </Card>
                    <Card
                        onClick={() => navigate('/tickets?view=waiting-room')}
                    >
                        <Stack alignItems="center">
                            {counters ? (
                                <Text as="insight">
                                    {counters.totalAwaiting}
                                </Text>
                            ) : (
                                <Skeleton width={40} height={30} />
                            )}
                            <Text as="h3" alignment="center">
                                {__('dashboardPage.usersWaiting')}
                            </Text>
                        </Stack>
                    </Card>
                    <Card onClick={() => navigate('/tickets?showClosed=true')}>
                        <Stack alignItems="center">
                            {counters ? (
                                <Text as="insight">{counters.totalClosed}</Text>
                            ) : (
                                <Skeleton width={40} height={30} />
                            )}
                            <Text as="h3" alignment="center">
                                {__('dashboardPage.ticketsClosed')}
                            </Text>
                        </Stack>
                    </Card>
                </Inline>
                <WeeklyOverview />
            </Stack>
        </Page>
    );
};

export default DashboardPage;
