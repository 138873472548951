import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import ContactsPage from './pages/ContactsPage';

export const ROUTES = [
    {
        path: '/contacts',
        element: <ContactsPage />,
        errorElement: <ErrorBoundary />
    }
];
