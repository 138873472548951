import { Dropdown, DropdownList, DropdownListItem } from '@7shifts/sous-chef';
import { useEffect, useLayoutEffect, useState } from 'react';
import { getQuickMesssages } from 'api/quickmessagesApi';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { handleError } from 'api/apiUtils';
import { QuickMessage } from 'types';
import './WhatsAppQuickMessages.scss';

type Props = {
    inputMessage: string;
    onSelect: (value: string) => void;
};

const WhatsAppQuickMessages = ({ inputMessage, onSelect }: Props) => {
    const [menuWidth, setMenuWidth] = useState(500);
    const [quickMessages, setQuickMessages] = useState<QuickMessage[]>([]);
    const { user } = useAuthContext();

    useEffect(() => {
        getQuickMesssages(user.companyId, user.id)
            .then(setQuickMessages)
            .catch(handleError);
    }, [user.companyId, user.id]);

    const isSearching = inputMessage.startsWith('/');
    const searchTerm =
        isSearching && inputMessage.length > 1 ? inputMessage.slice(1) : '';
    const availableOptions = quickMessages.filter((message) =>
        message.shortcode.startsWith(searchTerm)
    );

    // It opens the menu whenever the user types `/`
    useEffect(() => {
        if (inputMessage === '/') {
            const trigger = document.getElementById('quick-messages-trigger');
            if (trigger) {
                trigger.click();
            }
        }
    }, [inputMessage]);

    // This is just a hack so it closes and open the options again so it can position it properly in the screen
    useLayoutEffect(() => {
        if (isSearching && availableOptions.length > 0 && searchTerm) {
            const trigger = document.getElementById('quick-messages-trigger');
            if (trigger) {
                trigger.click();
                setTimeout(() => {
                    const isOpen = !!document.querySelector(
                        '[data-testid="quick-messages-trigger"]'
                    );
                    if (!isOpen) {
                        trigger.click();
                    }
                }, 10);
            }
        }
    }, [isSearching, availableOptions.length, searchTerm]);

    useEffect(() => {
        if (availableOptions.length > 0) {
            const trigger = document.getElementById('quick-messages-trigger');
            if (trigger) {
                setMenuWidth(trigger.getBoundingClientRect().width);
            }
        }
    }, [availableOptions.length]);

    if (availableOptions.length === 0) {
        return null;
    }

    return (
        <Dropdown
            trigger={
                <button
                    className="whats-app-quick-messages"
                    id="quick-messages-trigger"
                >
                    quick-messages-trigger
                </button>
            }
            alignment="left"
            testId="quick-messages-trigger"
            width={menuWidth}
        >
            <DropdownList>
                {availableOptions.map((option) => (
                    <DropdownListItem
                        key={option.id}
                        onClick={() => onSelect(option.message)}
                        caption={
                            option.message.length > 150
                                ? `${option.message.slice(0, 150)}...`
                                : option.message
                        }
                    >
                        {option.shortcode}
                    </DropdownListItem>
                ))}
            </DropdownList>
        </Dropdown>
    );
};

export default WhatsAppQuickMessages;
