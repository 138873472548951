import { Button, IconPaperPlane, IconTrash, Inline } from '@7shifts/sous-chef';
import SoundWaveAnimation from '../SoundWaveAnimation';
import './WhatsAppAudioBox.scss';
import RecordingTimer from '../RecordingTimer';

type Props = {
    onCancel: () => void;
    onSendAudio: () => void;
    loading: boolean;
};

const WhatsAppAudioBox = ({ onCancel, onSendAudio, loading }: Props) => {
    return (
        <div className="whats-app-audio-box">
            <Inline justifyContent="space-between" alignItems="center">
                <Button
                    theme="link-icon"
                    title="Delete audio"
                    onClick={onCancel}
                    disabled={loading}
                >
                    <IconTrash color="radish-500" />
                </Button>
                <Inline justifyContent="center" alignItems="center">
                    <RecordingTimer />
                    <SoundWaveAnimation />
                </Inline>
                <Button
                    theme="link-icon"
                    title="Send"
                    onClick={onSendAudio}
                    loading={loading}
                >
                    <IconPaperPlane />
                </Button>
            </Inline>
        </div>
    );
};

export default WhatsAppAudioBox;
