const DEFAULT_SLOTS = 'dMy';

type CursorPosition = {
    selectionStart: number;
    selectionEnd: number;
};

type MaskParams = {
    value: string;
    format: string;
    cursorPosition: CursorPosition;
    slots?: string;
};

export const maskfy = (params: MaskParams) => {
    const { value, format, slots = DEFAULT_SLOTS, cursorPosition } = params;

    const formatCursorPosition = format[cursorPosition.selectionStart];
    const isInAnchorPosition = !slots.includes(formatCursorPosition);

    const isDeletingAnchor = value.length < format.length && isInAnchorPosition;

    const [selectionStart, selectionEnd] = calculateCursorPosition({
        ...params,
        isDeletingAnchor
    });

    return {
        maskedValue: calculateMask({
            ...params,
            isDeletingAnchor
        }),
        cursorPosition: {
            selectionStart,
            selectionEnd
        }
    };
};

const calculateMask = ({
    value,
    format,
    slots = DEFAULT_SLOTS,
    isDeletingAnchor,
    cursorPosition
}: MaskParams & {
    isDeletingAnchor: boolean;
}) => {
    const slotsSet = new Set(slots);
    const accept = new RegExp('\\d', 'g');
    const inputMatches = value.match(accept) || [];

    const { isReplacingDigit, isReplacingAt } = calculateDigitReplacement(
        slots,
        format,
        inputMatches.length,
        cursorPosition
    );

    if (isReplacingDigit) {
        inputMatches.splice(isReplacingAt, 1);
    }

    if (isDeletingAnchor) {
        inputMatches.pop();
    }

    const maskedValue = Array.from(format, (character) => {
        return inputMatches[0] === character || slotsSet.has(character)
            ? inputMatches.shift() || character
            : character;
    });

    return maskedValue.join('');
};

const calculateCursorPosition = (
    params: MaskParams & {
        isDeletingAnchor: boolean;
    }
) => {
    const { value, format, cursorPosition, slots = DEFAULT_SLOTS } = params;
    const slotsSet = new Set(slots);
    const prev = ((j) =>
        Array.from(format, (c, i) => (slotsSet.has(c) ? (j = i + 1) : j)))(0);
    const first = [...format].findIndex((c) => slotsSet.has(c));

    const back = false;

    const calculatedCursorRange = [
        cursorPosition.selectionStart,
        cursorPosition.selectionEnd
    ].map((position) => {
        const valueCharacters = calculateMask({
            ...params,
            value: value.slice(0, position as number)
        }).split('');
        const slotIndex = valueCharacters.findIndex((character) =>
            slotsSet.has(character)
        );

        if (slotIndex < 0) {
            return prev[prev.length - 1];
        }

        if (back) {
            return prev[slotIndex - 1] || first;
        }

        return slotIndex;
    });

    return calculatedCursorRange;
};

const calculateDigitReplacement = (
    slots: string,
    format: string,
    amountOfDigitsTyped: number,
    cursorPosition: CursorPosition
) => {
    const slotsRegExp = new RegExp(`[${slots.split('').join('|')}]`, 'g');
    const slotsMatches = format.match(slotsRegExp) || [];

    const isReplacingDigit = amountOfDigitsTyped > slotsMatches.length;

    const getCharacterPosition = () => {
        const formatUntilTheCursorPoint = format.slice(
            0,
            cursorPosition.selectionStart
        );
        const slotsFormatSlicedMatches =
            formatUntilTheCursorPoint.match(slotsRegExp) || [];
        return slotsFormatSlicedMatches.length;
    };

    return {
        isReplacingDigit,
        isReplacingAt: isReplacingDigit ? getCharacterPosition() : 0
    };
};
