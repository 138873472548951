import { ACTIONS, RULES } from '../permissions';
import { Role } from 'types';

export const usePermission = (
    role: Role,
    action: keyof typeof ACTIONS
): boolean => {
    const permissions = RULES[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    /* TODO ALEX: It seems this dynamic permissions is not in place
    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    */
    return false;
};
