import { Message } from 'types';
import './SystemMessage.scss';
import MarkdownWrapper from '../../MarkdownWrapper';
import { format, parseISO } from 'date-fns/esm';

type Props = {
    message: Message;
};

const SystemMessage = ({ message }: Props) => {
    return (
        <span className="system-message" key={`timestamp-${message.id}`}>
            <div className="system-message__text">
                <MarkdownWrapper>{message.body}</MarkdownWrapper>
            </div>
            <span
                className="system-message__timestamp"
                title={format(parseISO(message.createdAt), 'dd/MM/yyyy HH:mm')}
            >
                {format(parseISO(message.createdAt), 'HH:mm')}
            </span>
        </span>
    );
};

export default SystemMessage;
