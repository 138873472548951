import { Pill, Tooltip } from '@7shifts/sous-chef';
import { WhatsAppStatus as WhatsAppStatusType } from 'types';
import { __ } from 'i18n';

type Props = {
    status: WhatsAppStatusType;
};

const WhatsAppStatus = ({ status }: Props) => {
    switch (status) {
        case 'CONNECTED':
            return (
                <Tooltip
                    overlay={__('channelsPage.statusConnectedTitle')}
                    theme="white"
                >
                    <span>
                        <Pill theme="success">
                            {__('channelsPage.statusConnected')}
                        </Pill>
                    </span>
                </Tooltip>
            );
        case 'DISCONNECTED':
            return (
                <Tooltip
                    header={__('channelsPage.statusDisconnectedTitle')}
                    overlay={__('channelsPage.statusDisconnectedDescription')}
                    theme="white"
                >
                    <span>
                        <Pill theme="danger">
                            {__('channelsPage.statusDisconnected')}
                        </Pill>
                    </span>
                </Tooltip>
            );
        case 'qrcode':
            return (
                <Tooltip
                    header={__('channelsPage.statusQRCodeTitle')}
                    overlay={__('channelsPage.statusQRCodeDescription')}
                    theme="white"
                >
                    <span>
                        <Pill theme="info">
                            {__('channelsPage.statusQRCode')}
                        </Pill>
                    </span>
                </Tooltip>
            );
        case 'TIMEOUT':
        case 'PAIRING':
            return (
                <Tooltip
                    header={__('channelsPage.statusTimeoutTitle')}
                    overlay={__('channelsPage.statusTimeoutDescription')}
                    theme="white"
                >
                    <span>
                        <Pill theme="danger">
                            {__('channelsPage.statusTimeout')}
                        </Pill>
                    </span>
                </Tooltip>
            );
    }
};

export default WhatsAppStatus;
