import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { TicketsPerDay } from 'api/dashboardApi';
import { Spinner } from '@7shifts/sous-chef';
import { __ } from 'i18n';

type Props = {
    ticketsPerDay?: TicketsPerDay;
    loading: boolean;
};
const TicketsPerDayChart = ({ ticketsPerDay, loading }: Props) => {
    console.log(ticketsPerDay);
    if (loading || !ticketsPerDay) {
        return <Spinner block />;
    }

    const chartData: { series: ApexOptions['series']; options: ApexOptions } = {
        series: [
            {
                data: Object.values(ticketsPerDay)
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: __('dashboardPage.dailyTickets'),
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                }
            },
            xaxis: {
                categories: [
                    __('dateShort.sunday'),
                    __('dateShort.monday'),
                    __('dateShort.tuesday'),
                    __('dateShort.wednesday'),
                    __('dateShort.thursday'),
                    __('dateShort.friday'),
                    __('dateShort.saturday')
                ]
            }
        }
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={350}
            />
        </div>
    );
};

export default TicketsPerDayChart;
