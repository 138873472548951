import { IconInfoCircle, Stack, Text, Tooltip } from '@7shifts/sous-chef';
import { __ } from 'i18n';

const IgnoreGroupMessagesTooltipInfo = () => {
    return (
        <Tooltip
            theme="white"
            overlay={
                <Stack>
                    <Stack space={8}>
                        <Text emphasis="bold">
                            {__('settingsPage.activated')}
                        </Text>
                        <Stack space={4}>
                            <Text>
                                {__(
                                    'settingsPage.ignoreGroupMessagesActivatedHelpText'
                                )}
                            </Text>
                            <Text emphasis="italic">
                                {__(
                                    'settingsPage.ignoreGroupMessagesActivatedHelpTextObs'
                                )}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack space={8}>
                        <Text emphasis="bold">
                            {__('settingsPage.deactivated')}
                        </Text>
                        <Stack space={4}>
                            <Text>
                                {__(
                                    'settingsPage.ignoreGroupMessagesDeactivatedHelpText'
                                )}
                            </Text>
                            <Text emphasis="italic">
                                {__(
                                    'settingsPage.ignoreGroupMessagesDeactivatedHelpTextObs'
                                )}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            }
        >
            <IconInfoCircle color="color-grey-300" />
        </Tooltip>
    );
};

export default IgnoreGroupMessagesTooltipInfo;
