import { AsyncSelectField, SelectOption } from '@7shifts/sous-chef';
import { useEffect } from 'react';
import { getContact, getContacts } from '../../api/contactsApi';
import { __ } from 'i18n/translate';

type Props = {
    initialContactId?: number | null;
    onInitialOptionLoaded?: (option: SelectOption<number>) => void;
    name: string;
    label: string;
};

const ContactSelectField = ({
    initialContactId,
    onInitialOptionLoaded,
    name,
    label
}: Props) => {
    useEffect(() => {
        if (initialContactId) {
            getContact(initialContactId).then((contact) => {
                if (onInitialOptionLoaded) {
                    onInitialOptionLoaded({
                        value: contact.id,
                        label: contact.name
                    });
                }
            });
        }
    }, [initialContactId]);

    return (
        <AsyncSelectField
            name={name}
            label={label}
            placeholder={__("component.select.placeholder")}
            loadOptions={(search) => {
                return getContacts(search, 1).then(({ contacts, hasMore }) => {
                    return {
                        options: contacts.map((contact) => ({
                            value: contact.id,
                            label: contact.name
                        })),
                        hasMore
                    };
                });
            }}
            isClearable
        />
    );
};

export default ContactSelectField;
