import { maskfy } from '../utils/mask';

export const useFieldMask = (format: string) => {
    const maskfyField = (input: HTMLInputElement) => {
        const { maskedValue, cursorPosition } = maskfy({
            value: input.value,
            format,
            slots: '_',
            cursorPosition: {
                selectionStart: input.selectionStart as number,
                selectionEnd: input.selectionEnd as number
            }
        });

        const value = maskedValue.toUpperCase();
        input.value = value;
        input.setSelectionRange(
            cursorPosition.selectionStart,
            cursorPosition.selectionEnd
        );

        return value;
    };

    return maskfyField;
};
