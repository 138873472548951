const LogoFull = () => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1004.000000 287.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,287.000000) scale(0.100000,-0.100000)"
            fill="#ffffff"
            stroke="none"
        >
            <path
                d="M1805 2315 c-28 -27 -31 -49 -10 -85 19 -32 19 -64 0 -80 -8 -6 -47
   -18 -87 -27 -79 -16 -197 -68 -240 -107 -43 -39 -95 -112 -108 -153 -8 -22
   -33 -57 -61 -84 -48 -44 -49 -46 -49 -105 1 -76 14 -135 36 -151 9 -7 46 -22
   80 -33 41 -14 64 -27 64 -37 0 -8 -24 -42 -54 -75 -115 -127 -166 -287 -117
   -365 45 -71 128 -87 201 -39 22 14 44 26 49 26 6 0 24 -29 41 -64 39 -82 118
   -169 184 -204 70 -36 151 -36 221 0 88 46 146 118 221 276 1 2 18 -8 39 -22
   51 -35 97 -47 138 -35 69 20 100 67 100 151 -1 79 -49 178 -132 271 -34 37
   -61 72 -61 76 0 15 24 28 81 45 81 23 92 39 97 147 4 76 2 93 -13 109 -23 26
   -35 25 -35 0 0 -11 -8 -37 -17 -58 l-17 -37 -6 95 c-5 74 -12 106 -33 146 -56
   110 -186 198 -336 229 -41 9 -80 20 -88 26 -17 15 -17 50 2 75 23 31 18 70
   -11 93 -34 27 -50 26 -79 -4z m72 -20 c9 -25 -4 -29 -17 -6 -7 15 -7 21 0 21
   6 0 13 -7 17 -15z m158 -250 c259 -66 351 -385 164 -560 -88 -82 -231 -132
   -354 -122 -110 8 -236 63 -264 115 -8 14 1 13 48 -10 115 -55 302 -56 423 -1
   176 79 236 298 114 419 -61 62 -107 71 -210 45 -102 -26 -138 -26 -218 -1 -34
   11 -74 20 -88 20 l-25 1 25 21 c88 75 255 106 385 73z m-355 -230 c83 -43 52
   -208 -40 -208 -87 0 -118 144 -43 203 31 24 45 25 83 5z m420 -12 c58 -52 45
   -159 -22 -191 -59 -28 -124 42 -114 123 5 44 51 95 86 95 11 0 34 -12 50 -27z
   m172 -465 c50 -61 98 -139 92 -149 -3 -5 -16 -9 -29 -9 -12 0 -48 -13 -79 -29
   l-56 -30 -1 37 c-1 50 -5 77 -26 146 l-17 59 23 18 c28 23 45 16 93 -43z
   m-782 -29 c6 -10 10 -59 9 -109 l-1 -91 -31 24 c-18 13 -46 29 -64 36 -38 15
   -45 30 -33 72 25 85 91 123 120 68z m624 -34 c37 -119 14 -263 -59 -371 -41
   -59 -68 -74 -133 -74 -165 1 -272 187 -198 346 39 85 89 112 246 134 41 6 76
   13 79 15 2 3 14 5 26 5 17 0 25 -11 39 -55z"
            />
            <path
                d="M1831 1223 c-5 -10 -10 -29 -11 -43 -6 -64 1 -83 39 -121 31 -31 46
   -39 75 -39 44 0 91 22 106 51 11 20 11 20 -14 3 -36 -23 -109 -14 -141 16 -31
   29 -49 83 -41 121 6 33 1 37 -13 12z"
            />
            <path
                d="M1457 2295 c-167 -69 -351 -245 -439 -420 -51 -101 -78 -184 -94
   -289 -19 -123 -13 -224 22 -356 88 -331 318 -564 659 -666 78 -24 108 -27 230
   -28 116 0 155 3 223 22 411 111 703 479 703 885 0 167 -38 314 -72 280 -7 -7
   -7 -27 0 -68 33 -180 23 -334 -35 -494 -42 -119 -96 -209 -178 -297 -144 -156
   -331 -251 -540 -274 -176 -20 -347 13 -504 96 -218 115 -371 305 -434 539 -31
   116 -31 326 0 443 71 264 240 464 488 577 58 27 75 39 70 51 -3 8 -6 18 -6 20
   0 10 -40 1 -93 -21z"
            />
            <path
                d="M985 2122 c-80 -106 -167 -279 -194 -388 -47 -186 -51 -393 -11 -549
   28 -110 125 -302 205 -407 67 -89 83 -100 105 -73 10 12 4 25 -37 72 -100 117
   -166 237 -209 380 -36 118 -45 365 -20 493 36 175 119 345 225 462 49 54 53
   71 21 88 -16 9 -28 -3 -85 -78z"
            />
            <path
                d="M690 1908 c-63 -132 -91 -256 -97 -435 -6 -161 1 -226 39 -343 35
   -109 65 -160 93 -160 14 0 25 2 25 4 0 2 -14 33 -30 70 -57 125 -74 221 -74
   406 1 145 4 177 27 263 15 55 46 139 71 188 l44 89 -29 0 c-28 0 -33 -7 -69
   -82z"
            />
            <path
                d="M3217 1816 c-48 -18 -73 -40 -101 -91 -18 -34 -21 -57 -21 -150 0
   -92 3 -117 21 -150 39 -75 86 -93 302 -116 138 -14 157 -26 157 -93 0 -66 -18
   -80 -109 -84 -99 -5 -142 9 -158 54 l-12 34 -109 0 -110 0 7 -62 c9 -80 26
   -117 64 -150 56 -47 91 -53 302 -53 171 0 199 2 230 19 83 44 117 101 126 208
   9 108 -9 184 -55 228 -46 44 -88 57 -244 76 -139 17 -151 20 -172 40 -22 22
   -19 87 4 108 15 14 35 16 107 14 l89 -3 15 -32 15 -33 109 0 109 0 -6 60 c-8
   96 -56 155 -146 180 -55 15 -368 12 -414 -4z"
            />
            <path
                d="M8099 1817 c-156 -44 -199 -136 -199 -427 0 -174 13 -255 52 -321 48
   -81 154 -119 333 -119 189 0 291 39 338 131 40 80 50 151 45 349 -7 246 -35
   316 -147 369 -52 24 -67 26 -216 28 -107 2 -175 -2 -206 -10z m314 -205 c21
   -21 22 -33 25 -188 6 -269 3 -274 -152 -274 -157 0 -151 -10 -151 238 0 257
   -3 253 168 248 75 -2 91 -5 110 -24z"
            />
            <path d="M4630 1390 l0 -430 95 0 95 0 0 430 0 430 -95 0 -95 0 0 -430z" />
            <path
                d="M7062 1393 l3 -428 250 0 c273 0 334 9 386 52 43 36 60 75 66 154 10
   112 -30 184 -121 219 -33 12 -33 14 25 43 59 30 79 71 79 164 0 86 -21 145
   -60 173 -57 41 -129 50 -387 50 l-243 0 2 -427z m450 230 c24 -22 26 -114 2
   -134 -11 -9 -53 -15 -125 -17 l-109 -4 0 86 0 86 107 0 c85 0 110 -3 125 -17z
   m12 -319 c19 -19 22 -124 4 -142 -8 -8 -52 -12 -130 -12 l-118 0 0 85 0 85
   114 0 c91 0 118 -3 130 -16z"
            />
            <path
                d="M8780 1730 l0 -90 110 0 110 0 0 -340 0 -340 110 0 110 0 0 340 0
   340 115 0 115 0 0 90 0 90 -335 0 -335 0 0 -90z"
            />
            <path
                d="M4058 1567 c-119 -34 -133 -66 -133 -302 0 -217 9 -244 91 -285 44
   -22 61 -25 169 -25 66 0 137 4 157 8 66 14 117 71 131 145 l7 32 -95 0 c-57 0
   -95 -4 -95 -10 0 -5 -9 -19 -20 -30 -15 -15 -33 -20 -73 -20 -71 0 -87 17 -87
   93 l0 57 185 0 185 0 0 75 c0 145 -33 220 -110 253 -48 20 -250 26 -312 9z
   m212 -127 c14 -14 20 -33 20 -65 l0 -45 -90 0 -90 0 0 43 c0 66 21 85 98 86
   29 1 48 -5 62 -19z"
            />
            <path
                d="M5098 1567 c-119 -34 -133 -66 -133 -302 0 -217 9 -244 91 -285 44
   -22 61 -25 169 -25 66 0 137 4 157 8 66 14 117 71 131 145 l7 32 -95 0 c-57 0
   -95 -4 -95 -10 0 -5 -9 -19 -20 -30 -15 -15 -33 -20 -73 -20 -71 0 -87 17 -87
   93 l0 57 185 0 185 0 0 83 c0 140 -36 216 -115 248 -45 17 -251 22 -307 6z
   m212 -127 c14 -14 20 -33 20 -65 l0 -45 -90 0 -90 0 0 43 c0 66 21 85 98 86
   29 1 48 -5 62 -19z"
            />
            <path
                d="M5946 1566 c-21 -8 -51 -27 -67 -42 l-29 -28 0 37 0 37 -95 0 -95 0
   0 -305 0 -305 94 0 94 0 3 209 c5 244 7 251 88 251 25 0 52 -4 60 -9 22 -14
   30 -86 31 -273 l0 -178 91 0 90 0 -3 263 c-3 290 -5 300 -68 332 -44 23 -147
   29 -194 11z"
            />
            <path
                d="M6535 1576 c-5 -3 -27 -7 -48 -11 -20 -3 -53 -20 -72 -36 -50 -45
   -65 -104 -65 -264 0 -160 15 -219 65 -264 44 -38 82 -46 230 -46 99 1 128 5
   166 22 51 23 83 66 94 126 l7 37 -96 0 c-74 0 -96 -3 -96 -13 0 -28 -36 -47
   -88 -47 -71 0 -92 21 -92 95 l0 55 186 0 186 0 -4 108 c-4 136 -22 176 -93
   211 -41 21 -65 25 -159 28 -61 1 -115 1 -121 -1z m164 -133 c14 -12 23 -35 27
   -65 l7 -48 -97 0 -96 0 0 40 c0 58 34 90 93 90 28 0 53 -7 66 -17z"
            />
        </g>
    </svg>
);

export default LogoFull;
