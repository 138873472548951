import { Message } from 'types';
import { ExpandMore } from '@material-ui/icons';
import DailyTimeStamps from './DailyTimeStamps/DailyTimeStamps';
import MessageDivider from './MessageDivider/MessageDivider';
import MessageMedia from './MessageMedia/MessageMedia';
import MessageContent from './MessageContent/MessageContent';
import './MessageDisplay.scss';
import classNames from 'classnames';
import { Dropdown, DropdownList, DropdownListItem } from '@7shifts/sous-chef';
import { deleteMessage } from 'api/messagesApi';
import { handleError } from 'api/apiUtils';
import { __ } from 'i18n';
import SystemMessage from './SystemMessage';
import { format, parseISO } from 'date-fns/esm';

type Props = {
    message: Message;
    previousMessage: Message | null;
    amountOfMessages: number;
    index: number;
    isGroup: boolean;
    onReplyMessage: (message: Message) => void;
};

const MessageDisplay = ({
    message,
    previousMessage,
    amountOfMessages,
    index,
    isGroup,
    onReplyMessage
}: Props) => {
    const isLastMessage = index === amountOfMessages - 1;

    const handleDeleteMessage = () => {
        deleteMessage(message.id).catch(handleError);
    };

    return (
        <>
            <DailyTimeStamps
                index={index}
                message={message}
                previousMessage={previousMessage}
                isLastMessage={isLastMessage}
            />
            <MessageDivider
                index={index}
                amountOfMessages={amountOfMessages}
                message={message}
                previousMessage={previousMessage}
            />
            {message.mediaType === 'system' ? (
                <SystemMessage message={message} />
            ) : (
                <div
                    className={classNames('message-display', {
                        'message-display--right': message.fromMe
                    })}
                    title={
                        message.statusError
                            ? message.statusError
                            : format(
                                  parseISO(message.createdAt),
                                  'dd/MM/yyyy HH:mm'
                              )
                    }
                >
                    {!message.isDeleted && (
                        <Dropdown
                            trigger={
                                <button
                                    id="messageActionsButton"
                                    className="message-display__actions-button"
                                >
                                    <ExpandMore />
                                </button>
                            }
                        >
                            <DropdownList>
                                {message.fromMe && (
                                    <DropdownListItem
                                        onClick={handleDeleteMessage}
                                    >
                                        {__('tickets.deleteMessage')}
                                    </DropdownListItem>
                                )}
                                <DropdownListItem
                                    onClick={() => onReplyMessage(message)}
                                >
                                    {__('tickets.replyMessage')}
                                </DropdownListItem>
                            </DropdownList>
                        </Dropdown>
                    )}
                    {!message.fromMe && isGroup && (
                        <span className="message-display__contact-name">
                            {message.contact?.name}
                        </span>
                    )}
                    {message.mediaUrl && <MessageMedia message={message} />}
                    <MessageContent message={message} />
                </div>
            )}
        </>
    );
};

export default MessageDisplay;
