/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck Ignore this file until we get back to it
/**
 * ALEX NOTE: This file needs to be properly converted to TypeScript.
 * It was just copied from the legacy codebase.
 */

import { useState, useEffect } from 'react';

import api from '../services/api';
import { handleError } from '../api/apiUtils';
import { AxiosError } from 'axios';

const useTickets = ({
    searchParam,
    tags,
    users,
    pageNumber,
    status,
    date,
    updatedAt,
    showAll,
    queueIds,
    withUnreadMessages,
    updateState
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                try {
                    const { data } = await api.get(`/tickets-v2/${status}`, {
                        params: {
                            searchParam,
                            pageNumber,
                            tags,
                            users,
                            status,
                            date,
                            updatedAt,
                            showAll,
                            queueIds,
                            withUnreadMessages
                        }
                    });
                    updateState(data.tickets);
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    handleError(err as AxiosError);
                }
            };
            fetchTickets();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [
        searchParam,
        tags,
        users,
        pageNumber,
        status,
        date,
        updatedAt,
        showAll,
        queueIds,
        withUnreadMessages,
        updateState
    ]);

    return { loading, hasMore };
};

export default useTickets;
