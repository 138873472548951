import api from '../services/api';
import { WhatsApp } from 'types';

type WhatsAppData = {
    complationMessage: string;
    greetingMessage: string;
    isDefault: boolean;
    name: string;
    outOfHoursMessage: string;
    flowIdentifier: string;
    queueIds: number[];
    ratingMessage: string;
};
export const addWhatsApp = (newWhatsApp: WhatsAppData): Promise<WhatsApp> => {
    const defaultData = {
        provider: 'beta',
        token: ''
    };
    return api
        .post('/whatsapp', { ...newWhatsApp, ...defaultData })
        .then(({ data }) => data);
};

type EditWhatsAppData = {
    token: string;
} & WhatsAppData;

export const editWhatsApp = (
    whatsAppId: number,
    editingWhatsApp: EditWhatsAppData
): Promise<WhatsApp> => {
    const defaultData = {
        provider: 'beta'
    };
    return api
        .put('/whatsapp/' + whatsAppId, { ...editingWhatsApp, ...defaultData })
        .then(({ data }) => data);
};

export const deleteWhatsApp = (whatsAppId: number): Promise<void> => {
    return api.delete('/whatsapp/' + whatsAppId);
};

export const requestNewQRCode = (whatsAppId: number): Promise<void> => {
    return api.put('/whatsappsession/' + whatsAppId);
};

export const reconnectWhatsAppSession = (whatsAppId: number): Promise<void> => {
    return api.post('/whatsappsession/' + whatsAppId);
};

export const disconnectWhatsAppSession = (
    whatsAppId: number
): Promise<void> => {
    return api.delete('/whatsappsession/' + whatsAppId);
};
