import { Button, IconMessages } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import SendTemplateMessageModal from 'modules/Tickets/components/SendTemplateMessageModal';
import { useState } from 'react';
import { Ticket } from 'types';

type Props = {
    ticket: Ticket;
};

const SendTemplateButton = ({ ticket }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button theme="link-primary" onClick={() => setIsOpen(true)}>
                <IconMessages size="medium" />
                {__('tickets.sendTemplateMessage')}
            </Button>
            {isOpen && (
                <SendTemplateMessageModal
                    ticket={ticket}
                    onClose={() => setIsOpen(false)}
                />
            )}
        </>
    );
};

export default SendTemplateButton;
