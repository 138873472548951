import {
    Avatar,
    Button,
    Dropdown,
    DropdownList,
    DropdownListItem,
    IconCheck,
    IconEllipsisV,
    Inline,
    Skeleton,
    Stack,
    Text,
    toast
} from '@7shifts/sous-chef';
import { Contact, Ticket } from 'types';
import './WhatsAppChatHeader.scss';
import WhatsAppHeader from '../WhatsAppHeader';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
    closeTicket,
    deleteTicket,
    sendTicketToWaitingRoom
} from 'api/ticketsApi';
import { handleError } from 'api/apiUtils';
import { usePermission } from 'hooks/usePermission';
import { __ } from 'i18n';
import ManageScheduleModal from 'modules/Schedules/components/ManageScheduleModal';
import { useState } from 'react';
import TransferTicketModal from 'modules/Tickets/components/TransferTicketModal';
import WhatsAppNotesButton from 'modules/Tickets/components/WhatsAppNotesButton';
import WhatsAppTagsButton from 'modules/Tickets/components/WhatsAppTagsButton';
import ReopenTicketButton from 'modules/Tickets/components/ReopenTicketButton';

type Props = {
    loading: boolean;
    showBorder: boolean;
    onProfileClick: () => void;
    onNotesClick: () => void;
    onTagsClick: () => void;
    contact?: Contact;
    ticket?: Ticket;
    amountOfNotes: number;
};

const WhatsAppChatHeader = ({
    loading,
    showBorder,
    onProfileClick,
    onNotesClick,
    onTagsClick,
    contact,
    ticket,
    amountOfNotes
}: Props) => {
    const { user } = useAuthContext();
    const [openScheduleMessageModal, setOpenScheduleMessageModal] =
        useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const navigate = useNavigate();
    const canDeleteTicket = usePermission(
        user.profile,
        'ticket-options:deleteTicket'
    );

    const navigateToWorkingTickets = () => {
        navigate('/tickets?view=working');
    };

    const handleCloseTicket = () => {
        if (!ticket) {
            return;
        }
        closeTicket(ticket.id, user.id)
            .then(() => {
                toast(__('tickets.ticketClosed'));
                navigateToWorkingTickets();
            })
            .catch(handleError);
    };

    const handleDeleteTicket = () => {
        if (!ticket) {
            return;
        }
        deleteTicket(ticket.id)
            .then(() => {
                toast(__('tickets.ticketDeleted'));
                navigateToWorkingTickets();
            })
            .catch(handleError);
    };

    const handleSendTicketBackToWaitingRoom = () => {
        if (!ticket) {
            return;
        }
        sendTicketToWaitingRoom(ticket.id)
            .then(() => {
                toast(__('tickets.ticketMovedToWaitingRoom'));
                navigateToWorkingTickets();
            })
            .catch(handleError);
    };

    return (
        <WhatsAppHeader showBorder={showBorder}>
            <Inline alignItems="center" justifyContent="space-between">
                <div
                    onClick={onProfileClick}
                    className="whats-app-chat-header__user-profile"
                >
                    <Inline
                        alignItems="center"
                        space={12}
                        justifyContent="center"
                    >
                        <Avatar
                            url={
                                loading || !contact
                                    ? undefined
                                    : contact.profilePicUrl
                            }
                        />
                        {loading || !contact || !ticket ? (
                            <Skeleton />
                        ) : (
                            <Stack space={0}>
                                <Inline space={8}>
                                    <Text emphasis="bold">{contact.name}</Text>
                                    <Text color="grey-300">{`#${ticket.id}`}</Text>
                                </Inline>
                                {user.profile === 'admin' && ticket.user && (
                                    <Text color="grey-300">
                                        {__(`tickets.chattingWith`, {
                                            userName: ticket.user.name
                                        })}
                                    </Text>
                                )}
                            </Stack>
                        )}
                    </Inline>
                </div>
                {ticket && ticket.status === 'closed' && (
                    <ReopenTicketButton ticket={ticket} />
                )}
                {ticket && ticket.status !== 'closed' && (
                    <Inline space={0}>
                        {ticket.status === 'open' && (
                            <WhatsAppNotesButton
                                onNotesClick={onNotesClick}
                                amountOfNotes={amountOfNotes}
                            />
                        )}
                        {ticket.status === 'open' && (
                            <WhatsAppTagsButton
                                amountOfTags={(ticket.tags || []).length}
                                onTagsClick={onTagsClick}
                            />
                        )}

                        <Button
                            theme="link-icon"
                            onClick={handleCloseTicket}
                            title={__('tickets.resolveTicket')}
                        >
                            <IconCheck color="mint-400" />
                        </Button>

                        {ticket.status === 'open' && (
                            <>
                                <Dropdown
                                    trigger={
                                        <Button theme="link-icon">
                                            <IconEllipsisV />
                                        </Button>
                                    }
                                >
                                    <DropdownList>
                                        <DropdownListItem
                                            onClick={
                                                handleSendTicketBackToWaitingRoom
                                            }
                                        >
                                            {__(
                                                'tickets.sendBackToWaitingRoom'
                                            )}
                                        </DropdownListItem>
                                        <DropdownListItem
                                            onClick={() =>
                                                setOpenScheduleMessageModal(
                                                    true
                                                )
                                            }
                                        >
                                            {__('tickets.schedule')}
                                        </DropdownListItem>
                                        <DropdownListItem
                                            onClick={() =>
                                                setOpenTransferModal(true)
                                            }
                                        >
                                            {__('tickets.transfer')}
                                        </DropdownListItem>
                                        {canDeleteTicket && (
                                            <DropdownListItem
                                                onClick={handleDeleteTicket}
                                            >
                                                {__('tickets.delete')}
                                            </DropdownListItem>
                                        )}
                                    </DropdownList>
                                </Dropdown>
                                {openScheduleMessageModal && (
                                    <ManageScheduleModal
                                        onClose={() =>
                                            setOpenScheduleMessageModal(false)
                                        }
                                        initialContactId={ticket.contactId}
                                    />
                                )}
                                {openTransferModal && (
                                    <TransferTicketModal
                                        onClose={() =>
                                            setOpenTransferModal(false)
                                        }
                                        onTranferedTicket={() => {
                                            setOpenTransferModal(false);
                                            navigateToWorkingTickets();
                                        }}
                                        ticketId={ticket.id}
                                    />
                                )}
                            </>
                        )}
                    </Inline>
                )}
            </Inline>
        </WhatsAppHeader>
    );
};

export default WhatsAppChatHeader;
