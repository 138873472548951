import api, { openApi } from '../services/api';
import { Plan } from 'types';

const usePlans = () => {
    const getPlanList = async (params: unknown) => {
        const { data } = await openApi.request({
            url: '/plans/list',
            method: 'GET',
            params
        });
        return data;
    };

    const list = async () => {
        const { data } = await api.request<Plan[]>({
            url: '/plans/all',
            method: 'GET'
        });
        return data;
    };

    const finder = async (id: number) => {
        const { data } = await api.request({
            url: `/plans/${id}`,
            method: 'GET'
        });
        return data;
    };

    const save = async (data: unknown) => {
        const { data: responseData } = await api.request({
            url: '/plans',
            method: 'POST',
            data
        });
        return responseData;
    };

    const update = async (data: unknown) => {
        const { data: responseData } = await api.request({
            url: `/plans/${(data as { id: number }).id}`,
            method: 'PUT',
            data
        });
        return responseData;
    };

    const remove = async (id: number) => {
        const { data } = await api.request({
            url: `/plans/${id}`,
            method: 'DELETE'
        });
        return data;
    };

    return {
        getPlanList,
        list,
        save,
        update,
        finder,
        remove
    };
};

export default usePlans;
