import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        email: Yup.string()
            .email(__('loginPage.emailInvalid'))
            .required(__('loginPage.emailRequired')),
        password: Yup.string()
            .min(5, __('loginPage.passwordTooShort'))
            .required(__('loginPage.passwordRequired'))
    });
};
