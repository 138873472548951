import { Stack, Text } from '@7shifts/sous-chef';
import EmptyStateIllustration from './EmptyStateIllustration';
import './WhatsAppEmptyState.scss';

const WhatsAppEmptyState = () => {
    return (
        <div className="whats-app-empty-state">
            <Stack alignItems="center">
                <EmptyStateIllustration />
                <Stack alignItems="center" space={8}>
                    <Text as="h2">SeleneBOT</Text>
                    <Text alignment="center">
                        O SeleneBOT é a resposta para suas necessidades de
                        atendimento ao cliente de forma eficiente, ágil e
                        estratégica.
                    </Text>
                </Stack>
            </Stack>
        </div>
    );
};

export default WhatsAppEmptyState;
