import {
    Button,
    PasswordField,
    TextField,
    Form,
    CheckboxField,
    InlineBanner
} from '@7shifts/sous-chef';
import {
    Navigate,
    Link as RouterLink,
    useSearchParams
} from 'react-router-dom';
import { __ } from 'i18n';
import { getSchema } from './schema';
import { useFormik } from 'formik';
import { useAuthContext } from '../../../../contexts/AuthContext';

const LoginForm = () => {
    const [params] = useSearchParams();
    const wasLoggedOut = params.get('logged_out');
    const urlReturn = params.get('urlReturn');
    const { handleLogin, isAuth } = useAuthContext();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            keepLogged: false
        },
        validationSchema: getSchema(),
        onSubmit: (formValues, { setSubmitting }) => {
            setSubmitting(true);
            handleLogin(formValues).finally(() => setSubmitting(false));
        }
    });

    if (isAuth) {
        if (urlReturn) {
            return <Navigate to={urlReturn} />;
        }
        return <Navigate to="/dashboard" />;
    }

    return (
        <Form wide formik={formik}>
            {wasLoggedOut && (
                <InlineBanner theme="success">
                    {__('loginPage.youHaveBeenLoggedOut')}
                </InlineBanner>
            )}
            <TextField
                name="email"
                label={__('loginPage.email')}
                placeholder={__('loginPage.typeEmail')}
            />
            <PasswordField
                name="password"
                label={__('loginPage.password')}
                placeholder={__('loginPage.typePassword')}
            />
            <CheckboxField
                name="keep-logged"
                label={__('loginPage.keepLogged')}
            />
            <Button
                type="submit"
                theme="primary"
                size="full-width"
                loading={formik.isSubmitting}
            >
                {__('loginPage.login')}
            </Button>

            <RouterLink to="/signup">{__('loginPage.register')}</RouterLink>
        </Form>
    );
};

export default LoginForm;
