import {
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Chip,
    DataTableAction
} from '@7shifts/sous-chef';
import { QuickMessage } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteQuickMessagesModal from '../../DeleteQuickMessagesModal';
import ManageQuickMessageModal from '../../ManageQuickMessageModal';
import { useAuthContext } from '../../../../../contexts/AuthContext';

type Props = DataTableCustomComponent<QuickMessage>;

const TableRow = ({ item }: Props) => {
    const { user } = useAuthContext();
    const [isEditing, setIsEditing] = useState(false);
    const [isDeletingQuickMessages, setIsDeletingQuickMessages] =
        useState(false);
    const actions: DataTableAction[] = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeletingQuickMessages(true)
        }
    ];
    const canManage = user.profile === 'admin' || !item.isGlobal;
    return (
        <>
            <DataTableRow
                actions={canManage ? actions : undefined}
                onClick={canManage ? () => setIsEditing(true) : undefined}
            >
                <DataTableCell columnIndex={0}>{item.shortcode}</DataTableCell>
                <DataTableCell columnIndex={1}>{item.message}</DataTableCell>
                <DataTableCell columnIndex={2}>
                    {item.isGlobal ? (
                        <Chip theme="info">{__('main.yes')}</Chip>
                    ) : (
                        <Chip theme="warning">{__('main.no')}</Chip>
                    )}
                </DataTableCell>
            </DataTableRow>
            {isEditing && (
                <ManageQuickMessageModal
                    editingMessage={item}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isDeletingQuickMessages && (
                <DeleteQuickMessagesModal
                    deletingMessages={item}
                    onClose={() => setIsDeletingQuickMessages(false)}
                />
            )}
        </>
    );
};

export default TableRow;
