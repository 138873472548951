import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    toast
} from '@7shifts/sous-chef';
import { TemplateExtraInfo } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import { useRefetchList } from 'hooks/useRefetchList';
import { handleError } from 'api/apiUtils';
import { deleteTemplate } from 'api/templatesApi';

type Props = {
    deletingTemplate: TemplateExtraInfo;
    onClose: () => void;
};

const DeleteTemplateModal = ({ deletingTemplate, onClose }: Props) => {
    const { refreshList } = useRefetchList();
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDelete = () => {
        setIsDeleting(true);

        deleteTemplate(deletingTemplate.id)
            .then(() => {
                refreshList();
                setIsDeleting(false);
                toast(
                    __('main.deleted', {
                        domainName: __(
                            'templatesPage.template'
                        ).toLocaleLowerCase()
                    })
                );
                onClose();
            })
            .catch((err) => {
                setIsDeleting(false);
                handleError(err);
            });
    };
    return (
        <Modal
            onClose={onClose}
            header={__('main.deleteDomain', {
                domainName: __('templatesPage.template')
            })}
            subHeader={__('main.confirmDeleteDomain', {
                domainName: deletingTemplate.name
            })}
        >
            <ModalBody>{__('templatesPage.deleteTemplateNotice')}</ModalBody>
            <ModalFooter
                actions={{
                    primary: (
                        <Button
                            onClick={handleDelete}
                            loading={isDeleting}
                            theme="danger"
                        >
                            {__('main.delete')}
                        </Button>
                    ),
                    secondary: (
                        <Button onClick={onClose}>{__('main.cancel')}</Button>
                    )
                }}
            />
        </Modal>
    );
};

export default DeleteTemplateModal;
