import {
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow
} from '@7shifts/sous-chef';
import { Tag } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteTagsModal from '../../DeleteTagsModal';
import ManageTagModal from '../../ManageTagModal';
import ColorDisplay from 'sharedComponents/ColorDisplay';

type Props = DataTableCustomComponent<Tag>;

const TableRow = ({ item }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeletingTags, setIsDeletingTags] = useState(false);
    const actions = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeletingTags(true)
        }
    ];
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>{item.name}</DataTableCell>
                <DataTableCell columnIndex={1}>
                    <ColorDisplay color={item.color} />
                </DataTableCell>
                <DataTableCell columnIndex={2}>
                    {item.ticketsCount}
                </DataTableCell>
            </DataTableRow>
            {isEditing && (
                <ManageTagModal
                    editingTag={item}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isDeletingTags && (
                <DeleteTagsModal
                    deletingTags={item}
                    onClose={() => setIsDeletingTags(false)}
                />
            )}
        </>
    );
};

export default TableRow;
