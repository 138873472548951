import { Button, IconMessages, Spinner } from '@7shifts/sous-chef';
import { useState } from 'react';
import WhatsAppContactInfoPane from '../WhatsAppContactInfoPane';
import { useTicketInfo } from 'hooks/useTicketInfo';
import WhatsAppConversation from '../WhatsAppConversation';
import './WhatsAppChat.scss';
import WhatsAppChatHeader from '../WhatsAppChatHeader';
import WhatsAppInput from '../WhatsAppInput';
import WhatsAppNotesPane from '../WhatsAppNotesPane';
import WhatsAppTagsPane from '../WhatsAppTagsPane';
import { acceptTicket } from 'api/ticketsApi';
import { useNavigate } from 'react-router-dom';
import { handleError } from 'api/apiUtils';
import { Message } from 'types';
import { useNotesCount } from 'modules/Tickets/hooks/useNotesCount';
import { __ } from 'i18n/translate';
import SendTemplateButton from 'modules/Tickets/components/SendTemplateButton';
import ReopenTicketTextButton from '../ReopenTicketTextButton';

type Props = {
    ticketUUId: string;
};
const WhatsAppChat = ({ ticketUUId }: Props) => {
    const [replyingMessage, setReplyingMessage] = useState<Message | null>(
        null
    );
    const [isCallingAPI, setIsCallingAPI] = useState(false);
    const [sidePane, setSidePane] = useState<
        'contact' | 'tags' | 'notes' | null
    >(null);
    const { loading, contact, ticket } = useTicketInfo(ticketUUId);

    const { amountOfNotes, refreshNotesCount } = useNotesCount(ticket);

    const navigate = useNavigate();

    const handleAcceptTicket = () => {
        if (!ticket) {
            return;
        }
        setIsCallingAPI(true);
        acceptTicket(ticket.id)
            .then(() => {
                setIsCallingAPI(false);
                navigate('/tickets?view=working&ticket=' + ticket.uuid);
            })
            .catch((err) => {
                setIsCallingAPI(false);
                handleError(err);
            });
    };

    const isChatLocked =
        ticket && ticket.officialTicket && !ticket.enabledMessages;

    return (
        <>
            <div className="whats-app-chat">
                <WhatsAppChatHeader
                    loading={loading}
                    contact={contact}
                    onProfileClick={() => setSidePane('contact')}
                    onNotesClick={() => setSidePane('notes')}
                    onTagsClick={() => setSidePane('tags')}
                    showBorder={sidePane !== null}
                    ticket={ticket}
                    amountOfNotes={amountOfNotes}
                />
                <div className="whats-app-chat__conversation-container">
                    {!loading && ticket && (
                        <WhatsAppConversation
                            ticketId={ticket.id}
                            ticket={ticket}
                            onReplyMessage={setReplyingMessage}
                        />
                    )}
                    {loading && (
                        <div className="whats-app-chat__conversation-content">
                            <Spinner block />
                        </div>
                    )}
                    {ticket && ticket.status === 'open' && !isChatLocked && (
                        <WhatsAppInput
                            ticket={ticket}
                            replyingMessage={replyingMessage}
                            closeReplyingMessage={() =>
                                setReplyingMessage(null)
                            }
                        />
                    )}
                    {ticket &&
                        ticket.status === 'pending' &&
                        ticket.queueId && (
                            <div className="whats-app-chat__accept-ticket">
                                <Button
                                    theme="primary"
                                    onClick={handleAcceptTicket}
                                    disabled={isCallingAPI}
                                >
                                    <IconMessages size="medium" />
                                    {__('tickets.startChating')} X
                                </Button>
                            </div>
                        )}
                    {ticket && ticket.status != 'open' && !ticket.queueId && (
                        <div className="whats-app-chat__accept-ticket">
                            <ReopenTicketTextButton ticket={ticket} />
                        </div>
                    )}
                    {isChatLocked && (
                        <div className="whats-app-chat__accept-ticket">
                            <SendTemplateButton ticket={ticket} />
                        </div>
                    )}
                </div>
            </div>
            {sidePane === 'contact' && contact && (
                <WhatsAppContactInfoPane
                    onClose={() => setSidePane(null)}
                    contact={contact}
                />
            )}
            {sidePane === 'notes' && ticket && (
                <WhatsAppNotesPane
                    onClose={() => setSidePane(null)}
                    onNotesChanged={refreshNotesCount}
                    ticket={ticket}
                />
            )}
            {sidePane === 'tags' && ticket && (
                <WhatsAppTagsPane
                    onClose={() => setSidePane(null)}
                    ticket={ticket}
                />
            )}
        </>
    );
};

export default WhatsAppChat;
