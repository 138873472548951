import { Button, IconTimes, Inline, Text } from '@7shifts/sous-chef';
import { Message } from 'types';
import classNames from 'classnames';
import MarkdownWrapper from '../MarkdownWrapper';
import './ReplyingMessageDisplay.scss';

type Props = {
    replyingMessage: Message;
    closeReplyingMessage: () => void;
};

const ReplyingMessageDisplay = ({
    replyingMessage,
    closeReplyingMessage
}: Props) => {
    return (
        <div className="replying-message-display">
            <Inline flex={[1]}>
                <div
                    className={classNames('replying-message-display__message', {
                        ['replying-message-display__message--from-contact']:
                            !replyingMessage.fromMe
                    })}
                >
                    {replyingMessage.contact && (
                        <Text emphasis="bold" color="#6bcbef">
                            {replyingMessage.contact.name}
                        </Text>
                    )}
                    <MarkdownWrapper>{replyingMessage.body}</MarkdownWrapper>
                </div>
                <Button theme="link-icon" onClick={closeReplyingMessage}>
                    <IconTimes />
                </Button>
            </Inline>
        </div>
    );
};

export default ReplyingMessageDisplay;
