import {
    Button,
    PasswordField,
    TextField,
    Form,
    InlineBanner,
    AsyncSelectField,
    SelectOption,
    SelectField,
    Inline
} from '@7shifts/sous-chef';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { __ } from 'i18n';
import { getSchema } from './schema';
import { useFormik } from 'formik';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { addDays } from 'date-fns/esm';
import usePlans from 'hooks/usePlans';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { register } from 'api/accountApi';
import MaskedTextField from 'sharedComponents/MaskedTextField/MaskedTextField';

const SignupForm = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const wasLoggedOut = params.get('logged_out');
    const { handleLogin } = useAuthContext();
    const { list: listPlans } = usePlans();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            document: {
                label: 'CPF',
                value: 'cpf'
            },
            billingEmail: '',
            cpfCnpj: '',
            password: '',
            plan: null
        },
        validationSchema: getSchema(),
        onSubmit: async ({ plan, ...formValues }, { setSubmitting }) => {
            if (!plan) {
                return;
            }
            const data = {
                ...formValues,
                recurrence: 'MENSAL' as const,
                dueDate: addDays(new Date(), 3),
                status: 't' as const,
                campaignsEnabled: true,
                planId: (plan as SelectOption<number>).value
            };

            setSubmitting(true);
            try {
                await register(data);
                const isLogged = await handleLogin(formValues);
                setSubmitting(false);
                if (isLogged) {
                    navigate('/dashboard');
                }
            } catch (err) {
                handleError(err as AxiosError);
                setSubmitting(false);
            }
        }
    });

    return (
        <Form wide formik={formik}>
            {wasLoggedOut && (
                <InlineBanner theme="success">
                    {__('signupPage.youHaveBeenLoggedOut')}
                </InlineBanner>
            )}
            <TextField
                name="name"
                label={__('signupPage.companyName')}
                placeholder={__('signupPage.typeCompanyName')}
            />
            <Inline flex={['', 1]}>
                <SelectField
                    name="document"
                    label={__('signupPage.document')}
                    placeholder=""
                    options={[
                        {
                            label: 'CPF',
                            value: 'cpf'
                        },
                        {
                            label: 'CNPJ',
                            value: 'cnpj'
                        }
                    ]}
                    onChange={(option) => {
                        formik.setFieldValue('document', option);
                        formik.setFieldValue('cpfCnpj', '');
                        formik.setFieldTouched('cpfCnpj', false);
                    }}
                />
                <MaskedTextField
                    name="cpfCnpj"
                    label={formik.values.document.label}
                    value={formik.values.cpfCnpj}
                    mask={
                        formik.values.document.value === 'cpf'
                            ? '___.___.___-__'
                            : '__.___.___/____-__'
                    }
                    onChange={(value) => formik.setFieldValue('cpfCnpj', value)}
                />
            </Inline>
            <TextField
                name="email"
                label={__('loginPage.email')}
                placeholder={__('loginPage.typeEmail')}
            />
            <TextField
                name="billingEmail"
                label={__('loginPage.billingEmail')}
                placeholder={__('loginPage.typeBillingEmail')}
            />
            <PasswordField
                name="password"
                label={__('loginPage.password')}
                placeholder={__('loginPage.typePassword')}
            />
            <AsyncSelectField
                placeholder={__('component.select.placeholder')}
                loadOptions={() => {
                    return listPlans().then((data) => {
                        const options = data.map((plan) => ({
                            value: plan.id,
                            label: `${plan.name} - R$ ${plan.value},00`
                        }));
                        return {
                            options,
                            hasMore: false
                        };
                    });
                }}
                name="plan"
                label={__('signupPage.plan')}
            />
            <Button
                type="submit"
                theme="primary"
                size="full-width"
                loading={formik.isSubmitting}
                disabled={!formik.isValid || !formik.dirty}
            >
                {__('signupPage.register')}
            </Button>

            <Link to="/login">{__('signupPage.alreadyRegistered')}</Link>
        </Form>
    );
};

export default SignupForm;
