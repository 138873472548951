import {
    Button,
    Dropdown,
    DropdownList,
    DropdownListItem,
    IconFilePdf,
    IconGrinBeam,
    IconPlus,
    Inline,
    TextAreaField,
    toast
} from '@7shifts/sous-chef';
import './WhatsAppInput.scss';
import { Mic } from '@material-ui/icons';
import {
    ChangeEventHandler,
    ClipboardEventHandler,
    KeyboardEvent,
    useCallback,
    useState
} from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Message, Ticket } from 'types';
import { sendMessage } from 'api/messagesApi';
import { handleError } from 'api/apiUtils';
import WhatsAppAudioBox from '../WhatsAppAudioBox';
import MicRecorder from 'mic-recorder-to-mp3';
import api from '../../../../services/api';
import { AxiosError } from 'axios';
import WhatsAppMediasBox from '../WhatsAppMediasBox';
import ReplyingMessageDisplay from '../ReplyingMessageDisplay';
import { __ } from 'i18n';
import EmojiPicker from '../EmojiPicker';
import WhatsAppQuickMessages from '../WhatsAppQuickMessages';
import { useDropzone } from 'react-dropzone';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

type Props = {
    ticket: Ticket;
    replyingMessage: Message | null;
    closeReplyingMessage: () => void;
};

const WhatsAppInput = ({
    ticket,
    replyingMessage,
    closeReplyingMessage
}: Props) => {
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const [recording, setRecording] = useState(false);
    const [medias, setMedias] = useState<File[]>([]);

    const onKeyPress = (e: KeyboardEvent) => {
        if (loading || e.shiftKey) return;
        else if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === '') return;
        setLoading(true);

        sendMessage({
            ticketId: ticket.id,
            message: inputMessage,
            signMessage: user.isSignatureEnabled,
            userName: user.name,
            replyingMessage
        })
            .then(() => {
                setShowEmoji(false);
                setInputMessage('');
                closeReplyingMessage();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    };

    const setFocusOnInput = () => {
        const input = document.querySelector(
            '#input-container textarea'
        ) as HTMLTextAreaElement;
        input.focus();
    };

    const selectFiles = () => {
        const input = document.querySelector(
            '#input-container > input'
        ) as HTMLTextAreaElement;
        input.click();
    };

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toast(err as string);
            setLoading(false);
        }
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toast(err as string);
        }
    };

    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3();
            if (blob.size < 10000) {
                setLoading(false);
                setRecording(false);
                return;
            }

            const formData = new FormData();
            const filename = `audio-record-site-${new Date().getTime()}.mp3`;
            formData.append('medias', blob, filename);
            formData.append('body', filename);
            formData.append('fromMe', 'true');

            await api.post(`/messages/${ticket.id}`, formData);

            closeReplyingMessage();
        } catch (err) {
            handleError(err as AxiosError);
        }

        setRecording(false);
        setLoading(false);
    };

    const fileChecker = (file: File) => {
        const allowedVideos: string[] = ['video/3gp', 'video/mp4'];
        const allowedAudios: string[] = [
            'audio/aac',
            'audio/amr',
            'audio/mpeg',
            'audio/mp4',
            'audio/ogg'
        ];
        const allowedAudiosExtensions: string[] = [
            '.aac',
            '.amr',
            'mp3',
            'm4a',
            'ogg'
        ];
        const allowedVideosExtensions: string[] = ['.3gp', '.mp4'];
        const allowedImages: string[] = [
            'image/jpeg',
            'image/png',
            'image/webp'
        ];
        const allowedImagesExtensions: string[] = ['.jpeg', '.png', '.webp'];
        const allowedDocuments: string[] = [
            'text/plain',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/pdf'
        ];
        const allowedDocumentExtensions: string[] = [
            '.txt',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.pdf'
        ];
        if (file.type.indexOf('image') > -1) {
            if (allowedImages.indexOf(file.type) === -1) {
                throw __('main.formatError', {
                    formats: allowedImagesExtensions.join(', ')
                });
            }
            if (file.size > 5 * 1024 * 1024) {
                throw __('main.sizeError', {
                    size: 5
                });
            }
        } else if (file.type.indexOf('video') > -1) {
            if (allowedVideos.indexOf(file.type) === -1) {
                throw __('main.formatError', {
                    formats: allowedVideosExtensions.join(', ')
                });
            }
            if (file.size > 16 * 1024 * 1024) {
                throw __('main.sizeError', {
                    size: 16
                });
            }
        } else if (file.type.indexOf('audio') > -1) {
            if (allowedAudios.indexOf(file.type) === -1) {
                throw __('main.formatError', {
                    formats: allowedAudiosExtensions.join(', ')
                });
            }
            if (file.size > 16 * 1024 * 1024) {
                throw __('main.sizeError', {
                    size: 16
                });
            }
        } else if (
            file.type.indexOf('application') > -1 ||
            file.type.indexOf('text') > -1
        ) {
            if (allowedDocuments.indexOf(file.type) === -1) {
                throw __('main.formatError', {
                    formats: allowedDocumentExtensions.join(', ')
                });
            }
            if (file.size > 16 * 1024 * 1024) {
                throw __('main.sizeError', {
                    size: 16
                });
            }
        } else {
            throw __('main.unacceptedExtension');
        }
    };

    const checkMediaInput = (files: File[]): boolean => {
        try {
            for (const _file of files) {
                fileChecker(_file);
            }
            return true;
        } catch (e) {
            toast(e as string, 'danger');
            return false;
        }
    };

    const handleChangeMedias: ChangeEventHandler<HTMLInputElement> = (e) => {
        console.log('handleChangeMedias');
        const files = e.target.files;
        if (!files) {
            return;
        }
        const selectedMedias = Array.from(files);
        if (!checkMediaInput(selectedMedias)) {
            return;
        }
        setMedias(selectedMedias);
    };

    const handleOnPaste: ClipboardEventHandler<HTMLElement> = (event) => {
        const clipboardItems = event.clipboardData.items;
        const items = [].slice
            .call(clipboardItems)
            .filter(function (item: File) {
                return /^image\//.test(item.type);
            }) as DataTransferItem[];
        if (items.length > 0) {
            const _files = [] as File[];
            for (const dataTransferItem of items) {
                _files.push(dataTransferItem!.getAsFile() as File);
            }

            if (!checkMediaInput(_files)) {
                return;
            }

            setMedias(_files);
            return;
        }
    };

    const handleUploadMedia = async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('fromMe', 'true');
        medias.forEach((media) => {
            formData.append('medias', media, media.name);
            formData.append('body', media.name);
        });

        try {
            await api.post(`/messages/${ticket.id}`, formData);
        } catch (err) {
            console.log(err);
            handleError(err as AxiosError);
        }

        setLoading(false);
        setMedias([]);
        closeReplyingMessage();
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles) {
            if (!checkMediaInput(acceptedFiles)) {
                return;
            }
            setMedias(acceptedFiles);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone(
        {
            onDrop,
            noClick: true,
            autoFocus: false
        }
    );
    return (
        <section onPaste={handleOnPaste} ref={inputRef}>
            <div
                {...getRootProps()}
                onFocus={(e) => e.preventDefault()}
                onBlur={(e) => e.preventDefault()}
            >
                <input {...getInputProps()} />
                <div className="whats-app-input" id="input-container">
                    <input
                        multiple
                        type="file"
                        id="upload-button"
                        disabled={
                            loading || recording || ticket.status !== 'open'
                        }
                        style={{ display: 'none' }}
                        onChange={handleChangeMedias}
                    />
                    {medias.length > 0 && (
                        <WhatsAppMediasBox
                            onCancel={() => setMedias([])}
                            onUpload={handleUploadMedia}
                            loading={loading}
                            medias={medias}
                        />
                    )}
                    {recording && (
                        <WhatsAppAudioBox
                            onCancel={handleCancelAudio}
                            onSendAudio={handleUploadAudio}
                            loading={loading}
                        />
                    )}
                    {showEmoji && (
                        <EmojiPicker
                            onEmojiSelect={(emoji) => {
                                setInputMessage(inputMessage + emoji);
                                setShowEmoji(false);
                                setFocusOnInput();
                            }}
                        />
                    )}
                    {replyingMessage && (
                        <ReplyingMessageDisplay
                            replyingMessage={replyingMessage}
                            closeReplyingMessage={closeReplyingMessage}
                        />
                    )}
                    <TextAreaField
                        id={`message${isDragActive ? '-dragging' : ''}`}
                        name="message"
                        minHeight={60}
                        onKeyPress={onKeyPress}
                        value={inputMessage}
                        onChange={setInputMessage}
                        toolbar={
                            <Inline space={0}>
                                <Button
                                    theme="link-icon"
                                    onClick={() =>
                                        setShowEmoji((prev) => !prev)
                                    }
                                >
                                    <IconGrinBeam />
                                </Button>
                                <Dropdown
                                    trigger={
                                        <Button theme="link-icon">
                                            <IconPlus />
                                        </Button>
                                    }
                                    alignment="left"
                                >
                                    <DropdownList>
                                        <DropdownListItem
                                            prefix={
                                                <IconFilePdf size="medium" />
                                            }
                                            onClick={selectFiles}
                                        >
                                            {__('tickets.attachment')}
                                        </DropdownListItem>
                                        <DropdownListItem
                                            prefix={<Mic />}
                                            onClick={handleStartRecording}
                                        >
                                            {__('tickets.audio')}
                                        </DropdownListItem>
                                    </DropdownList>
                                </Dropdown>
                            </Inline>
                        }
                    />
                    <WhatsAppQuickMessages
                        inputMessage={inputMessage}
                        onSelect={setInputMessage}
                    />
                </div>
            </div>
        </section>
    );
};

export default WhatsAppInput;
