import {
    Avatar,
    Card,
    Inline,
    Modal,
    ModalBody,
    Stack,
    Text
} from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';

type Contact = {
    name: string;
    number: string;
};

type Props = {
    contacts: Contact[];
    onClose: () => void;
    onChat: (phoneNumber: string) => void;
};

const ContactsModal = ({ contacts, onClose, onChat }: Props) => {
    return (
        <Modal
            onClose={onClose}
            header={__('tickets.amountOfContacts', { count: contacts.length })}
        >
            <ModalBody>
                {contacts.map((contact, key) => (
                    <Card mb={8} onClick={() => onChat(contact.number)}>
                        <Inline alignItems="center" key={key}>
                            <Avatar size="large" />
                            <Stack space={4}>
                                <Text as="h3">{contact.name}</Text>
                                <Text>{contact.number}</Text>
                            </Stack>
                        </Inline>
                    </Card>
                ))}
            </ModalBody>
        </Modal>
    );
};

export default ContactsModal;
