import { AsyncSelectField, SelectOption } from '@7shifts/sous-chef';
import { getUser, getUsers } from '../../api/usersApi';
import { useEffect } from 'react';
import { __ } from 'i18n/translate';

type Props = {
    initialuserId?: number | null;
    onInitialOptionLoaded?: (option: SelectOption<number>) => void;
    name: string;
    label?: string;
    onChange?: (e: SelectOption<number>) => void;
    value?: SelectOption<number>;
    placeholder?: string;
};

const UserSelectField = ({
    initialuserId,
    onInitialOptionLoaded,
    name,
    label,
    onChange,
    value,
    placeholder
}: Props) => {
    useEffect(() => {
        if (initialuserId) {
            getUser(initialuserId).then((user) => {
                if (onInitialOptionLoaded) {
                    onInitialOptionLoaded({
                        value: user.id,
                        label: user.name
                    });
                }
            });
        }
    }, [initialuserId]);

    return (
        <AsyncSelectField
            name={name}
            placeholder={placeholder || __('component.select.placeholder')}
            label={label}
            value={value}
            loadOptions={(search) => {
                return getUsers(search, 1).then(({ users, hasMore }) => {
                    return {
                        options: users.map((user) => ({
                            value: user.id,
                            label: user.name
                        })),
                        hasMore
                    };
                });
            }}
            isClearable
            onChange={onChange}
        />
    );
};

export default UserSelectField;
