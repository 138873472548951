import TicketsPage from './pages/TicketsPage';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';

export const ROUTES = [
    {
        path: '/tickets',
        element: <TicketsPage />,
        errorElement: <ErrorBoundary />
    }
];
