import api from '../services/api';

export const acceptTicket = (ticketId: number) => {
    return api.post(`/tickets-v2/acceptTicket/${ticketId}`, {});
};

export const reopenTicket = (ticketId: number, queueId?: number | null) => {
    return api.post(`/tickets-v2/reopenTicket/${ticketId}`, {
        queueId
    });
};

export const sendTicketToWaitingRoom = (ticketId: number) => {
    return api.put(`/tickets/${ticketId}`, {
        status: 'pending',
        userId: null
    });
};

export const transferTicketToUser = (
    ticketId: number,
    data: {
        userId: number;
        queueId?: number;
    }
) => {
    return api.post(`/tickets-v2/transferTicket/${ticketId}`, data);
};

export const transferTicketToQueue = (ticketId: number, queueId: number) => {
    return api.put(`/tickets/${ticketId}`, {
        status: 'pending',
        userId: null,
        queueId
    });
};

export const closeTicket = (ticketId: number, userId: number) => {
    return api.put(`/tickets/${ticketId}`, {
        status: 'closed',
        justClose: true,
        userId: userId
    });
};

export const deleteTicket = (ticketId: number) => {
    return api.delete(`/tickets/${ticketId}`);
};

type TicketData = {
    contactId: number;
    queueId: number;
    userId: number;
    whatsappId: number;
};
export const createNewTicket = (data: TicketData) => {
    return api.post(`/tickets`, {
        ...data,
        chatbot: false,
        status: 'open'
    });
};
