import {
    Button,
    IconFilePdf,
    IconMegaphone,
    IconPaperPlane,
    IconTrash,
    Inline,
    Stack,
    Text,
    Tooltip
} from '@7shifts/sous-chef';
import './WhatsAppMediasBox.scss';
import { __ } from 'i18n/translate';

type Props = {
    onCancel: () => void;
    onUpload: () => void;
    loading: boolean;
    medias: File[];
};

const WhatsAppMediasBox = ({ onCancel, onUpload, loading, medias }: Props) => {
    const renderFileType = (file: File) => {
        if (file.type.includes('video')) {
            return (
                <video
                    src={URL.createObjectURL(file)}
                    className="whats-app-medias-box__upload-media"
                />
            );
        } else if (file.type.includes('image')) {
            return (
                <img
                    src={URL.createObjectURL(file)}
                    className="whats-app-medias-box__upload-media"
                />
            );
        } else if (
            file.type.includes('document') ||
            file.type.includes('application')
        ) {
            return (
                <Stack justifyContent="center" alignItems="center">
                    <IconFilePdf
                        className="whats-app-medias-box__upload-media"
                        size="large"
                    />
                    {file.name}
                </Stack>
            );
        } else if (file.type.includes('audio')) {
            return (
                <Stack justifyContent="center" alignItems="center">
                    <IconMegaphone
                        className="whats-app-medias-box__upload-media"
                        size="large"
                    />
                    {file.name}
                </Stack>
            );
        }
        return <></>;
    };
    const Media = ({
        file,
        showTooltip
    }: {
        file: File;
        showTooltip?: boolean;
    }) => {
        const fileTag = renderFileType(file);

        if (showTooltip) {
            return <Tooltip overlay={file.name}>{fileTag}</Tooltip>;
        }
        return fileTag;
    };

    const AMOUNT_OF_MEDIAS_TO_SHOW = 3;

    const visibleMedias = medias.slice(0, AMOUNT_OF_MEDIAS_TO_SHOW);
    const hiddenMedias = medias.slice(AMOUNT_OF_MEDIAS_TO_SHOW);

    return (
        <div className="whats-app-medias-box">
            <Inline justifyContent="space-between" alignItems="center">
                <Button
                    theme="link-icon"
                    title="Delete files"
                    onClick={onCancel}
                    disabled={loading}
                >
                    <IconTrash color="radish-500" />
                </Button>
                <Inline
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    {visibleMedias.map((media, key) => (
                        <a
                            href={URL.createObjectURL(media)}
                            target="_blank"
                            key={key}
                        >
                            <Media file={media} key={key} />
                        </a>
                    ))}
                    {hiddenMedias.length > 0 && (
                        <Tooltip
                            overlay={
                                <Stack>
                                    {hiddenMedias.map((media, key) => (
                                        <a
                                            href={URL.createObjectURL(media)}
                                            target="_blank"
                                            key={key}
                                        >
                                            <Inline alignItems="center">
                                                <Media
                                                    file={media}
                                                    showTooltip={false}
                                                />
                                                <Text>{media.name}</Text>
                                            </Inline>
                                        </a>
                                    ))}
                                </Stack>
                            }
                            theme="white"
                            delayOnClose
                        >
                            <span>
                                {__('tickets.moreMedia', {
                                    count: hiddenMedias.length
                                })}
                            </span>
                        </Tooltip>
                    )}
                </Inline>
                <Button
                    theme="link-icon"
                    title="Send"
                    onClick={onUpload}
                    loading={loading}
                >
                    <IconPaperPlane />
                </Button>
            </Inline>
        </div>
    );
};

export default WhatsAppMediasBox;
