/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck Ignore this file until we get back to it
/**
 * ALEX NOTE: This file needs to be properly converted to TypeScript.
 * It was just copied from the legacy codebase.
 */

import { useCallback, useEffect, useReducer, useState } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import useTickets from './useTickets';
import { socketConnection } from '../services/socket';
import { Ticket, TicketStatus } from 'types';

const reducer = (state, action) => {
    if (action.type === 'LOAD_TICKETS') {
        const newTickets = action.payload || [];

        newTickets.forEach((ticket) => {
            const ticketIndex = state.findIndex((t) => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        return [...state];
    }

    if (action.type === 'RESET_UNREAD') {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === 'UPDATE_TICKET') {
        const ticket = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === 'UPDATE_TICKET_UNREAD_MESSAGES') {
        const ticket = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === 'UPDATE_TICKET_CONTACT') {
        const contact = action.payload;
        const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === 'DELETE_TICKET') {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }

        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

type UseChatsParams = {
    status: TicketStatus | OnChatBotStatus;
    searchParam: string;
    tags: number[];
    user?: number;
    showAll: boolean;
    selectedQueueIds: number[];
    hasScroll: boolean;
};

type UseChatsResponse = {
    ticketsList: Ticket[];
    loading: boolean;
    onScroll: (e: React.UIEvent<HTMLElement>) => void;
};

export const useChats = ({
    status,
    searchParam,
    tags,
    user: userFilter,
    showAll,
    selectedQueueIds,
    hasScroll
}: UseChatsParams): UseChatsResponse => {
    const [pageNumber, setPageNumber] = useState(1);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const { user } = useAuthContext();

    const tagsHash = JSON.stringify(tags);
    const queuesHash = JSON.stringify(selectedQueueIds);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [status, dispatch, showAll, tagsHash, queuesHash, userFilter]);

    useEffect(() => {
        dispatch({ type: 'RESET' });
    }, [searchParam]);

    const updateState = useCallback(
        (data: Ticket[]) => {
            if (!status && !searchParam) return;
            dispatch({
                type: 'LOAD_TICKETS',
                payload: data
            });
        },
        [status, searchParam]
    );

    const { hasMore, loading } = useTickets({
        pageNumber,
        searchParam,
        tags: JSON.stringify(tags),
        status,
        showAll,
        queueIds: JSON.stringify(selectedQueueIds),
        users: JSON.stringify(userFilter ? [userFilter] : []),
        updateState
    });

    const loadMore = useCallback(() => {
        if (!hasMore || loading) return;
        setPageNumber((prevState) => prevState + 1);
    }, [hasMore, loading]);

    useEffect(() => {
        const { profile, queues, companyDistribuitionMethod } = user;
        const isAdmin = profile === 'admin';
        const companyId = localStorage.getItem('companyId');
        const socket = socketConnection({ companyId });

        const shouldUpdateTicket = (ticket: Ticket) => {
            //usuario está logado ou ticket não é objeto valido
            if (!user || !user.id || !ticket || !ticket.id) {
                return false;
            }

            if (shouldFilterOutTicket(ticket)) {
                return false;
            }

            const queuesIds = queues.map((a) => a.id);
            if (isAdmin) {
                //usuário admin
                if (
                    (ticket.queueId &&
                        queuesIds.indexOf(ticket.queueId) > -1) ||
                    !ticket.queueId
                ) {
                    return true;
                }
            } else {
                //usuário normal
                if (ticket.chatbot || !ticket.queueId) {
                    return false;
                }
                if (ticket.status === 'open') {
                    return (
                        queuesIds.indexOf(ticket.queueId) > -1 &&
                        ticket.userId === user.id
                    );
                } else if (ticket.status === 'pending') {
                    if (companyDistribuitionMethod === 'manual') {
                        return (
                            queuesIds.indexOf(ticket.queueId) > -1 &&
                            (!ticket.userId || ticket.userId === user.id)
                        );
                    } else {
                        return ticket.userId === user.id;
                    }
                } else if (ticket.status === 'closed') {
                    return ticket.userIdClosed === user.id;
                }
            }
        };

        const shouldFilterOutTicket = (ticket: Ticket) => {
            console.log('Checking filter status', status, ticket.status);
            if (status !== ticket.status) {
                console.log('is different status');
                return true;
            }
            console.log('Checking user', userFilter, ticket.userId);
            if (userFilter && ticket.userId !== userFilter) {
                console.log('is different user');
                return true;
            }

            console.log(
                'Checking departments',
                selectedQueueIds,
                ticket.queueId
            );
            if (
                selectedQueueIds.length > 0 &&
                ticket.queueId &&
                !selectedQueueIds.includes(ticket.queueId)
            ) {
                console.log('is different department');
                return true;
            }

            console.log('Checking tags', tags, ticket.tags);
            if (tags.length > 0) {
                const hasSomeFilteredTags = ticket.tags.some((tag) =>
                    tags.includes(tag.id)
                );

                if (!hasSomeFilteredTags) {
                    console.log('does not have tag');
                    return true;
                }
            }

            return false;
        };

        socket.on('connect', () => {
            if (status) {
                socket.emit('joinTickets', status);
            } else {
                socket.emit('joinNotification');
            }
        });

        socket.on(`company-${companyId}-ticket`, (data) => {
            if (data.action === 'updateUnread') {
                dispatch({
                    type: 'RESET_UNREAD',
                    payload: data.ticketId
                });
            } else if (data.action === 'update') {
                if (shouldUpdateTicket(data.ticket)) {
                    console.log('UPDATE_TICKET', data.ticket);
                    dispatch({
                        type: 'UPDATE_TICKET',
                        payload: data.ticket
                    });
                } else {
                    dispatch({
                        type: 'DELETE_TICKET',
                        payload: data.ticket.id
                    });
                }
            } else if (
                data.action === 'delete' ||
                data.action === 'removeFromList'
            ) {
                dispatch({ type: 'DELETE_TICKET', payload: data.ticketId });

                // If there is no scroll we load more items if some tickets are removed from the list
                if (!hasScroll) {
                    loadMore();
                }
            }
        });

        socket.on(`company-${companyId}-appMessage`, (data) => {
            if (data.action === 'create' && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: 'UPDATE_TICKET_UNREAD_MESSAGES',
                    payload: data.ticket
                });
            }
        });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === 'update') {
                dispatch({
                    type: 'UPDATE_TICKET_CONTACT',
                    payload: data.contact
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [status, showAll, user, selectedQueueIds, loadMore, hasScroll]);

    const onScroll = (e: React.UIEvent<HTMLElement>) => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            console.log('LOADING MORE');
            loadMore();
        }
    };

    return {
        ticketsList,
        loading,
        onScroll
    };
};
