import {
    Button,
    CheckboxField,
    Form,
    Inline,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextAreaField,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import ColorPicker from 'sharedComponents/ColorPicker';
import { COLORS } from 'sharedComponents/ColorPicker/constants';
import { addQueue, editQueue } from 'api/queuesApi';
import { handleError } from 'api/apiUtils';
import { getScheduleTypeSetting } from '../../../../domain/settings';
import { DEFAULT_SCHEDULES } from './constants';
import { Queue } from 'types';
import { useRefetchList } from 'hooks/useRefetchList';
import { useSettings } from 'hooks/useSettings';

type Props = {
    onClose: () => void;
    editingQueue?: Queue;
};

const ManageQueueModal = ({ onClose, editingQueue }: Props) => {
    const { isLoadingSettings, settings } = useSettings();
    const { refreshList } = useRefetchList();
    const formik = useFormik({
        initialValues: {
            name: editingQueue ? editingQueue.name : '',
            color: editingQueue
                ? editingQueue.color.replace('#', '')
                : COLORS[Math.floor(Math.random() * COLORS.length)],
            greetingMessage: editingQueue ? editingQueue.greetingMessage : '',
            outOfHoursMessage: editingQueue
                ? editingQueue.outOfHoursMessage
                : '',
            allowPreferAttendant: editingQueue
                ? editingQueue.allowPreferAttendant
                : false
        },
        validationSchema: getSchema(),
        onSubmit: ({ color, ...values }, { setSubmitting }) => {
            setSubmitting(true);

            if (editingQueue) {
                editQueue(editingQueue.id, {
                    color: `#${color}`,
                    schedules: DEFAULT_SCHEDULES,
                    ...values
                })
                    .then(() => {
                        refreshList();
                        onClose();
                        toast(
                            __('main.edited', { domainName: __('main.queue') })
                        );
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                addQueue({
                    color: `#${color}`,
                    schedules: DEFAULT_SCHEDULES,
                    ...values
                })
                    .then(() => {
                        toast(
                            __('main.added', { domainName: __('main.queue') })
                        );
                        refreshList();
                        onClose();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        }
    });

    const schedulesEnabled =
        !isLoadingSettings && getScheduleTypeSetting(settings) === 'queue';

    return (
        <Modal
            header={
                editingQueue
                    ? __('queuesPage.editQueue')
                    : __('queuesPage.addQueue')
            }
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form formik={formik} stackContent={false}>
                    <ModalBody>
                        <Stack>
                            <Inline flex={[1]}>
                                <TextField
                                    name="name"
                                    label={__('queuesPage.name')}
                                    placeholder={__('queuesPage.typeName')}
                                />
                                <ColorPicker
                                    value={formik.values.color}
                                    onChange={(color) =>
                                        formik.setFieldValue('color', color)
                                    }
                                    mt={23}
                                />
                            </Inline>
                            <TextAreaField
                                name="greetingMessage"
                                label={__('queuesPage.greetingMessage')}
                                placeholder={__(
                                    'queuesPage.typeGreetingMessage'
                                )}
                            />
                            {schedulesEnabled && (
                                <TextAreaField
                                    name="outOfHoursMessage"
                                    label={__('queuesPage.outOfHoursMessage')}
                                    placeholder={__(
                                        'queuesPage.typeOutOfHoursMessage'
                                    )}
                                />
                            )}
                            <CheckboxField
                                name="allowPreferAttendant"
                                label={__('queuesPage.allowPreferAttendant')}
                                caption={__(
                                    'queuesPage.allowPreferAttendantCaption'
                                )}
                            />
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingQueue
                                        ? __('queuesPage.editQueue')
                                        : __('queuesPage.addQueue')}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageQueueModal;
