import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required(
            __('main.required', {
                fieldName: __('usersPage.name')
            })
        ),
        email: Yup.string().email(__('loginPage.emailInvalid'))
    });
};
