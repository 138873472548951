import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

// Determine if click was inside the provided element
function isClickInside<T extends HTMLElement>(
    ref: RefObject<T>,
    event: UIEvent
): boolean {
    return (
        !ref.current ||
        (event.target instanceof Node && ref.current.contains(event.target))
    );
}

/**
 * @param cb {Function} callback to be invoked when a click occurs outside the reference element. Wrap your callback in useCallback to prevent re-renders.
 */
const useOnClickOutside = <T extends HTMLElement>(
    ref: RefObject<T>,
    cb: (event?: UIEvent) => void
) => {
    useEffect(() => {
        const listener = (event: Event) => {
            if (isClickInside(ref, event)) {
                return;
            }
            cb(event);
        };

        const onKeyUp = (event: KeyboardEvent) => {
            if (event.code !== 'Escape') {
                return;
            }
            cb(event);
        };

        document.addEventListener('mousedown', listener, false);
        document.addEventListener('touchstart', listener, false);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('mousedown', listener, false);
            document.removeEventListener('touchstart', listener, false);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [ref, cb]);
};

export default useOnClickOutside;
