import { Button, IconPlug, IconPlus } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageChannelModal from '../ManageChannelModal';
import { useLocation } from 'react-router-dom';
import { useMetaAuthentication } from 'hooks/useMetaAuthentication';

const ChannelPageActions = () => {
    const { launchWhatsAppSignup } = useMetaAuthentication();

    const location = useLocation();
    const [showModal, setShowModal] = useState(() => {
        if (location.state?.add) {
            return true;
        }
        return false;
    });

    return (
        <>
            <Button theme="upsell" onClick={launchWhatsAppSignup}>
                <IconPlug size="medium" />
                {__('channelsPage.addWhatsAppOfficial')}
            </Button>{' '}
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconPlus size="medium" />
                {__('channelsPage.addWhatsApp')}
            </Button>
            {showModal && (
                <ManageChannelModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default ChannelPageActions;
