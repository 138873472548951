import {
    Button,
    Form,
    FormRow,
    FormSection,
    ModalBody,
    ModalFooter,
    SelectOption,
    TextAreaField,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { useFormik } from 'formik';
import QueueSelectField from 'sharedComponents/QueueSelectField';
import { Setting } from 'types';
import { getSchema } from './schema';
import { __ } from 'i18n/translate';
import { updateSetting } from 'api/settingsApi';
import { handleError } from 'api/apiUtils';
import { useAuthContext } from 'contexts/AuthContext';

type Props = {
    onClose: () => void;
    settings: Setting[];
};

const FlowSettingsForm = ({ onClose, settings }: Props) => {
    const { user } = useAuthContext();
    const findSetting = (key: string, defaultValue: string) => {
        const setting = settings.find((s) => s.key === key);
        if (setting) {
            return setting.value;
        }
        return defaultValue;
    };

    const formik = useFormik({
        initialValues: {
            IdleTimeInMinutes: findSetting('IdleTimeInMinutes', ''),
            IdleMaxTries: findSetting('IdleMaxTries', ''),
            IdleTransferQueue: null,
            IdleMessage: findSetting('IdleMessage', ''),
            IdleOverflownMessage: findSetting('IdleOverflownMessage', '')
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            if (!values.IdleTransferQueue) {
                return;
            }

            setSubmitting(true);

            Promise.all([
                updateSetting({
                    key: 'IdleTimeInMinutes',
                    value: values.IdleTimeInMinutes
                }),
                updateSetting({
                    key: 'IdleMaxTries',
                    value: values.IdleMaxTries
                }),
                updateSetting({
                    key: 'IdleMessage',
                    value: values.IdleMessage
                }),
                updateSetting({
                    key: 'IdleTransferQueueId',
                    value: (values.IdleTransferQueue as SelectOption<string>)
                        .value
                }),
                updateSetting({
                    key: 'IdleOverflownMessage',
                    value: values.IdleOverflownMessage
                })
            ])
                .then(() => {
                    toast(
                        __('main.edited', {
                            domainName: __('settingsPage.flowSettings')
                        })
                    );
                    onClose();
                })
                .catch((err) => {
                    handleError(err);
                });
        }
    });

    return (
        <Form stackContent={false} formik={formik}>
            <ModalBody>
                <FormSection
                    title={__('settingsPage.userInactivity')}
                    subtitle={__('settingsPage.userInactivityDescription')}
                >
                    <FormRow>
                        <TextField
                            name="IdleTimeInMinutes"
                            suffix="minutos"
                            label={__('settingsPage.idleTime')}
                            caption={__('settingsPage.idleTimeCaption')}
                            onChange={(e) => {
                                if (Number.isInteger(+e)) {
                                    formik.setFieldValue(
                                        'IdleTimeInMinutes',
                                        e
                                    );
                                }
                            }}
                        />
                        <TextField
                            name="IdleMaxTries"
                            label={__('settingsPage.idleMaxTries')}
                            caption={__('settingsPage.idleMaxTriesCaption')}
                            onChange={(e) => {
                                if (Number.isInteger(+e)) {
                                    formik.setFieldValue('IdleMaxTries', e);
                                }
                            }}
                        />
                    </FormRow>
                    <QueueSelectField
                        userId={user.id}
                        initialQueueId={
                            +findSetting('IdleTransferQueueId', '0')
                        }
                        name="IdleTransferQueue"
                        label={__('settingsPage.idleTransferQueue')}
                        caption={__('settingsPage.idleTransferQueueCaption')}
                        onInitialOptionLoaded={(option) =>
                            formik.setFieldValue('IdleTransferQueue', option)
                        }
                    />
                    <TextAreaField
                        name="IdleMessage"
                        label={__('settingsPage.idleMessage')}
                        caption={__('settingsPage.idleMessageCaption')}
                    />
                    <TextAreaField
                        name="IdleOverflownMessage"
                        label={__('settingsPage.idleOverflownMessage')}
                        caption={__('settingsPage.idleOverflownMessageCaption')}
                    />
                </FormSection>
            </ModalBody>
            <ModalFooter
                actions={{
                    primary: (
                        <Button
                            type="submit"
                            disabled={!formik.dirty || !formik.isValid}
                            loading={formik.isSubmitting}
                        >
                            {__('main.save')}
                        </Button>
                    ),
                    secondary: (
                        <Button onClick={onClose}>{__('main.cancel')}</Button>
                    )
                }}
            />
        </Form>
    );
};

export default FlowSettingsForm;
