import {
    Button,
    Chip,
    IconSearch,
    Inline,
    Text,
    TextField
} from '@7shifts/sous-chef';
import { ReactNode, useRef } from 'react';
import WhatsAppHeader from '../WhatsAppHeader';
import './TicketsContactsPane.scss';
import TicketsFilter from '../TicketsFilter';
import { useSearchParams } from 'react-router-dom';
import { useSearchField } from 'hooks/useSearchField';
import { __ } from 'i18n';
import NewTicketButton from '../NewTicketButton';

type Props = {
    children: ReactNode;
};
const TicketsContactsPane = ({ children }: Props) => {
    const { searchValue, setSearchValue } = useSearchField();
    const searchRef = useRef<HTMLInputElement>(null);
    const [params, setParams] = useSearchParams();
    const showClosedFilter = params.get('showClosed') === 'true';
    const queuesFilter = params.get('queues');
    const tagsFilter = params.get('tags');
    const userFilter = params.get('user');
    const amountOfFilteredQueues = queuesFilter
        ? queuesFilter.split(',').length
        : 0;
    const amountOfFilteredTags = tagsFilter ? tagsFilter.split(',').length : 0;
    const hasFilters =
        userFilter ||
        showClosedFilter ||
        amountOfFilteredQueues > 0 ||
        amountOfFilteredTags > 0 ||
        searchValue !== '';
    return (
        <div className="tickets-contacts-pane">
            <WhatsAppHeader showBorder>
                <Inline alignItems="center" justifyContent="space-between">
                    <div />
                    <Inline>
                        <NewTicketButton />
                    </Inline>
                </Inline>
            </WhatsAppHeader>
            <div className="tickets-contacts-pane__filters">
                <Inline flex={[1]} space={4} alignItems="center">
                    <TextField
                        name="search"
                        prefix={<IconSearch />}
                        placeholder={__('tickets.searchTicketsPlaceholder')}
                        defaultValue={searchValue}
                        onChange={setSearchValue}
                        ref={searchRef}
                    />
                    <TicketsFilter />
                </Inline>
                {hasFilters && (
                    <div className="tickets-contacts-pane__filters-applied">
                        <Inline space={8} alignItems="center">
                            <Text>{__('tickets.filters')}:</Text>
                            {showClosedFilter && (
                                <Chip theme="marketing">
                                    {__('tickets.closedTickets')}
                                </Chip>
                            )}
                            {amountOfFilteredQueues > 0 && (
                                <Chip theme="success">
                                    {__('tickets.filteredQueues', {
                                        count: amountOfFilteredQueues
                                    })}
                                </Chip>
                            )}
                            {amountOfFilteredTags > 0 && (
                                <Chip theme="warning">
                                    {__('tickets.filteredTags', {
                                        count: amountOfFilteredTags
                                    })}
                                </Chip>
                            )}
                            {userFilter && (
                                <Chip theme="upsell">
                                    {__('tickets.filteredUsers', {
                                        count: 1
                                    })}
                                </Chip>
                            )}
                            <Button
                                theme="link-primary"
                                onClick={() => {
                                    params.delete('showClosed');
                                    params.delete('queues');
                                    params.delete('search');
                                    params.delete('tags');
                                    params.delete('user');
                                    if (searchRef.current) {
                                        searchRef.current.value = '';
                                    }
                                    setParams(params);
                                }}
                            >
                                {__('tickets.resetFilters')}
                            </Button>
                        </Inline>
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

export default TicketsContactsPane;
