import api from '../services/api';
import { Schedule } from 'types';

export type GetSchedulesResponse = {
    schedules: Schedule[];
    count: number;
    hasMore: boolean;
};

export const getSchedules = (
    searchParam: string,
    pageNumber: number
): Promise<GetSchedulesResponse> => {
    return api
        .get('/schedules/', {
            params: { searchParam, pageNumber }
        })
        .then(({ data }) => data);
};

type ScheduleData = {
    body: string;
    contactId: number;
    sendAt: string;
    userId: number;
};
export const addSchedule = (newSchedule: ScheduleData): Promise<Schedule> => {
    return api
        .post('/schedules', { ...newSchedule, sentAt: '' })
        .then(({ data }) => data);
};

type EditScheduleData = {
    companyId: number;
} & ScheduleData;
export const editSchedule = (
    scheduleId: number,
    editingTemplate: EditScheduleData
): Promise<Schedule> => {
    return api
        .put('/schedules/' + scheduleId, editingTemplate)
        .then(({ data }) => data);
};

export const deleteSchedule = (scheduleId: number): Promise<void> => {
    return api.delete('/schedules/' + scheduleId);
};
