import {
    Avatar,
    Button,
    Card,
    Form,
    Inline,
    Modal,
    ModalBody,
    ModalFooter,
    SelectField,
    Stack,
    Text,
    toast
} from '@7shifts/sous-chef';
import {
    CustomOptionProps,
    SelectedOptionPrefixProps
} from '@7shifts/sous-chef/dist/forms/SelectField/types';
import { useWhatsContext } from 'contexts/WhatsAppContext/WhatsAppContext';
import { useFormik } from 'formik';
import { getSchema, FormValues } from './schema';
import ContactSelectField from 'sharedComponents/ContactSelectField';
import { Contact, Queue, WhatsApp } from 'types';
import { createNewTicket } from 'api/ticketsApi';
import { useAuthContext } from 'contexts/AuthContext';
import { handleError } from 'api/apiUtils';
import { useNavigate } from 'react-router-dom';
import { __ } from 'i18n/translate';
import { capitalizeFirstLetter } from 'utils/string';

type Props = {
    onClose: () => void;
    initialContact?: Contact;
};

const CreateTicketModal = ({ onClose, initialContact }: Props) => {
    const { user } = useAuthContext();
    const { whatsApps } = useWhatsContext();
    const navigate = useNavigate();

    const channelOptions = whatsApps
        .filter((channel) => channel.status === 'CONNECTED')
        .map((channel) => ({
            value: channel,
            label: channel.name
        }));

    const formik = useFormik<FormValues>({
        initialValues: {
            contact: initialContact
                ? { label: initialContact.name, value: initialContact.id }
                : null,
            channel: channelOptions[0],
            department: null
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            if (!values.contact || !values.department) {
                return;
            }
            setSubmitting(true);
            createNewTicket({
                contactId: values.contact.value,
                queueId: values.department.value.id,
                userId: user.id,
                whatsappId: values.channel.value.id
            })
                .then(({ data }) => {
                    toast(__('tickets.newTicketCreated'));
                    navigate(`/tickets?ticket=${data.uuid}`);
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    const departmentOptions = formik.values.channel.value.queues.map(
        (queue) => ({
            label: queue.name,
            value: queue
        })
    );

    return (
        <Modal header={__('tickets.newTicket')} onClose={onClose}>
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        {initialContact ? (
                            <Card>
                                <Inline alignItems="center">
                                    <Avatar
                                        url={initialContact.profilePicUrl}
                                    />
                                    <Stack space={4}>
                                        <Text as="h3">
                                            {initialContact.name}
                                        </Text>
                                        <Text>{initialContact.number}</Text>
                                    </Stack>
                                </Inline>
                            </Card>
                        ) : (
                            <ContactSelectField
                                name="contact"
                                label={__('contactsPage.contact')}
                            />
                        )}
                        <SelectField
                            placeholder={__("component.select.placeholder")}
                            name="channel"
                            label={__('main.channel')}
                            options={channelOptions}
                            CustomOption={({
                                label,
                                value
                            }: CustomOptionProps<WhatsApp>) => (
                                <Inline alignItems="center" space={8}>
                                    <Avatar size="medium">{label[0]}</Avatar>
                                    <Stack space={0}>
                                        <Text as="body">{label}</Text>
                                        <Text as="caption" color="grey-300">
                                            {value.actualNumber}
                                        </Text>
                                    </Stack>
                                </Inline>
                            )}
                            SelectedOptionPrefix={({
                                selectedOption
                            }: SelectedOptionPrefixProps<WhatsApp>) => (
                                <Avatar size="small">
                                    {selectedOption.label[0]}
                                </Avatar>
                            )}
                            onChange={() => {
                                formik.setFieldValue('department', null);
                            }}
                        />
                        <SelectField
                            placeholder={__("component.select.placeholder")}
                            name="department"
                            label={capitalizeFirstLetter(__('main.queue'))}
                            options={departmentOptions}
                            CustomOption={({
                                label,
                                value
                            }: CustomOptionProps<Queue>) => (
                                <Inline alignItems="center" space={8}>
                                    <Avatar size="small" color={value.color}>
                                        {label[0]}
                                    </Avatar>
                                    {label}
                                </Inline>
                            )}
                            SelectedOptionPrefix={({
                                selectedOption
                            }: SelectedOptionPrefixProps<Queue>) => (
                                <Avatar
                                    size="small"
                                    color={selectedOption.value.color}
                                >
                                    {selectedOption.label[0]}
                                </Avatar>
                            )}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {__('tickets.createTicket')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default CreateTicketModal;
