import { Button, IconUserPlus } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageUserModal from '../ManageUserModal/ManageUserModal';

const UsersPageActions = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconUserPlus size="medium" />
                {__('usersPage.addUser')}
            </Button>
            {showModal && (
                <ManageUserModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default UsersPageActions;
