import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    MultiSelectField,
    SelectOption,
    Stack,
    TextAreaField,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useEffect, useState } from 'react';
import { getQueues } from 'api/queuesApi';
import { handleError } from 'api/apiUtils';
import { Queue, WhatsApp } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { usePermission } from 'hooks/usePermission';
import { ACTIONS } from '../../../../permissions';
import { useFormik } from 'formik';
import { getSchema } from './schema';
import { useRefetchList } from 'hooks/useRefetchList';
import { addWhatsApp, editWhatsApp } from 'api/whatsAppApi';

type Props = {
    onClose: () => void;
    editingWhatsApp?: WhatsApp;
};

const ManageChannelModal = ({ onClose, editingWhatsApp }: Props) => {
    const [queues, setQueues] = useState<Queue[]>([]);
    const [showMoreAutomatedMessages, setShowMoreAutomatedMessages] =
        useState(false);
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();

    const canEditQueues = usePermission(
        user.profile,
        ACTIONS['user-modal:editQueues']
    );

    useEffect(() => {
        getQueues()
            .then((data) => setQueues(data))
            .catch(handleError);
    }, []);

    const getInitialValues = () => {
        if (editingWhatsApp) {
            return {
                name: editingWhatsApp.name,
                greetingMessage: editingWhatsApp.greetingMessage,
                complationMessage: editingWhatsApp.complationMessage,
                outOfHoursMessage: editingWhatsApp.outOfHoursMessage,
                ratingMessage: editingWhatsApp.ratingMessage,
                queues: editingWhatsApp.queues.map((queue) => ({
                    label: queue.name,
                    value: queue.id
                }))
            };
        }
        return {
            name: '',
            greetingMessage: '',
            complationMessage: '',
            outOfHoursMessage: '',
            ratingMessage: '',
            queues: [] as SelectOption<number>[]
        };
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(),
        onSubmit: ({ queues, ...values }, { setSubmitting }) => {
            setSubmitting(true);
            const queueIds = (queues || []).map((queue) => queue.value);

            const whatsAppData = { queueIds, ...values };

            const request = editingWhatsApp
                ? editWhatsApp(editingWhatsApp.id, {
                      ...whatsAppData,
                      token: editingWhatsApp.token,
                      isDefault: editingWhatsApp.isDefault,
                      flowIdentifier: editingWhatsApp.flowIdentifier || ''
                  })
                : addWhatsApp({
                      ...whatsAppData,
                      isDefault: true,
                      flowIdentifier: ''
                  });

            request
                .then(() => {
                    toast(
                        editingWhatsApp
                            ? __('channelsPage.channelAdded')
                            : __('channelsPage.channelEdited')
                    );
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingWhatsApp
                    ? __('channelsPage.editWhatsApp')
                    : __('channelsPage.addWhatsApp')
            }
            onClose={onClose}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <TextField
                            name="name"
                            label={__('channelsPage.name')}
                            placeholder={__('channelsPage.typeName')}
                        />
                        {canEditQueues && (
                            <MultiSelectField
                                name="queues"
                                label={__('main.queues')}
                                options={queues.map((queue) => ({
                                    label: queue.name,
                                    value: queue.id
                                }))}
                            />
                        )}
                        <TextAreaField
                            name="greetingMessage"
                            label={__('channelsPage.greetingMessage')}
                            placeholder={__('channelsPage.typeGreetingMessage')}
                        />
                        {!showMoreAutomatedMessages && !editingWhatsApp && (
                            <Button
                                theme="link-primary"
                                onClick={() =>
                                    setShowMoreAutomatedMessages(true)
                                }
                            >
                                {__('channelsPage.showMoreMessages')}
                            </Button>
                        )}
                        {(showMoreAutomatedMessages || editingWhatsApp) && (
                            <Stack>
                                <TextAreaField
                                    name="closingMessage"
                                    label={__('channelsPage.closingMessage')}
                                />
                                <TextAreaField
                                    name="outOfHoursMessage"
                                    label={__('channelsPage.outOfHoursMessage')}
                                />
                                <TextAreaField
                                    name="ratingMessage"
                                    label={__('channelsPage.ratingMessage')}
                                />
                            </Stack>
                        )}
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {editingWhatsApp
                                    ? __('channelsPage.editWhatsApp')
                                    : __('channelsPage.addWhatsApp')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ManageChannelModal;
