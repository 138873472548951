import { Modal, ModalBody, Spinner } from '@7shifts/sous-chef';
import { handleError } from 'api/apiUtils';
import { getCompany } from 'api/companiesApi';
import { useAuthContext } from 'contexts/AuthContext';
import WorkingHoursFormModal from './WorkingHoursFormModal';
import { useEffect, useState } from 'react';
import { Company } from 'types';

type Props = {
    onClose: () => void;
};

const ConfigureWorkingHoursModal = ({ onClose }: Props) => {
    const [company, setCompany] = useState<Company>();
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuthContext();
    const companyId = user.companyId;

    useEffect(() => {
        getCompany(companyId)
            .then(setCompany)
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, [companyId]);

    if (isLoading || !company) {
        return (
            <Modal header="Configure working hours">
                <ModalBody>
                    <Spinner block></Spinner>
                </ModalBody>
            </Modal>
        );
    }

    return <WorkingHoursFormModal onClose={onClose} company={company} />;
};

export default ConfigureWorkingHoursModal;
