import { Stack, Text } from '@7shifts/sous-chef';
import illustration from 'assets/login-illustration.svg';
import { __ } from 'i18n';

const LoginMarketingPane = () => {
    return (
        <>
            <img src={illustration} alt="" />
            <Stack alignItems="center" mt={22}>
                <Text as="h1" alignment="center">
                    {__('loginPage.header')}
                </Text>
                <Text color="#6d87dd" as="h1">
                    {__('loginPage.headerNextLine')} 🎉
                </Text>
            </Stack>
        </>
    );
};

export default LoginMarketingPane;
