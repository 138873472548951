import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import UsersPage from './pages/UsersPage';

export const ROUTES = [
    {
        path: '/users',
        element: <UsersPage />,
        errorElement: <ErrorBoundary />
    }
];
