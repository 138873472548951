import {
    Avatar,
    Badge,
    EmptyState,
    Inline,
    Skeleton,
    Stack,
    Text,
    Tooltip
} from '@7shifts/sous-chef';
import { useChats } from 'hooks/useChats';
import { useAuthContext } from '../../../../contexts/AuthContext';
import MarkdownWrapper from '../MarkdownWrapper';
import { formmatRelative } from '../../../../utils/date';
import i18n from 'i18n/i18n';
import './TicketList.scss';
import { __ } from 'i18n';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import ticketsEmptyStateIllustration from 'assets/tickets-empty-state.svg';
import { useLayoutEffect, useRef, useState } from 'react';
import TicketBadges from 'modules/Tickets/components/TicketBadges';

type Props = {
    view: string;
};

const TicketsList = ({ view }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hasScroll, setHasScroll] = useState(false);
    const [params, setParams] = useSearchParams();
    const { user } = useAuthContext();
    const { profile } = user;
    const isAdmin = profile === 'admin';
    let status = 'pending';
    switch (view) {
        case 'working':
            status = 'open';
            break;
        case 'waiting-room':
            status = 'pending';
            break;
        case 'onchatbot-room':
            if (isAdmin) {
                status = 'onchatbot';
            }
            break;
    }

    const queueFilters = params.get('queues');
    const tagFilters = params.get('tags');
    const userFilter = params.get('user');
    const search = params.get('search');
    const selectedQueueIds = queueFilters
        ? queueFilters.split(',').map((id) => +id)
        : [];
    const selectedTagIds = tagFilters
        ? tagFilters.split(',').map((id) => +id)
        : [];

    const isShowingClosed =
        params.get('showClosed') === 'true' && status === 'open';

    const { ticketsList, loading, onScroll } = useChats({
        showAll: true,
        searchParam: search || '',
        status: isShowingClosed ? 'closed' : status,
        tags: selectedTagIds,
        user: userFilter ? +userFilter : undefined,
        selectedQueueIds:
            selectedQueueIds.length > 0
                ? selectedQueueIds
                : user.queues.map((queue) => queue.id),
        hasScroll
    });

    const amountOfItems = ticketsList.length;

    useLayoutEffect(() => {
        if (ref.current) {
            setHasScroll(ref.current.scrollHeight > ref.current.clientHeight);
        }
    }, [amountOfItems]);

    return (
        <div
            className="tickets-contacts-pane__content"
            onScroll={onScroll}
            ref={ref}
        >
            <Stack space={0} justifyContent="center">
                {!loading && ticketsList.length === 0 && (
                    <Inline alignItems="center" justifyContent="center" mt={22}>
                        <EmptyState
                            title={__('main.nothingFound')}
                            mediaUrl={ticketsEmptyStateIllustration}
                            size="small"
                        />
                    </Inline>
                )}
                {ticketsList.map((ticket) => (
                    <div
                        className={classNames('ticket-list__item', {
                            'ticket-list__item--selected':
                                ticket.uuid === params.get('ticket')
                        })}
                        key={ticket.id}
                        onClick={() => {
                            params.set('ticket', ticket.uuid);
                            setParams(params);
                        }}
                    >
                        <Tooltip
                            overlay={
                                ticket.queue ? ticket.queue.name : 'No queue'
                            }
                        >
                            <div
                                className="ticket-list__tag-indicator"
                                style={{
                                    backgroundColor: ticket.queue
                                        ? ticket.queue.color
                                        : 'var(--color-grey-200)'
                                }}
                            />
                        </Tooltip>
                        <Inline alignItems="center">
                            <Avatar
                                size="large"
                                url={ticket.contact.profilePicUrl}
                            />
                            <Stack space={4}>
                                <div className="ticket-list__contact-name">
                                    {ticket.contact.name}
                                </div>
                                <div className="ticket-list__message-preview">
                                    <MarkdownWrapper>
                                        {ticket.lastMessage}
                                    </MarkdownWrapper>
                                </div>
                                <TicketBadges ticket={ticket} />
                            </Stack>
                        </Inline>
                        <Stack space={4} alignItems="flex-end">
                            {ticket.unreadMessages && (
                                <div className="ticket-list__badge-row">
                                    <Badge theme="success">
                                        {ticket.unreadMessages}
                                    </Badge>
                                </div>
                            )}
                            <Text color="mint-400" emphasis="bold">
                                {formmatRelative(
                                    new Date(ticket.updatedAt),
                                    i18n.language === 'en' ? '12h' : '24h'
                                )}
                            </Text>
                        </Stack>
                    </div>
                ))}
                {loading &&
                    Array.from(Array(8)).map((_, index) => (
                        <Inline alignItems="center" space={8} key={index}>
                            <Skeleton width={12} height={60} ml={2} />
                            <Skeleton as="avatar--large" />
                            <Stack space={4} ml={8}>
                                <Skeleton width={100} />
                                <Skeleton width={200} height={10} />
                            </Stack>
                        </Inline>
                    ))}
            </Stack>
        </div>
    );
};

export default TicketsList;
