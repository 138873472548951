import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import ChannelPageActions from '../components/ChannelPageActions';
import ChannelsTable from '../components/ChannelsTable';

const ChannelsListPage = () => {
    return (
        <Page title={__('main.channels')} actions={<ChannelPageActions />}>
            <ChannelsTable />
        </Page>
    );
};

export default ChannelsListPage;
