import * as Yup from 'yup';
import { __ } from 'i18n';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { SelectOption } from '@7shifts/sous-chef';

export const getSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required(__('signupPage.companyNameRequired')),
        email: Yup.string()
            .email(__('loginPage.emailInvalid'))
            .required(__('loginPage.emailRequired')),
        billingEmail: Yup.string()
            .email(__('loginPage.emailInvalid'))
            .required(__('loginPage.emailRequired')),
        password: Yup.string()
            .min(5, __('loginPage.passwordTooShort'))
            .required(__('loginPage.passwordRequired')),
        plan: Yup.object().required(__('signupPage.planRequired')),
        cpfCnpj: Yup.string()
            .required(__('signupPage.cpfCnpjRequired'))
            .when('document', {
                is: (option: SelectOption<string>) => option.value === 'cpf',
                then: () =>
                    Yup.string().test(
                        'cpf-invalid',
                        __('signupPage.cpfInvalid'),
                        (value) => {
                            if (cpf.isValid(value || '')) {
                                return true;
                            }
                            return false;
                        }
                    ),
                otherwise: () =>
                    Yup.string().test(
                        'cnpj-invalid',
                        __('signupPage.cnpjInvalid'),
                        (value) => {
                            if (cnpj.isValid(value || '')) {
                                return true;
                            }
                            return false;
                        }
                    )
            })
            .test(
                'cpfcnpj-invalid',
                __('signupPage.cpfCnpjInvalid'),
                (value) => {
                    if (cnpj.isValid(value) || cpf.isValid(value)) {
                        return true;
                    }
                    return false;
                }
            )
    });
};
