import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    toast
} from '@7shifts/sous-chef';
import { QueueOption } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import { useRefetchList } from 'hooks/useRefetchList';
import { handleError } from 'api/apiUtils';
import { deleteQueueOption } from 'api/queueOptionsApi';

type Props = {
    deletingQueueOption: QueueOption;
    onClose: () => void;
};

const DeleteQueueOptionModal = ({ deletingQueueOption, onClose }: Props) => {
    const { refreshList } = useRefetchList();
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDelete = () => {
        setIsDeleting(true);

        deleteQueueOption(deletingQueueOption.id)
            .then(() => {
                refreshList();
                setIsDeleting(false);
                toast(
                    __('main.deleted', {
                        domainName: __(
                            'queuesPage.queueOption'
                        ).toLocaleLowerCase()
                    })
                );
                onClose();
            })
            .catch((err) => {
                setIsDeleting(false);
                handleError(err);
            });
    };
    return (
        <Modal
            onClose={onClose}
            header={__('main.deleteDomain', {
                domainName: __('queuesPage.queueOption').toLocaleLowerCase()
            })}
        >
            <ModalBody>
                {__('main.confirmDeleteDomain', {
                    domainName: deletingQueueOption.title
                })}
            </ModalBody>
            <ModalFooter
                actions={{
                    primary: (
                        <Button
                            onClick={handleDelete}
                            loading={isDeleting}
                            theme="danger"
                        >
                            {__('main.delete')}
                        </Button>
                    ),
                    secondary: (
                        <Button onClick={onClose}>{__('main.cancel')}</Button>
                    )
                }}
            />
        </Modal>
    );
};

export default DeleteQueueOptionModal;
