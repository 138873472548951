import {
    Button,
    IconFourSquares,
    IconSync,
    IconTimes,
    Inline,
    Text
} from '@7shifts/sous-chef';
import { WhatsAppStatus as WhatsAppStatusType } from 'types';
import { __ } from 'i18n';
import {
    disconnectWhatsAppSession,
    reconnectWhatsAppSession,
    requestNewQRCode
} from 'api/whatsAppApi';
import { handleError } from 'api/apiUtils';

type Props = {
    status: WhatsAppStatusType;
    whatsAppId: number;
    showQRCode: () => void;
};

const ChannelSessionActions = ({ status, whatsAppId, showQRCode }: Props) => {
    switch (status) {
        case 'qrcode':
            return (
                <>
                    <Button theme="default" onClick={showQRCode}>
                        <IconFourSquares size="medium" />
                        {__('channelsPage.statusQRCode')}
                    </Button>
                </>
            );
        case 'DISCONNECTED':
            return (
                <Inline space={12}>
                    <Button
                        theme="hollow"
                        onClick={() =>
                            reconnectWhatsAppSession(whatsAppId).catch(
                                handleError
                            )
                        }
                    >
                        <IconSync size="medium" />
                        {__('channelsPage.tryAgain')}
                    </Button>
                    <Button
                        theme="hollow"
                        onClick={() => {
                            requestNewQRCode(whatsAppId).catch(handleError);
                        }}
                    >
                        <IconFourSquares size="medium" />
                        {__('channelsPage.newQRCode')}
                    </Button>
                </Inline>
            );
        case 'CONNECTED':
        case 'PAIRING':
        case 'TIMEOUT':
            return (
                <Button
                    theme="link-danger"
                    onClick={() => {
                        disconnectWhatsAppSession(whatsAppId).catch(
                            handleError
                        );
                    }}
                >
                    <IconTimes size="medium" />
                    {__('channelsPage.disconnect')}
                </Button>
            );
        case 'OPENING':
            return <Text>{__('channelsPage.connecting')}...</Text>;
    }
};

export default ChannelSessionActions;
