import {
    Button,
    CheckboxField,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextAreaField,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import { addQuickMesssage, editQuickMesssage } from 'api/quickmessagesApi';
import { handleError } from 'api/apiUtils';
import { useRefetchList } from 'hooks/useRefetchList';
import { QuickMessage } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';

type Props = {
    onClose: () => void;
    editingMessage?: QuickMessage;
};

const ManageQuickMessageModal = ({ onClose, editingMessage }: Props) => {
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();
    const formik = useFormik({
        initialValues: {
            id: editingMessage ? editingMessage.id : undefined,
            isGlobal: editingMessage ? editingMessage.isGlobal : false,
            shortcode: editingMessage ? editingMessage.shortcode : '',
            message: editingMessage ? editingMessage.message : ''
        },
        validationSchema: getSchema(),
        onSubmit: ({ ...values }, { setSubmitting }) => {
            setSubmitting(true);
            if (editingMessage && editingMessage.id) {
                editQuickMesssage(editingMessage.id, values)
                    .then(() => {
                        refreshList();
                        toast(
                            __('main.edited', {
                                domainName: __('main.quickMessages')
                            })
                        );
                        onClose();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                addQuickMesssage(values)
                    .then(() => {
                        refreshList();
                        toast(
                            __('main.added', {
                                domainName: __('main.quickMessages')
                            })
                        );
                        onClose();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        }
    });

    return (
        <Modal
            header={
                editingMessage
                    ? __('quickMessagesPage.editQuickMessage')
                    : __('quickMessagesPage.addQuickMessage')
            }
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form formik={formik} stackContent={false}>
                    <ModalBody>
                        <Stack>
                            <TextField
                                name="shortcode"
                                label={__('quickMessagesPage.shortcut')}
                                placeholder={__(
                                    'quickMessagesPage.typeShortcut'
                                )}
                            />
                            <TextAreaField
                                name="message"
                                label={__('quickMessagesPage.message')}
                                placeholder={__(
                                    'quickMessagesPage.typeMessage'
                                )}
                            />
                            {user.profile === 'admin' && (
                                <CheckboxField
                                    name="isGlobal"
                                    label={__('quickMessagesPage.isGlobal')}
                                    caption={__(
                                        'quickMessagesPage.explanationMessage'
                                    )}
                                />
                            )}
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingMessage
                                        ? __(
                                              'quickMessagesPage.editQuickMessage'
                                          )
                                        : __(
                                              'quickMessagesPage.addQuickMessage'
                                          )}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageQuickMessageModal;
