import api from '../services/api';
import { Setting } from 'types';

export const getSettings = (): Promise<Setting[]> => {
    return api.get('/settings').then(({ data }) => data);
};

type EditSettingData = {
    key: string;
    value: string;
};
export const updateSetting = (data: EditSettingData): Promise<Setting> => {
    return api.put('/settings/' + data.key, data);
};
