import { DataTable } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { QueueOption } from 'types';
import emptyStateIllustration from 'assets/empty-state.svg';

type Props = {
    queueOptions: QueueOption[];
    isLoading: boolean;
};
const QueueOptionsTable = ({ queueOptions, isLoading }: Props) => {
    const COLUMNS = [
        {
            name: 'title',
            label: __('queuesPage.optionTitle')
        },
        {
            name: 'description',
            label: __('queuesPage.optionDescription')
        }
    ];

    return (
        <DataTable
            items={queueOptions}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    context: 'female',
                    domainName: __('queuesPage.queueOption').toLocaleLowerCase()
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default QueueOptionsTable;
