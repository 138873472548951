import { AccessTime, Done, DoneAll, Warning } from '@material-ui/icons';
import './MessageAck.scss';
import classNames from 'classnames';

type Props = {
    ack: number;
};

const MessageAck = ({ ack }: Props) => {
    switch (ack) {
        case -1:
            return (
                <Warning
                    fontSize="small"
                    className={classNames('message-ack', 'message-ack--error')}
                />
            );
        case 0:
            return <AccessTime fontSize="small" className="message-ack" />;
        case 1:
            return <Done fontSize="small" className="message-ack" />;
        case 2:
            return <DoneAll fontSize="small" className="message-ack" />;
        case 3:
        case 4:
            return (
                <DoneAll
                    fontSize="small"
                    className={classNames(
                        'message-ack',
                        'message-ack--all-done'
                    )}
                />
            );
        default:
            return null;
    }
};

export default MessageAck;
