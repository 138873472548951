import vCardParser from 'vcard-parser';

export const getContactFromMessage = (
    message: string
): { name: string; number: string } => {
    let name = '';
    let number = '';
    try {
        const parsedCard = vCardParser.parse(message);
        name = parsedCard?.fn[0]?.value;
        number = parsedCard?.tel[0]?.value;

        if (parsedCard.tel.meta?.waid) {
            number = parsedCard.tel.meta?.waid;
        }
        if (number) {
            number = (number + '').replace(/[^\d]+/g, '');
        }
    } catch (e) {
        return {
            name: '',
            number: ''
        };
    }
    return {
        name,
        number
    };
};
