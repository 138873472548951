import { useEffect, useState } from 'react';
import api from '../services/api';
import { useAuthContext } from '../contexts/AuthContext';
import { toast } from '@7shifts/sous-chef';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleError } from '../api/apiUtils';
import { AxiosError } from 'axios';
import { Contact, Ticket } from 'types';
import { socketConnection } from '../services/socket';

type UseTicketInfoResponse = {
    loading: boolean;
    contact?: Contact;
    ticket?: Ticket;
};
export const useTicketInfo = (ticketUUId: string): UseTicketInfoResponse => {
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState<Contact>();
    const [ticket, setTicket] = useState<Ticket>();
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const [params] = useSearchParams();
    const showClosed = params.get('showClosed');
    const view = params.get('view');

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get('/tickets/u/' + ticketUUId);
                    const { queueId, userIdClosed } = data;
                    const { queues, profile } = user;
                    const queueAllowed =
                        queues.find((q) => q.id === queueId) === undefined &&
                        userIdClosed != user?.id;

                    if (queueAllowed && profile !== 'admin') {
                        toast('Acesso não permitido', 'danger');
                        navigate('/tickets');
                        return;
                    }

                    setContact(data.contact);
                    setTicket(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    handleError(err as AxiosError);
                }
            };
            fetchTicket();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketUUId, user, navigate, showClosed, view]);

    useEffect(() => {
        if (!ticket) {
            return;
        }
        const companyId = localStorage.getItem('companyId');
        const socket = socketConnection({ companyId });

        socket.on('connect', () => socket.emit('joinChatBox', `${ticket.id}`));

        socket.on(`company-${companyId}-ticket`, (data) => {
            if (data.action === 'update') {
                setTicket(data.ticket);
            }

            if (data.action === 'delete') {
                toast('Ticket deleted sucessfully.');
                navigate('/tickets');
            }
        });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === 'update') {
                setContact((prevState) => {
                    if (prevState && prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact };
                    }
                    return prevState;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [ticketUUId, ticket, navigate]);

    return { loading, contact, ticket };
};
