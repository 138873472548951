import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        password: Yup.string()
            .min(5, __('loginPage.passwordTooShort'))
            .required(__('loginPage.passwordRequired')),
        confirmPassword: Yup.string()
            .required(
                __('main.required', {
                    fieldName: __('usersPage.confirmPassword')
                })
            )
            .oneOf([Yup.ref('password')], __('usersPage.passwordsShouldMatch'))
    });
};
