import {
    Button,
    Form,
    Inline,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextField
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import ColorPicker from 'sharedComponents/ColorPicker';
import { COLORS } from 'sharedComponents/ColorPicker/constants';
import { addTag, editTag } from 'api/tagsApi';
import { handleError } from 'api/apiUtils';
import { useRefetchList } from 'hooks/useRefetchList';
import { Tag } from 'types';

type Props = {
    onClose: () => void;
    editingTag?: Tag;
};

const ManageTagModal = ({ onClose, editingTag }: Props) => {
    const { refreshList } = useRefetchList();
    const formik = useFormik({
        initialValues: {
            name: editingTag ? editingTag.name : '',
            color: editingTag
                ? editingTag.color.replace('#', '')
                : COLORS[Math.floor(Math.random() * COLORS.length)]
        },
        validationSchema: getSchema(),
        onSubmit: ({ color, ...values }, { setSubmitting }) => {
            setSubmitting(true);
            if (editingTag) {
                editTag(editingTag.id, {
                    color: `#${color}`,
                    ...values
                })
                    .then(() => {
                        refreshList();
                        onClose();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            } else {
                addTag({
                    color: `#${color}`,
                    ...values
                })
                    .then(() => {
                        refreshList();
                        onClose();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        }
    });

    return (
        <Modal
            header={editingTag ? __('tagsPage.editTag') : __('tagsPage.addTag')}
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form formik={formik} stackContent={false}>
                    <ModalBody>
                        <Stack>
                            <Inline flex={[1]}>
                                <TextField
                                    name="name"
                                    label={__('tagsPage.name')}
                                    placeholder={__('tagsPage.typeName')}
                                />
                                <ColorPicker
                                    value={formik.values.color}
                                    onChange={(color) =>
                                        formik.setFieldValue('color', color)
                                    }
                                    mt={23}
                                />
                            </Inline>
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingTag
                                        ? __('tagsPage.editTag')
                                        : __('tagsPage.addTag')}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageTagModal;
