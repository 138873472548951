import { Avatar, Inline, MultiSelectField } from '@7shifts/sous-chef';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Queue } from 'types';
import { CustomOptionProps } from '@7shifts/sous-chef/dist/forms/SelectField/types';
import { __ } from 'i18n/translate';

type Props = {
    disabled?: boolean;
};
const TicketsFilterQueue = ({ disabled }: Props) => {
    const [params, setParams] = useSearchParams();
    const { user } = useAuthContext();
    const queueFilters = params.get('queues');
    const selectedQueueIds = queueFilters ? queueFilters.split(',') : [];
    const queuesMap = user.queues.reduce<{ [key: number]: Queue }>(
        (map, queue) => ({
            ...map,
            [queue.id]: queue
        }),
        {}
    );

    const selectedQueueOptions = user.queues
        .filter((queue) => selectedQueueIds.includes(queue.id.toString()))
        .map((queue) => ({
            value: queue.id,
            label: queue.name
        }));

    return (
        <MultiSelectField
            name="queues"
            label={__('main.queues')}
            placeholder={__('tickets.showAll')}
            disabled={disabled}
            value={selectedQueueOptions}
            options={user.queues.map((queue) => ({
                value: queue.id,
                label: queue.name
            }))}
            onChange={(selectedOptions) => {
                if (!selectedOptions) {
                    params.delete('queues');
                } else {
                    params.set(
                        'queues',
                        selectedOptions
                            .map((options) => options.value)
                            .join(',')
                    );
                }

                setParams(params);
            }}
            CustomOption={({ label, value }: CustomOptionProps<number>) => (
                <Inline alignItems="center" space={8}>
                    <Avatar
                        size="small"
                        color={
                            queuesMap[value]
                                ? queuesMap[value].color
                                : 'grey-200'
                        }
                    >
                        {' '}
                    </Avatar>
                    {label}
                </Inline>
            )}
        />
    );
};

export default TicketsFilterQueue;
