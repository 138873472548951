import {
    Button,
    Card,
    Form,
    FormRow,
    Modal,
    ModalBody,
    ModalFooter,
    Paywall,
    Stack,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { useFormik } from 'formik';
import { WhatsApp } from 'types';
import { getSchema } from './schema';
import { editWhatsApp } from 'api/whatsAppApi';
import { __ } from 'i18n/translate';
import { useRefetchList } from 'hooks/useRefetchList';
import { handleError } from 'api/apiUtils';
import ilustration from 'assets/work-chat.svg';

type Props = {
    whatsApp: WhatsApp;
    onClose: () => void;
};

const ChannelFlowSettingsModal = ({ whatsApp, onClose }: Props) => {
    const { refreshList } = useRefetchList();

    const formik = useFormik({
        initialValues: {
            flowIdentifier: whatsApp.flowIdentifier || ''
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);

            editWhatsApp(whatsApp.id, {
                isDefault: true,
                complationMessage: whatsApp.complationMessage,
                greetingMessage: whatsApp.greetingMessage,
                name: whatsApp.name,
                outOfHoursMessage: whatsApp.outOfHoursMessage,
                queueIds: whatsApp.queues.map((queue) => queue.id),
                ratingMessage: whatsApp.ratingMessage,
                token: whatsApp.token,
                flowIdentifier: values.flowIdentifier
            })
                .then(() => {
                    toast(__('channelsPage.channelEdited'));
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={__('channelsPage.channelFlow')}
            onClose={onClose}
            width={900}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <Card>
                            <Paywall
                                header={__('channelsPage.channelFlow')}
                                mediaUrl={ilustration}
                                size="medium"
                                title={__('channelsPage.channelFlowTitle')}
                            >
                                {__('channelsPage.channelFlowDescription')}
                            </Paywall>
                        </Card>
                        <FormRow columns={2}>
                            <TextField
                                name="flowIdentifier"
                                label={__('channelsPage.flowIdentifier')}
                                caption={__(
                                    'channelsPage.flowIdentifierCaption'
                                )}
                            />
                        </FormRow>
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.dirty || !formik.isValid}
                                loading={formik.isSubmitting}
                            >
                                {__('main.save')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        ),
                        tertiary: (
                            <Button
                                theme="link-primary"
                                href="https://flow.selenebot.com.br"
                                target="_blank"
                            >
                                {__('channelsPage.accessFlowPortal')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ChannelFlowSettingsModal;
