import {
    Button,
    Dropdown,
    IconBarsH,
    Stack,
    Text,
    Toggle
} from '@7shifts/sous-chef';
import { useSearchParams } from 'react-router-dom';
import TicketsFilterQueue from '../TicketsFilterQueue';
import TicketsFilterTags from '../TicketsFilterTags';
import { __ } from 'i18n';
import TicketsFilterUsers from '../TicketsFilterUsers';
import { useAuthContext } from 'contexts/AuthContext';

const TicketsFilter = () => {
    const [params, setParams] = useSearchParams();

    const { user } = useAuthContext();

    const isShowingClosed = params.get('showClosed') === 'true';

    return (
        <Dropdown
            trigger={
                <Button theme="link-icon">
                    <IconBarsH />
                </Button>
            }
            width={300}
        >
            <Stack>
                <Text as="h3">{__('tickets.filters')}</Text>
                <Toggle
                    checked={isShowingClosed}
                    onChange={() => {
                        if (isShowingClosed) {
                            params.set('showClosed', 'false');
                        } else {
                            params.set('showClosed', 'true');
                            params.delete('queues');
                        }
                        params.delete('ticket');
                        setParams(params);
                    }}
                    label={__('tickets.showClosedTickets')}
                />
                {user.profile === 'admin' && <TicketsFilterUsers />}
                <TicketsFilterQueue disabled={isShowingClosed} />
                <TicketsFilterTags />
            </Stack>
        </Dropdown>
    );
};

export default TicketsFilter;
