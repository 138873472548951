import { Page } from '@7shifts/sous-chef';
import MessageTemplateForm from '../components/MessageTemplateForm/MessageTemplateForm';

const MessageTemplatesNewPage = () => {
    return (
        <Page>
            <MessageTemplateForm />
        </Page>
    );
};

export default MessageTemplatesNewPage;
