import {
    Button,
    DataTable,
    DataTableColumn,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    toast
} from '@7shifts/sous-chef';
import { Company } from 'types';
import { WorkHoursItem } from '../types';
import { useFormik } from 'formik';
import { formatTimeAMPM, parseAMPMTo24hrs } from 'utils/date';
import { editCompanySchedules } from 'api/companiesApi';
import { __ } from 'i18n/translate';
import { handleError } from 'api/apiUtils';
import WorkingHoursRow from '../WorkingHoursRow';

type Props = {
    onClose: () => void;
    company: Company;
};

const WorkingHoursFormModal = ({ company, onClose }: Props) => {
    const formik = useFormik<{ workHours: WorkHoursItem[] }>({
        initialValues: {
            workHours: company.schedules.map((item) => {
                const open =
                    (item.startTime !== '00:00' && item.startTime !== '') ||
                    (item.endTime !== '00:00' && item.endTime !== '');
                return {
                    open,
                    weekday: item.weekday,
                    weekdayEn: item.weekdayEn,
                    startTime: open
                        ? formatTimeAMPM(item.startTime)
                        : '- - : - -',
                    endTime: open ? formatTimeAMPM(item.endTime) : '- - : - -'
                };
            })
        },
        onSubmit: (formValues, { setSubmitting }) => {
            setSubmitting(true);
            const workingHours = formValues.workHours.map((item) => ({
                weekday: item.weekday,
                weekdayEn: item.weekdayEn,
                startTime: item.open
                    ? parseAMPMTo24hrs(item.startTime) || '00:00'
                    : '00:00',
                endTime: item.open
                    ? parseAMPMTo24hrs(item.endTime) || '00:00'
                    : '00:00'
            }));

            editCompanySchedules(company.id, workingHours)
                .then(() => {
                    onClose();
                    toast(
                        __('main.edited', {
                            domainName: __('queuesPage.workingHours')
                        })
                    );
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    const columns: DataTableColumn[] = [
        {
            name: 'open',
            label: __('queuesPage.open'),
            size: 0.5
        },
        {
            name: 'weekday',
            label: __('date.day')
        },
        {
            name: 'startTime',
            label: __('queuesPage.startTime')
        },
        {
            name: 'endTime',
            label: __('queuesPage.endTime')
        }
    ];
    return (
        <Modal
            header={__('settingsPage.configureWorkingHours')}
            onClose={onClose}
            width={650}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <DataTable
                        items={formik.values.workHours}
                        columns={columns}
                        itemComponent={(args) => (
                            <WorkingHoursRow
                                {...args}
                                setFieldValue={formik.setFieldValue}
                            />
                        )}
                    />
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button type="submit" loading={formik.isSubmitting}>
                                {__('main.save')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default WorkingHoursFormModal;
