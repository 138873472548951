import {
    Button,
    CheckboxField,
    Form,
    FormRow,
    Modal,
    ModalBody,
    ModalFooter,
    MultiSelectField,
    PasswordField,
    SelectField,
    SelectOption,
    Stack,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useEffect, useState } from 'react';
import { getQueues } from 'api/queuesApi';
import { handleError } from 'api/apiUtils';
import { Queue, User } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { usePermission } from 'hooks/usePermission';
import { ACTIONS } from '../../../../permissions';
import { useFormik } from 'formik';
import { getSchema } from './schema';
import { addUser, editUser } from 'api/usersApi';
import { useRefetchList } from 'hooks/useRefetchList';

type Props = {
    onClose: () => void;
    editingUser?: User;
};

const ManageUserModal = ({ onClose, editingUser }: Props) => {
    const [queues, setQueues] = useState<Queue[]>([]);
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();

    const canEditProfile = usePermission(
        user.profile,
        ACTIONS['user-modal:editProfile']
    );
    const canEditQueues = usePermission(
        user.profile,
        ACTIONS['user-modal:editQueues']
    );

    useEffect(() => {
        getQueues()
            .then((data) => setQueues(data))
            .catch(handleError);
    }, []);

    const getInitialValues = () => {
        if (editingUser) {
            return {
                name: editingUser.name,
                profile: {
                    value: editingUser.profile,
                    label: editingUser.profile
                },
                email: editingUser.email,
                password: '',
                isAttendant: editingUser.isAttendant,
                onVacation: editingUser.onVacation,
                isActive: editingUser.isActive,
                queues: editingUser.queues.map((queue) => ({
                    label: queue.name,
                    value: queue.id
                }))
            };
        }
        return {
            name: '',
            profile: {
                value: 'user',
                label: 'user'
            },
            email: '',
            password: '',
            isAttendant: false,
            onVacation: false,
            isActive: false,
            queues: [] as SelectOption<number>[]
        };
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(!editingUser),
        onSubmit: ({ profile, queues, ...values }, { setSubmitting }) => {
            setSubmitting(true);
            const queueIds = queues.map((queue) => queue.value);

            const userData = { profile: profile.value, queueIds, ...values };

            const request = editingUser
                ? editUser(editingUser.id, {
                      companyId: user.company.id,
                      ...userData
                  })
                : addUser(userData);

            request
                .then(() => {
                    toast(
                        editingUser
                            ? __('usersPage.userEdited')
                            : __('usersPage.userAdded')
                    );
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingUser ? __('usersPage.editUser') : __('usersPage.addUser')
            }
            onClose={onClose}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <FormRow>
                            <TextField
                                name="name"
                                label={__('usersPage.name')}
                                placeholder={__('usersPage.typeName')}
                            />
                            {canEditProfile && (
                                <SelectField
                                    placeholder={__(
                                        'component.select.placeholder'
                                    )}
                                    name="profile"
                                    label={__('usersPage.profile')}
                                    options={[
                                        {
                                            value: 'admin',
                                            label: __('usersPage.admin')
                                        },
                                        {
                                            value: 'user',
                                            label: __('usersPage.user')
                                        }
                                    ]}
                                />
                            )}
                        </FormRow>
                        <TextField
                            name="email"
                            label={__('loginPage.email')}
                            placeholder={__('loginPage.typeEmail')}
                        />
                        {!editingUser && (
                            <PasswordField
                                name="password"
                                label={__('loginPage.password')}
                                placeholder={__('loginPage.typePassword')}
                            />
                        )}
                        {canEditQueues && (
                            <MultiSelectField
                                name="queues"
                                label={__('main.queues')}
                                options={queues.map((queue) => ({
                                    label: queue.name,
                                    value: queue.id
                                }))}
                            />
                        )}
                        <CheckboxField
                            name="isAttendant"
                            label={__('usersPage.isAttendant')}
                            caption={__('usersPage.isAttendantCaption')}
                        />
                        <CheckboxField
                            name="onVacation"
                            label={__('usersPage.onVacation')}
                            caption={__('usersPage.onVacationCaption')}
                        />
                        <CheckboxField
                            name="isActive"
                            label={__('usersPage.isActive')}
                            caption={__('usersPage.isActiveCaption')}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {editingUser
                                    ? __('usersPage.editUser')
                                    : __('usersPage.addUser')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ManageUserModal;
