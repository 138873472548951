import classNames from 'classnames';
import { Message } from 'types';
import './QuotedMessage.scss';

type Props = {
    message: Message;
};

const QuotedMessage = ({ message }: Props) => (
    <div
        className={classNames('quoted-message', {
            ['quoted-message--right']: message.fromMe
        })}
    >
        <span
            className={classNames('quoted-message__side', {
                ['quoted-message__side--right']: message.quotedMsg?.fromMe
            })}
        ></span>
        <div className="quoted-message__content">
            {!message.quotedMsg?.fromMe && (
                <span className="quoted-message__contact-name">
                    {message.quotedMsg?.contact?.name}
                </span>
            )}
            {message.quotedMsg?.body}
        </div>
    </div>
);

export default QuotedMessage;
