import { useEffect, useState } from 'react';
import { Contact } from 'types';
import { getContact } from 'api/contactsApi';
import ManageContactModal from '../ManageContactModal';
import { Modal, ModalBody, Spinner } from '@7shifts/sous-chef';
import { __ } from 'i18n';

type Props = {
    onClose: () => void;
    editingContactId: number;
};

const EditContactModal = ({ onClose, editingContactId }: Props) => {
    const [contact, setContact] = useState<Contact>();

    useEffect(() => {
        getContact(editingContactId).then(setContact);
    }, [editingContactId]);

    if (!contact) {
        return (
            <Modal header={__('contactsPage.editContact')} onClose={onClose}>
                <ModalBody>
                    <Spinner block />
                </ModalBody>
            </Modal>
        );
    }

    return <ManageContactModal onClose={onClose} editingContact={contact} />;
};

export default EditContactModal;
