import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import i18nPT from '@emoji-mart/data/i18n/pt.json';
import i18nEN from '@emoji-mart/data/i18n/pt.json';
import i18n from 'i18n/i18n';
import './EmojiPicker.scss';

type Props = {
    onEmojiSelect: (emoji: string) => void;
};

const EmojiPicker = ({ onEmojiSelect }: Props) => {
    return (
        <div className="emoji-picker">
            <Picker
                data={data}
                onEmojiSelect={(emoji: { native: string }) =>
                    onEmojiSelect(emoji.native)
                }
                perLine={16}
                showPreview={false}
                showSkinTones={false}
                i18n={i18n.language === 'pt' ? i18nPT : i18nEN}
            />
        </div>
    );
};

export default EmojiPicker;
