import { Inline, Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import MyAccountForm from '../components/MyAccountForm';
import LoginAndSecurityCard from '../components/LoginAndSecurityCard';

const MyAccountPage = () => {
    return (
        <Page title={__('main.myAccount')}>
            <Inline flex={['0 0 500px', 1]} space={60}>
                <MyAccountForm />
                <LoginAndSecurityCard />
            </Inline>
        </Page>
    );
};

export default MyAccountPage;
