import { DataTable, DataTableColumn } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { useSearchParams } from 'react-router-dom';
import { useRefetchList } from 'hooks/useRefetchList';
import { GetSchedulesResponse, getSchedules } from 'api/schedulesApi';
import emptyStateIllustration from 'assets/empty-state.svg';

const SchedulesTable = () => {
    const [schedules, setSchedules] = useState<GetSchedulesResponse>();
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useSearchParams();
    const search = params.get('search') || '';
    const page = +(params.get('page') || 1);

    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        getSchedules(search, page)
            .then((data) => setSchedules(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setSchedules(undefined);
            })
            .finally(() => setIsLoading(false));
    }, [search, page, refreshToggle]);

    const COLUMNS: DataTableColumn[] = [
        {
            name: 'contact',
            label: __('contactsPage.contact')
        },
        {
            name: 'message',
            label: __('schedulesPage.message'),
            size: 1.5
        },
        {
            name: 'sendAt',
            label: __('schedulesPage.sendAt')
        },
        {
            name: 'status',
            label: __('schedulesPage.status'),
            size: 0.7
        }
    ];

    return (
        <DataTable
            items={schedules?.schedules || []}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            hasNext={schedules?.hasMore}
            hasPrevious={page > 1}
            onNextClick={() => {
                params.set('page', String(page + 1));
                setParams(params);
            }}
            onPreviousClick={() => {
                const previousPage = page - 1;
                if (previousPage <= 1) {
                    params.delete('page');
                } else {
                    params.set('page', String(page - 1));
                }
                setParams(params);
            }}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    domainName: __(
                        'schedulesPage.scheduledMessage'
                    ).toLocaleLowerCase()
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default SchedulesTable;
