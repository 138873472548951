import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    PasswordField,
    Stack,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { handleError } from 'api/apiUtils';
import { User } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useFormik } from 'formik';
import { getSchema } from './schema';
import { editUser } from 'api/usersApi';

type Props = {
    onClose: () => void;
    editingUser: User;
};

const ChangeUserPasswordModal = ({ onClose, editingUser }: Props) => {
    const { user } = useAuthContext();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);

            const userData = {
                companyId: user.company.id,
                name: editingUser.name,
                profile: editingUser.profile,
                email: editingUser.email,
                isActive: editingUser.isActive,
                isAttendant: editingUser.isAttendant,
                onVacation: editingUser.onVacation,
                password: values.password,
                queueIds: editingUser.queues.map((queue) => queue.id)
            };

            editUser(editingUser.id, userData)
                .then(() => {
                    toast(__('usersPage.passwordChanged'));
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={__('usersPage.changePassword')}
            onClose={onClose}
            width={400}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <PasswordField
                            name="password"
                            label={__('loginPage.password')}
                            placeholder={__('loginPage.typePassword')}
                        />
                        <PasswordField
                            name="confirmPassword"
                            label={__('usersPage.confirmPassword')}
                            placeholder={__('usersPage.typeConfirmPassword')}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {__('usersPage.changePassword')}
                            </Button>
                        ),
                        secondary: (
                            <Button onClick={onClose}>
                                {__('main.cancel')}
                            </Button>
                        )
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ChangeUserPasswordModal;
