/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck Ignore this file until we get back to it
/**
 * ALEX NOTE: This file needs to be properly converted to TypeScript.
 * It was just copied from the legacy codebase.
 */

import {
    WheelEventHandler,
    useEffect,
    useReducer,
    useRef,
    useState
} from 'react';
import api from '../services/api';
import { handleError } from '../api/apiUtils';
import { AxiosError } from 'axios';
import { socketConnection } from '../services/socket';
import { Message, Ticket } from 'types';

const reducer = (state, action) => {
    if (action.type === 'LOAD_MESSAGES') {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...newMessages, ...state];
    } else if (action.type === 'ADD_MESSAGE') {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    } else if (action.type === 'ACK_MESSAGE') {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex(
            (m) => m.id === messageToUpdate.id
        );

        if (messageIndex !== -1) {
            state[messageIndex].ack = messageToUpdate.ack;
            state[messageIndex].isDeleted = messageToUpdate.isDeleted;
        }

        return [...state];
    } else if (action.type === 'UPDATE_MESSAGE') {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex(
            (m) => m.id === messageToUpdate.id
        );

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    } else if (action.type === 'RESET') {
        return [];
    } else if (action.type === 'RESET_UNREAD_MESSAGE') {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(
            (t: { id: number }) => t.id === ticketId
        );
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }
};

type UseMessagesResponse = {
    messagesList: Message[];
    loading: boolean;
    onScroll: WheelEventHandler;
};

export const useMessages = (
    ticketId: number,
    ticket: Ticket
): UseMessagesResponse => {
    const [messagesList, dispatch] = useReducer(reducer, []);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const currentTicketId = useRef(ticketId);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);

        currentTicketId.current = ticketId;
    }, [ticketId]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                if (ticketId === undefined) return;
                try {
                    const { data } = await api.get('/messages/' + ticketId, {
                        params: { pageNumber }
                    });

                    if (currentTicketId.current === ticketId) {
                        dispatch({
                            type: 'LOAD_MESSAGES',
                            payload: data.messages
                        });
                        setHasMore(data.hasMore);
                        setLoading(false);
                    }

                    if (pageNumber === 1 && data.messages.length > 1) {
                        scrollToBottom();
                    }
                } catch (err) {
                    setLoading(false);
                    handleError(err as AxiosError);
                }
            };
            fetchMessages();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [pageNumber, ticketId]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');
        const socket = socketConnection({ companyId });

        socket.on('connect', () => socket.emit('joinChatBox', `${ticket.id}`));

        socket.on(`company-${companyId}-appMessage`, (data) => {
            if (data.action === 'create') {
                dispatch({ type: 'ADD_MESSAGE', payload: data.message });
                scrollToBottom();
            } else if (data.action === 'ack') {
                dispatch({ type: 'ACK_MESSAGE', payload: data.message });
            } else if (data.action === 'update') {
                dispatch({ type: 'UPDATE_MESSAGE', payload: data.message });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [ticketId, ticket]);

    const loadMore = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            const chatContent = document.getElementById(
                'whats-app-chat-content'
            );
            if (chatContent) {
                chatContent.scrollTop = chatContent.scrollHeight;
            }
        }, 100);
    };

    const handleScroll: WheelEventHandler = () => {
        if (!hasMore) return;
        const chatContent = document.getElementById('whats-app-chat-content');
        if (!chatContent) {
            return;
        }
        const scrollTop = chatContent.scrollTop;

        if (scrollTop === 0) {
            const messageListDomElement =
                document.getElementById('messagesList');
            if (messageListDomElement) {
                messageListDomElement.scrollTop = 1;
            }
        }

        if (loading) {
            return;
        }

        if (scrollTop < 50) {
            loadMore();
        }
    };

    return { messagesList, loading, onScroll: handleScroll };
};
