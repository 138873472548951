import { Page, Spinner } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import SettingsForm from '../components/SettingsForm/SettingsForm';
import { useSettings } from 'hooks/useSettings';

const SettingsPage = () => {
    const { isLoadingSettings, settings } = useSettings();

    return (
        <Page title={__('main.settings')}>
            {!isLoadingSettings && <SettingsForm settings={settings} />}
            {isLoadingSettings && <Spinner block />}
        </Page>
    );
};

export default SettingsPage;
