import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import QuickMessagesTable from '../components/QuickMessagesTable';
import QuickMessagesAction from '../components/QuickMessagesAction';

const QuickMessagesPage = () => {
    return (
        <Page
            title={__('main.quickMessages')}
            actions={<QuickMessagesAction />}
        >
            <QuickMessagesTable />
        </Page>
    );
};

export default QuickMessagesPage;
