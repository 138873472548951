import {
    Button,
    Form,
    FormRow,
    FormSection,
    IconPlus,
    IconTrash,
    Inline,
    Modal,
    ModalBody,
    ModalFooter,
    PhoneField,
    SelectField,
    SelectOption,
    Stack,
    TextField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { handleError } from 'api/apiUtils';
import { Contact, TemplateExtraInfo } from 'types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ArrayHelpers, FieldArray, FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import { useRefetchList } from 'hooks/useRefetchList';
import { addContact, editContact } from 'api/contactsApi';
import UserSelectField from 'sharedComponents/UserSelectField';
import { SEGMENTS } from 'modules/Contacts/components/ManageContactModal/constants';

type Props = {
    onClose: () => void;
    editingContact?: Contact;
    defaultTemplates?: TemplateExtraInfo[];
};

const ManageContactModal = ({
    onClose,
    editingContact,
    defaultTemplates
}: Props) => {
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();

    const DEFAULT_EXTRA_INFO = {
        name: '',
        value: ''
    };

    const ADD_TEMPLATES = defaultTemplates
        ? defaultTemplates.map((template) => ({
              name: template.name,
              value: template.value
          }))
        : [DEFAULT_EXTRA_INFO];

    const getInitialValues = () => {
        if (editingContact) {
            const extraInfo = (editingContact.extraInfo || []).map((info) => ({
                name: info.name,
                value: info.value
            }));
            return {
                name: editingContact.name,
                number: '+' + editingContact.number,
                email: editingContact.email,
                segment: editingContact.segment
                    ? {
                          value: editingContact.segment,
                          label: editingContact.segment
                      }
                    : null,
                prefferedAttendant: null,
                extraInfo:
                    extraInfo.length > 0 ? extraInfo : [DEFAULT_EXTRA_INFO]
            };
        }
        return {
            name: '',
            number: '',
            email: '',
            segment: null,
            prefferedAttendant: null as SelectOption<number> | null,
            extraInfo: ADD_TEMPLATES
        };
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);

            const contactData = {
                name: values.name,
                number: values.number.replace('+', ''),
                email: values.email,
                attendantId: values.prefferedAttendant?.value || null,
                segment: values.segment ? values.segment.value : '',
                extraInfo: values.extraInfo
            };

            const request = editingContact
                ? editContact(editingContact.id, {
                      companyId: user.company.id,
                      ...contactData
                  })
                : addContact(contactData);

            request
                .then(() => {
                    toast(
                        editingContact
                            ? __('usersPage.userEdited')
                            : __('usersPage.userAdded')
                    );
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingContact
                    ? __('contactsPage.editContact')
                    : __('contactsPage.addContact')
            }
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form stackContent={false} formik={formik}>
                    <ModalBody>
                        <Stack>
                            <FormSection>
                                <FormRow>
                                    <TextField
                                        name="name"
                                        label={__('usersPage.name')}
                                        placeholder={__('usersPage.typeName')}
                                    />
                                    <PhoneField
                                        name="number"
                                        label={__('contactsPage.whatsApp')}
                                    />
                                </FormRow>
                                <TextField
                                    name="email"
                                    label={__('loginPage.email')}
                                    placeholder={__('loginPage.typeEmail')}
                                />
                                <UserSelectField
                                    name="prefferedAttendant"
                                    label={__(
                                        'contactsPage.prefferedAttendantLabel'
                                    )}
                                    initialuserId={
                                        editingContact?.attendantId || null
                                    }
                                    onInitialOptionLoaded={(option) =>
                                        formik.setFieldValue(
                                            'prefferedAttendant',
                                            option
                                        )
                                    }
                                />
                                <SelectField
                                    name="segment"
                                    label={__('contactsPage.segment')}
                                    options={SEGMENTS.map((segment) => ({
                                        label: segment,
                                        value: segment
                                    }))}
                                    placeholder={__(
                                        'component.select.placeholder'
                                    )}
                                    isClearable
                                />
                            </FormSection>
                            <FormSection title={__('contactsPage.extraInfo')}>
                                <FieldArray
                                    name="extraInfo"
                                    render={({
                                        push,
                                        remove
                                    }: ArrayHelpers) => (
                                        <Stack>
                                            {formik.values.extraInfo.map(
                                                (_: unknown, index: number) => (
                                                    <Inline
                                                        key={index}
                                                        flex={[1, 1]}
                                                    >
                                                        <TextField
                                                            name={`extraInfo[${index}].name`}
                                                            label={
                                                                index === 0 &&
                                                                __(
                                                                    'contactsPage.extraFieldName'
                                                                )
                                                            }
                                                        />
                                                        <TextField
                                                            name={`extraInfo[${index}].value`}
                                                            label={
                                                                index === 0 &&
                                                                __(
                                                                    'contactsPage.extraFieldValue'
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            theme="link-icon"
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                            mt={
                                                                index === 0
                                                                    ? 24
                                                                    : 0
                                                            }
                                                        >
                                                            <IconTrash />
                                                        </Button>
                                                    </Inline>
                                                )
                                            )}
                                            <Button
                                                onClick={() =>
                                                    push({
                                                        name: '',
                                                        value: ''
                                                    })
                                                }
                                                theme="link-primary"
                                            >
                                                <IconPlus size="medium" />
                                                {__(
                                                    'contactsPage.addExtraInfo'
                                                )}
                                            </Button>
                                        </Stack>
                                    )}
                                />
                            </FormSection>
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingContact
                                        ? __('contactsPage.editContact')
                                        : __('contactsPage.addContact')}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageContactModal;
