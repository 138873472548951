import { useCallback, useEffect, useState } from 'react';
import WhatsAppSidePane from '../WhatsAppSidePane';
import { addNote, listNotes } from 'api/notesApi';
import { Note, Ticket } from 'types';
import { handleError } from 'api/apiUtils';
import {
    Button,
    EmptyState,
    IconPlus,
    Inline,
    Spinner,
    TextAreaField
} from '@7shifts/sous-chef';
import WhatsAppNote from '../WhatsAppNote';
import './WhatsAppNotesPane.scss';
import { __ } from 'i18n';
import notesEmptyStateIllustration from 'assets/notes-empty-state.svg';

type Props = {
    onClose: () => void;
    ticket: Ticket;
    onNotesChanged: () => void;
};

const WhatsAppNotesPane = ({ onClose, ticket, onNotesChanged }: Props) => {
    const [notes, setNotes] = useState<Note[]>([]);
    const [newNote, setNewNote] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const loadNotes = useCallback(() => {
        listNotes({
            contactId: ticket.contactId,
            ticketId: ticket.id
        })
            .then((data) => setNotes(data))
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, [ticket.contactId, ticket.id]);

    useEffect(() => {
        loadNotes();
    }, [loadNotes]);

    const handleAddNote = () => {
        if (newNote.trim() === '') {
            return;
        }
        addNote({
            contactId: ticket.contactId,
            ticketId: ticket.id,
            note: newNote
        })
            .then(() => {
                loadNotes();
                setNewNote('');
                onNotesChanged();
            })
            .catch(handleError);
    };

    return (
        <WhatsAppSidePane title={__('tickets.notes')} onClose={onClose}>
            <div className="whats-app-notes-pane__new-note">
                <TextAreaField
                    name="note"
                    placeholder={__('tickets.newNotePlaceholder')}
                    value={newNote}
                    maxLength={1024}
                    onChange={setNewNote}
                    toolbar={
                        <Inline justifyContent="end">
                            <Button theme="primary" onClick={handleAddNote}>
                                <IconPlus size="medium" />
                                {__('tickets.addNote')}
                            </Button>
                        </Inline>
                    }
                    autoGrow
                />
            </div>
            {isLoading && <Spinner block />}
            <div className="whats-app-notes-pane__notes">
                {notes.length === 0 && (
                    <EmptyState
                        mediaUrl={notesEmptyStateIllustration}
                        size="small"
                        title={__('tickets.notesEmptyStateTitle')}
                    >
                        {__('tickets.notesEmptyStateDescription')}
                    </EmptyState>
                )}
                {notes.map((note) => (
                    <WhatsAppNote
                        note={note}
                        key={note.id}
                        onNoteDeleted={() => {
                            loadNotes();
                            onNotesChanged();
                        }}
                    />
                ))}
            </div>
        </WhatsAppSidePane>
    );
};

export default WhatsAppNotesPane;
