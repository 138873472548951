import SignupForm from '../components/SignupForm';
import LogoFull from '../components/LogoFull';
import { LoginLayoutFrame } from 'app-layout-frame';
import LoginMarketingPane from '../components/LoginMarketingPane';

const SignupPage = () => {
    return (
        <LoginLayoutFrame
            logo={<LogoFull />}
            marketingPane={<LoginMarketingPane />}
        >
            <SignupForm />
        </LoginLayoutFrame>
    );
};
export default SignupPage;
