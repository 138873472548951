import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import SchedulesPage from './pages/SchedulesPage';

export const ROUTES = [
    {
        path: '/schedules',
        element: <SchedulesPage />,
        errorElement: <ErrorBoundary />
    }
];
