import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        IdleTimeInMinutes: Yup.number().required(
            __('main.required', {
                fieldName: __('settingsPage.idleTime')
            })
        ),
        IdleMaxTries: Yup.number().required(
            __('main.required', {
                fieldName: __('settingsPage.idleMaxTries')
            })
        ),
        IdleTransferQueue: Yup.object().required(
            __('main.required', {
                fieldName: __('settingsPage.idleTransferQueue')
            })
        ),
        IdleMessage: Yup.string().required(
            __('main.required', {
                fieldName: __('settingsPage.idleMessage')
            })
        )
    });
};
