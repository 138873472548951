import { useState } from 'react';
import { Setting } from 'types';
import {
    Button,
    Card,
    Form,
    FormSection,
    IconClock,
    IconCog,
    Inline,
    InlineBanner,
    RadioGroupField,
    RadioGroupOption,
    Stack,
    Toggle
} from '@7shifts/sous-chef';
import { updateSetting } from 'api/settingsApi';
import { handleError } from 'api/apiUtils';
import IgnoreGroupMessagesTooltipInfo from '../IgnoreGroupMessagesTooltipInfo';
import AcceptCallTooltipInfo from '../AcceptCallTooltipInfo';
import { __ } from 'i18n';
import ConfigureWorkingHoursModal from '../ConfigureWorkingHoursModal';
import FlowSettingsModal from '../FlowSettingsModal';
import { AxiosError } from 'axios';

type Props = {
    settings: Setting[];
};

type HandleUpdateParams = {
    newValue: string;
    prevValue: string;
    setState: (value: string) => void;
    settingName: string;
};

const SettingsForm = ({ settings }: Props) => {
    const [isConfiguringFlowSettings, setIsConfiguringFlowSettings] =
        useState(false);
    const [isConfiguringWorkingHours, setIsConfiguringWorkingHours] =
        useState(false);
    const findSetting = (key: string, defaultValue: string) => {
        const setting = settings.find((s) => s.key === key);
        return setting ? setting.value : defaultValue;
    };

    const [scheduleType, setScheduleType] = useState(() =>
        findSetting('scheduleType', 'disabled')
    );
    const [callType, setCallType] = useState(() =>
        findSetting('call', 'enabled')
    );
    const [checkMsgIsGroup, setCheckMsgIsGroupType] = useState(() =>
        findSetting('CheckMsgIsGroup', 'enabled')
    );
    const [distribuitionMethod, setDistribuitionMethod] = useState(() =>
        findSetting('distribuitionMethod', 'manual')
    );
    const [isSignatureEnabled, setIsSignatureEnabled] = useState(() =>
        findSetting('isSignatureEnabled', 'disabled')
    );

    const handleUpdate = async (params: HandleUpdateParams) => {
        const { newValue, prevValue, setState, settingName } = params;

        setState(newValue);
        try {
            updateSetting({ key: settingName, value: newValue });
        } catch (error) {
            handleError(error as AxiosError);
            setState(prevValue);
        }
    };

    return (
        <>
            <Card>
                <Form>
                    <Toggle
                        checked={scheduleType !== 'disabled'}
                        label={__('settingsPage.workingHours')}
                        caption={__('settingsPage.workingHoursCaption')}
                        onChange={() => {
                            handleUpdate({
                                newValue:
                                    scheduleType !== 'disabled'
                                        ? 'disabled'
                                        : 'company',
                                prevValue: scheduleType,
                                setState: setScheduleType,
                                settingName: 'scheduleType'
                            });
                        }}
                    />
                    {scheduleType !== 'disabled' && (
                        <Stack ml={50}>
                            <RadioGroupField
                                name="schedule-type"
                                value={scheduleType}
                                onChange={(option) => {
                                    handleUpdate({
                                        newValue: option as string,
                                        prevValue: scheduleType,
                                        setState: setScheduleType,
                                        settingName: 'scheduleType'
                                    });
                                }}
                            >
                                <RadioGroupOption
                                    value="queue"
                                    label={__(
                                        'settingsPage.workingHoursByQueue'
                                    )}
                                    caption={__(
                                        'settingsPage.workingHoursByQueueCaption'
                                    )}
                                />
                                <RadioGroupOption
                                    value="company"
                                    label={__(
                                        'settingsPage.workingHoursByCompany'
                                    )}
                                    caption={__(
                                        'settingsPage.workingHoursByCompanyCaption'
                                    )}
                                />
                            </RadioGroupField>
                            {scheduleType === 'company' && (
                                <Button
                                    onClick={() =>
                                        setIsConfiguringWorkingHours(true)
                                    }
                                >
                                    <IconClock size="medium" />
                                    {__('settingsPage.configureWorkingHours')}
                                </Button>
                            )}
                        </Stack>
                    )}
                    <Toggle
                        checked={checkMsgIsGroup === 'enabled'}
                        label={
                            <Inline alignItems="center" space={8}>
                                <>{__('settingsPage.ignoreGroupMessages')}</>
                                <IgnoreGroupMessagesTooltipInfo />
                            </Inline>
                        }
                        caption={__('settingsPage.ignoreGroupMessagesCaption')}
                        onChange={() => {
                            handleUpdate({
                                newValue:
                                    checkMsgIsGroup === 'enabled'
                                        ? 'disabled'
                                        : 'enabled',
                                prevValue: checkMsgIsGroup,
                                setState: setCheckMsgIsGroupType,
                                settingName: 'CheckMsgIsGroup'
                            });
                        }}
                    />
                    <Toggle
                        checked={callType === 'enabled'}
                        label={
                            <Inline alignItems="center" space={8}>
                                <>{__('settingsPage.acceptCalls')}</>
                                <AcceptCallTooltipInfo />
                            </Inline>
                        }
                        caption={__('settingsPage.acceptCallsCaption')}
                        onChange={() => {
                            handleUpdate({
                                newValue:
                                    callType === 'enabled'
                                        ? 'disabled'
                                        : 'enabled',
                                prevValue: callType,
                                setState: setCallType,
                                settingName: 'call'
                            });
                        }}
                    />
                    <Toggle
                        checked={isSignatureEnabled === 'enabled'}
                        label={
                            <Inline alignItems="center" space={8}>
                                <>{__('settingsPage.enableSignature')}</>
                            </Inline>
                        }
                        caption={__('settingsPage.enableSignatureCaption')}
                        onChange={() => {
                            handleUpdate({
                                newValue:
                                    isSignatureEnabled === 'enabled'
                                        ? 'disabled'
                                        : 'enabled',
                                prevValue: isSignatureEnabled,
                                setState: setIsSignatureEnabled,
                                settingName: 'isSignatureEnabled'
                            });
                        }}
                    />
                    <RadioGroupField
                        name="distribuitionMethod"
                        label={__('settingsPage.distributionMethod')}
                        value={distribuitionMethod}
                        onChange={(option) => {
                            handleUpdate({
                                newValue: option as string,
                                prevValue: distribuitionMethod,
                                setState: setDistribuitionMethod,
                                settingName: 'distribuitionMethod'
                            });
                        }}
                    >
                        <RadioGroupOption
                            value="manual"
                            label={__('settingsPage.manualDistributionMethod')}
                            caption={__(
                                'settingsPage.manualDistributionMethodCaption'
                            )}
                        />
                        <RadioGroupOption
                            value="rotactive"
                            label={__(
                                'settingsPage.rotactiveDistributionMethod'
                            )}
                            caption={__(
                                'settingsPage.rotactiveDistributionMethodCaption'
                            )}
                        />
                    </RadioGroupField>
                    <FormSection title="Fluxo">
                        <InlineBanner
                            title={__('settingsPage.flowSettings')}
                            caption={__('settingsPage.flowSettingsCaption')}
                            primaryButton={
                                <Button
                                    onClick={() =>
                                        setIsConfiguringFlowSettings(true)
                                    }
                                >
                                    <IconCog size="medium" />
                                    {__('settingsPage.configureflowSettings')}
                                </Button>
                            }
                        >
                            {__('settingsPage.flowSettingsDescrition')}
                        </InlineBanner>
                    </FormSection>
                </Form>
            </Card>
            {isConfiguringWorkingHours && (
                <ConfigureWorkingHoursModal
                    onClose={() => setIsConfiguringWorkingHours(false)}
                />
            )}
            {isConfiguringFlowSettings && (
                <FlowSettingsModal
                    onClose={() => setIsConfiguringFlowSettings(false)}
                />
            )}
        </>
    );
};

export default SettingsForm;
