import api from '../services/api';
import { Message } from 'types';

export const sendMessage = (params: {
    userName: string;
    message: string;
    ticketId: number;
    signMessage: boolean;
    replyingMessage: Message | null;
}) => {
    const data = {
        read: 1,
        fromMe: true,
        mediaUrl: '',
        body: params.signMessage
            ? `*${params.userName}:*\n${params.message.trim()}`
            : params.message.trim(),
        quotedMsg: params.replyingMessage
    };
    return api.post(`/messages/${params.ticketId}`, data);
};

export const deleteMessage = (messageId: string) => {
    return api.delete(`/messages/${messageId}`);
};
