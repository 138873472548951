import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import SearchFilter from 'sharedComponents/SearchFilter';
import SchedulesTable from '../components/SchedulesTable';
import SchedulePageActions from '../components/SchedulePageActions';

const SchedulesPage = () => {
    return (
        <Page
            title={__('main.schedules')}
            actions={<SchedulePageActions />}
            filterBar={<SearchFilter />}
        >
            <SchedulesTable />
        </Page>
    );
};

export default SchedulesPage;
