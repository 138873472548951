import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import MessageTemplatesNewPage from './pages/MessageTemplatesNewPage';
import MessageTemplatesPage from './pages/MessageTemplatesPage';

export const ROUTES = [
    {
        path: '/message-templates',
        element: <MessageTemplatesPage />,
        errorElement: <ErrorBoundary />
    },
    {
        path: '/message-templates/new',
        element: <MessageTemplatesNewPage />,
        errorElement: <ErrorBoundary />
    }
];
