import {
    Avatar,
    Chip,
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Inline
} from '@7shifts/sous-chef';
import { Contact } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteContactModal from '../../DeleteContactModal';
import EditContactModal from '../../EditContactModal';
import CreateTicketModal from 'modules/Tickets/components/CreateTicketModal';

type Props = DataTableCustomComponent<Contact>;

const TableRow = ({ item }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCreatingTicket, setIsCreatingTicket] = useState(false);
    const actions: DataTableAction[] = [
        {
            action: 'createTicket',
            label: __('contactsPage.createTicket'),
            onAction: () => setIsCreatingTicket(true)
        },
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeleting(true)
        }
    ];
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>
                    <Inline space={12} alignItems="center">
                        <Avatar url={item.profilePicUrl} />
                        <>{item.name}</>
                    </Inline>
                </DataTableCell>
                <DataTableCell columnIndex={2}>{item.number}</DataTableCell>
                <DataTableCell columnIndex={1}>{item.email}</DataTableCell>
                <DataTableCell columnIndex={1}>
                    {item.attendantId ? (
                        <Chip theme="info">{__('main.yes')}</Chip>
                    ) : (
                        <Chip theme="warning">{__('main.no')}</Chip>
                    )}
                </DataTableCell>
            </DataTableRow>

            {isEditing && (
                <EditContactModal
                    editingContactId={item.id}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isDeleting && (
                <DeleteContactModal
                    deletingContact={item}
                    onClose={() => setIsDeleting(false)}
                />
            )}
            {isCreatingTicket && (
                <CreateTicketModal
                    initialContact={item}
                    onClose={() => setIsCreatingTicket(false)}
                />
            )}
        </>
    );
};

export default TableRow;
