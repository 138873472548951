import { Button, IconTrash, Inline, Stack, Text } from '@7shifts/sous-chef';
import { Note } from 'types';
import './WhatsAppNote.scss';
import { formatRelative } from 'date-fns';
import i18n from 'i18n/i18n';
import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt-BR';
import { deleteNote } from 'api/notesApi';
import { handleError } from 'api/apiUtils';
import { useState } from 'react';

type Props = {
    note: Note;
    onNoteDeleted: () => void;
};

const WhatsAppNote = ({ note, onNoteDeleted }: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteNote = () => {
        setIsDeleting(true);
        deleteNote(note.id)
            .then(() => onNoteDeleted())
            .catch(handleError)
            .finally(() => setIsDeleting(false));
    };
    return (
        <div className="whats-app-note">
            <div className="whats-app-note__delete">
                <Button
                    theme="link-icon"
                    onClick={handleDeleteNote}
                    loading={isDeleting}
                >
                    <IconTrash />
                </Button>
            </div>
            <Stack>
                <Inline space={4} alignItems="flex-end">
                    <Text emphasis="bold">{note.user.name} - </Text>
                    <Text as="caption" color="grey-300">
                        {formatRelative(new Date(note.createdAt), new Date(), {
                            locale: i18n.language === 'en' ? enLocale : ptLocale
                        })}
                    </Text>
                </Inline>
                <div className="whats-app-note__content">{note.note}</div>
            </Stack>
        </div>
    );
};

export default WhatsAppNote;
