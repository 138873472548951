import { Button, IconUserTag } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageTagModal from '../ManageTagModal';
import { useAuthContext } from 'contexts/AuthContext';

const TagsPageAction = () => {
    const [showModal, setShowModal] = useState(false);

    const { user } = useAuthContext();

    if (user.profile === 'user') {
        return null;
    }

    return (
        <>
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconUserTag size="medium" />
                {__('tagsPage.addTag')}
            </Button>
            {showModal && (
                <ManageTagModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default TagsPageAction;
