import { Button, IconMessages } from '@7shifts/sous-chef';
import { handleError } from 'api/apiUtils';
import { reopenTicket } from 'api/ticketsApi';
import { useAuthContext } from 'contexts/AuthContext';
import { __ } from 'i18n/translate';
import ReopenTicketModal from 'modules/Tickets/components/ReopenTicketModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Ticket } from 'types';

type Props = {
    ticket: Ticket;
};
const ReopenTicketTextButton = ({ ticket }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReopening, setIsReopening] = useState(false);
    const navigate = useNavigate();

    const { user } = useAuthContext();

    const handleReopenTicket = () => {
        setIsReopening(true);
        reopenTicket(ticket.id, user.queues[0].id)
            .then(() => {
                navigate('/tickets?view=working&ticket=' + ticket.uuid);
            })
            .catch(handleError)
            .finally(() => {
                setIsReopening(false);
            });
    };

    return (
        <>
            <Button
                theme="primary"
                onClick={() => {
                    if (user.queues.length === 1) {
                        handleReopenTicket();
                    } else {
                        setIsModalOpen(true);
                    }
                }}
                loading={isReopening}
            >
                <IconMessages size="medium" />
                {__('tickets.startChating')}
            </Button>
            {isModalOpen && (
                <ReopenTicketModal
                    onClose={() => setIsModalOpen(false)}
                    ticket={ticket}
                />
            )}
        </>
    );
};

export default ReopenTicketTextButton;
