export const COLORS: string[] = [
    'e25241',
    '9035aa',
    '4253af',
    '4896ec',
    '429488',
    '67AC5C',
    '97C15C',
    'D0DB59',
    'F6C344',
    'F19C38',
    'ED6337',
    '74574A',
    '9E9E9E',
    '667D8A',
    '000000'
];
