import {
    Chip,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow
} from '@7shifts/sous-chef';
import { User } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageUserModal from '../../ManageUserModal';
import ChangeUserPasswordModal from '../../ChangeUserPasswordModal';
import DeleteUserModal from '../../DeleteUserModal';

type Props = DataTableCustomComponent<User>;

const TableRow = ({ item }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const actions = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'changePassword',
            label: __('usersPage.changePassword'),
            onAction: () => setIsChangingPassword(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeletingUser(true)
        }
    ];
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>{item.name}</DataTableCell>
                <DataTableCell columnIndex={1}>{item.email}</DataTableCell>
                <DataTableCell columnIndex={2}>
                    {__(`usersPage.${item.profile}`)}
                </DataTableCell>
                <DataTableCell columnIndex={3}>
                    {item.isAttendant ? (
                        <Chip theme="info">{__('main.yes')}</Chip>
                    ) : (
                        <Chip theme="warning">{__('main.no')}</Chip>
                    )}
                </DataTableCell>
                <DataTableCell columnIndex={4}>
                    {item.onVacation ? (
                        <Chip theme="warning">{__('main.yes')}</Chip>
                    ) : (
                        <Chip theme="info">{__('main.no')}</Chip>
                    )}
                </DataTableCell>
                <DataTableCell columnIndex={5}>
                    {item.isActive ? (
                        <Chip theme="info">{__('main.yes')}</Chip>
                    ) : (
                        <Chip theme="warning">{__('main.no')}</Chip>
                    )}
                </DataTableCell>
            </DataTableRow>
            {isEditing && (
                <ManageUserModal
                    editingUser={item}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isChangingPassword && (
                <ChangeUserPasswordModal
                    editingUser={item}
                    onClose={() => setIsChangingPassword(false)}
                />
            )}
            {isDeletingUser && (
                <DeleteUserModal
                    deletingUser={item}
                    onClose={() => setIsDeletingUser(false)}
                />
            )}
        </>
    );
};

export default TableRow;
