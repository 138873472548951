import { Modal, ModalBody } from '@7shifts/sous-chef';
import QRCode from 'qrcode.react';
import { WhatsApp } from 'types';
import { useEffect } from 'react';
import { __ } from 'i18n';

type Props = {
    whatsApp: WhatsApp;
    onClose: () => void;
};

const QRCodeModal = ({ whatsApp, onClose }: Props) => {
    useEffect(() => {
        if (whatsApp.status !== 'qrcode') {
            onClose();
        }
    }, [whatsApp.status]);

    if (!whatsApp.qrcode) {
        return null;
    }
    return (
        <Modal
            header={__('channelsPage.readQRCode')}
            onClose={onClose}
            width={425}
        >
            <ModalBody>
                <QRCode value={whatsApp.qrcode} size={356} />
            </ModalBody>
        </Modal>
    );
};

export default QRCodeModal;
