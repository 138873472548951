import { TextField } from '@7shifts/sous-chef';
import { useFieldMask } from 'hooks/useFieldMask';
import { useRef } from 'react';

type Props = {
    onChange: (value: string) => void;
    mask: string;
    name: string;
    label: string;
    value: string;
};

const MaskedTextField = ({ onChange, mask, name, label, value }: Props) => {
    const maskfy = useFieldMask(mask);
    const ref = useRef(null);
    return (
        <TextField
            name={name}
            label={label}
            value={value}
            ref={ref}
            placeholder={mask}
            onChange={() => {
                if (!ref.current) {
                    return;
                }
                const value = maskfy(ref.current);
                onChange(value);
            }}
        />
    );
};

export default MaskedTextField;
