import api from '../services/api';
import { Company, QueueSchedule } from 'types';

export const getCompany = (id: number): Promise<Company> => {
    return api.get('/companies/' + id).then(({ data }) => data);
};

export const editCompanySchedules = (
    id: number,
    schedules: QueueSchedule[]
): Promise<Company> => {
    return api
        .put(`/companies/${id}/schedules`, { id, schedules })
        .then(({ data }) => data);
};
