import api from '../services/api';
import { QueueOption } from 'types';

export const getQueueOptions = (queueId: number): Promise<QueueOption[]> => {
    return api
        .get<QueueOption[]>(`/queue-options?queueId=${queueId}&parentId=-1`)
        .then(({ data }) => data);
};

type QueueOptionData = {
    title: string;
    message: string;
    edition: boolean;
    option: number;
    queueId: number;
    parentId: number | null;
    children: object[];
};
export const addQueueOption = (
    newQueueOption: QueueOptionData
): Promise<QueueOption> => {
    return api.post('/queue-options', newQueueOption).then(({ data }) => data);
};

export const editQueueOption = (
    queueOptionId: number,
    editQueueOption: QueueOptionData
): Promise<QueueOption> => {
    return api
        .put('/queue-options/' + queueOptionId, editQueueOption)
        .then(({ data }) => data);
};

export const deleteQueueOption = (queueOptionId: number): Promise<void> => {
    return api.delete(`/queue-options/${queueOptionId}`);
};
