import { DataTable } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { GetUsersResponse, getUsers } from 'api/usersApi';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { useSearchParams } from 'react-router-dom';
import { useRefetchList } from 'hooks/useRefetchList';
import emptyStateIllustration from 'assets/empty-state.svg';

const UsersTable = () => {
    const [users, setUsers] = useState<GetUsersResponse>();
    const [isLoading, setIsLoading] = useState(true);

    const [params, setParams] = useSearchParams();
    const search = params.get('search') || '';
    const page = +(params.get('page') || 1);

    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                getUsers(search, page)
                    .then((data) => setUsers(data))
                    .catch((err: AxiosError) => {
                        handleError(err);
                        setUsers(undefined);
                    })
                    .finally(() => setIsLoading(false));
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [search, page, refreshToggle]);

    const COLUMNS = [
        {
            name: 'name',
            label: __('usersPage.name')
        },
        {
            name: 'email',
            label: __('usersPage.email')
        },
        {
            name: 'profile',
            label: __('usersPage.profile')
        },
        {
            name: 'isAttendant',
            label: __('usersPage.isAttendant')
        },
        {
            name: 'onVacation',
            label: __('usersPage.onVacation')
        },
        {
            name: 'isActive',
            label: __('usersPage.isActive')
        }
    ];

    return (
        <DataTable
            items={users?.users || []}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            hasNext={users?.hasMore}
            hasPrevious={page > 1}
            onNextClick={() => {
                params.set('page', String(page + 1));
                setParams(params);
            }}
            onPreviousClick={() => {
                const previousPage = page - 1;
                if (previousPage <= 1) {
                    params.delete('page');
                } else {
                    params.set('page', String(page - 1));
                }
                setParams(params);
            }}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    domainName: __('main.user')
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default UsersTable;
