import { SelectOption } from '@7shifts/sous-chef';
import { useAuthContext } from 'contexts/AuthContext';
import { __ } from 'i18n/translate';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserSelectField from 'sharedComponents/UserSelectField';

const UsersFilter = () => {
    const [params, setParams] = useSearchParams();
    const { user } = useAuthContext();

    const [selectedUser, setSelectedUser] = useState<SelectOption<number>>();

    const isAdmin = user && user.profile === 'admin';
    const selectedUserId = params.get('userId');
    return (
        isAdmin && (
            <div style={{ minWidth: 200 }}>
                <UserSelectField
                    name="user"
                    placeholder={__('dashboardPage.allUsers')}
                    onChange={(userOption) => {
                        if (!userOption) {
                            params.delete('userId');
                        } else {
                            params.set('userId', userOption.value.toString());
                        }
                        setParams(params);
                        setSelectedUser(userOption);
                    }}
                    initialuserId={selectedUserId ? +selectedUserId : undefined}
                    onInitialOptionLoaded={setSelectedUser}
                    value={selectedUser}
                />
            </div>
        )
    );
};

export default UsersFilter;
