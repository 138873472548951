import { Button, Card, Stack, Text } from '@7shifts/sous-chef';
import { useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { __ } from 'i18n';
import ChangeUserPasswordModal from '../../../../modules/Users/components/ChangeUserPasswordModal';

const LoginAndSecurityCard = () => {
    const [showChangePasswordModal, setShowChangePasswordModal] =
        useState(false);
    const { user } = useAuthContext();

    return (
        <>
            <Card>
                <Stack>
                    <Text as="h3">{__('usersPage.loginAndSecurity')}</Text>
                    <Stack space={8}>
                        <Text emphasis="bold">{__('loginPage.password')}</Text>
                        <Text>*********</Text>
                    </Stack>
                    <Button onClick={() => setShowChangePasswordModal(true)}>
                        {__('usersPage.changePassword')}
                    </Button>
                </Stack>
            </Card>
            {showChangePasswordModal && (
                <ChangeUserPasswordModal
                    editingUser={user}
                    onClose={() => setShowChangePasswordModal(false)}
                />
            )}
        </>
    );
};

export default LoginAndSecurityCard;
