import { Badge, Button, IconFlag } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import './WhatsAppTagsButton.scss';

type Props = {
    onTagsClick: () => void;
    amountOfTags: number;
};

const WhatsAppTagsButton = ({ onTagsClick, amountOfTags }: Props) => {
    return (
        <div className="whats-app-tags-button">
            <Button
                theme="link-icon"
                onClick={onTagsClick}
                title={__('tickets.tags')}
            >
                <IconFlag />
            </Button>
            {amountOfTags > 0 && (
                <div className="whats-app-tags-button__badge">
                    <Badge>{amountOfTags}</Badge>
                </div>
            )}
        </div>
    );
};

export default WhatsAppTagsButton;
