import {
    Button,
    Form,
    FormFooter,
    FormSection,
    IconPlus,
    IconTrash,
    Inline,
    Page,
    SelectField,
    Stack,
    TextAreaField,
    TextField,
    toast
} from '@7shifts/sous-chef';

import {
    FieldArray,
    FieldArrayRenderProps,
    FormikProvider,
    useFormik
} from 'formik';

import { __ } from 'i18n/translate';
import TemplatePreview from '../TemplatePreview/TemplatePreview';
import { useNavigate } from 'react-router-dom';
import { getSchema } from './schema';
import { getHeaderTypeOptions, getModelTypeOptions } from './constants';
import { generateMetaPayload } from './domain';
import api from 'services/api';

export default function MessageTemplateForm() {
    const HEADER_TYPE_OPTIONS = getHeaderTypeOptions();
    const MODEL_TYPE_OPTIONS = getModelTypeOptions();
    const navigate = useNavigate();
    const defaultBodyText = __('messageTemplateForm.defaultBodyText');
    const defaultFooterText = __('messageTemplateForm.defaultFooterText');
    const defaultHeaderText = __('messageTemplateForm.defaultHeaderText');
    const allowedImages: string = '.png, .jpg, .jpeg, .webp';
    const allowedVideos: string = '.mp4,.3gp';
    const allowedDocs: string = '.txt,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx';

    const formik = useFormik({
        initialValues: {
            type: MODEL_TYPE_OPTIONS[0],
            headerType: HEADER_TYPE_OPTIONS[0],
            headerContent: defaultHeaderText,
            name: '',
            bodyText: defaultBodyText,
            footerText: defaultFooterText,
            buttons: []
        },
        validationSchema: getSchema(),
        onSubmit: async (values) => {
            console.log('submitting', values);
            try {
                const generatedPayload = await generateMetaPayload(values);
                console.log(generatedPayload);
                const result = await api.post(
                    '/meta/template',
                    generatedPayload,
                    {
                        validateStatus: () => true
                    }
                );
                if (result.status === 200) {
                    toast(__('messageTemplateForm.successMessage'), 'default');
                    setTimeout(() => {
                        navigate('/message-templates');
                    }, 2500);
                } else {
                    toast(
                        __('messageTemplateForm.errorMessage') +
                            '\n' +
                            result.data.error.error_user_msg,
                        'danger'
                    );
                }
            } catch (e) {
                console.error(e);
                toast(e as string, 'danger');
            }
            formik.setSubmitting(false);
        }
    });

    const handleCancel = () => {
        navigate('/message-templates');
    };

    return (
        <Page title={__('messageTemplateForm.titleForm')}>
            <FormikProvider value={formik}>
                <Form formik={formik} wide={true}>
                    <FormSection as="card">
                        <Inline flex={['0 1 80%', '0 1 20%']}>
                            <TextField
                                label={__('messageTemplateForm.titleModelName')}
                                name="name"
                                maxLength={512}
                            />
                            <SelectField
                                label={__('messageTemplateForm.titleModelName')}
                                options={MODEL_TYPE_OPTIONS}
                                name="type"
                            />
                        </Inline>
                    </FormSection>
                    <Inline flex={['0 1 40%', '0 1 60%']}>
                        <Stack>
                            <FormSection
                                title={__(
                                    'messageTemplateForm.headerContentForm'
                                )}
                                as="card"
                            >
                                <Stack>
                                    <SelectField
                                        options={HEADER_TYPE_OPTIONS}
                                        name="headerType"
                                        onChange={(value) => {
                                            if (value.value === 'text') {
                                                formik.setFieldValue(
                                                    'headerContent',
                                                    __(
                                                        'messageTemplateForm.defaultHeaderText'
                                                    )
                                                );
                                                return;
                                            }
                                            formik.setFieldValue(
                                                'headerContent',
                                                ''
                                            );
                                        }}
                                    />
                                    {formik.values.headerType.value ===
                                        'text' && (
                                        <TextAreaField
                                            name="headerContent"
                                            label={__(
                                                'messageTemplateForm.TextTypeOfContentForm'
                                            )}
                                            maxLength={1024}
                                        />
                                    )}
                                    {formik.values.headerType.value !== '' &&
                                        formik.values.headerType.value !==
                                            'text' && (
                                            <>
                                                <input
                                                    required={true}
                                                    type="file"
                                                    name="headerContentFile"
                                                    accept={
                                                        formik.values.headerType
                                                            .value === 'image'
                                                            ? allowedImages
                                                            : formik.values
                                                                  .headerType
                                                                  .value ===
                                                              'video'
                                                            ? allowedVideos
                                                            : allowedDocs
                                                    }
                                                    onChange={(event) => {
                                                        if (
                                                            event &&
                                                            event.target &&
                                                            event.target
                                                                .files &&
                                                            event.target
                                                                .files[0]
                                                        ) {
                                                            formik.setFieldValue(
                                                                'headerContent',
                                                                URL.createObjectURL(
                                                                    event.target
                                                                        .files[0]
                                                                )
                                                            );

                                                            formik.setFieldValue(
                                                                'headerContentFile',
                                                                event.target
                                                                    .files[0]
                                                            );
                                                        }
                                                    }}
                                                    className="filetype"
                                                />
                                            </>
                                        )}
                                </Stack>
                            </FormSection>
                            <FormSection
                                title={__(
                                    'messageTemplateForm.titleBodyContentForm'
                                )}
                                as="card"
                            >
                                <TextAreaField
                                    name="bodyText"
                                    maxLength={1024}
                                    minHeight={100}
                                    maxHeight={200}
                                    autoGrow={true}
                                />
                            </FormSection>
                            <FormSection
                                title={__(
                                    'messageTemplateForm.footerContentForm'
                                )}
                                as="card"
                            >
                                <TextField name="footerText" />
                            </FormSection>
                            <FieldArray
                                name="buttons"
                                render={({
                                    remove,
                                    push
                                }: FieldArrayRenderProps) => {
                                    return (
                                        <FormSection
                                            title={__(
                                                'messageTemplateForm.buttonsContentForm'
                                            )}
                                            as="card"
                                        >
                                            <Stack>
                                                {formik.values.buttons.map(
                                                    (_, index) => (
                                                        <Inline
                                                            key={index}
                                                            flex={[1]}
                                                            space={4}
                                                        >
                                                            <TextField
                                                                maxLength={25}
                                                                name={`buttons[${index}]`}
                                                            />
                                                            <Button
                                                                theme="link-icon"
                                                                onClick={() =>
                                                                    remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <IconTrash />
                                                            </Button>
                                                        </Inline>
                                                    )
                                                )}
                                            </Stack>
                                            <Button
                                                theme="link-primary"
                                                onClick={() => {
                                                    push(
                                                        formik.values.buttons
                                                            .length === 0
                                                            ? __(
                                                                  'messageTemplateForm.defaultButton'
                                                              )
                                                            : ''
                                                    );
                                                }}
                                                disabled={
                                                    formik.values.buttons
                                                        .length >= 3
                                                }
                                            >
                                                <IconPlus size="medium" />
                                                {__(
                                                    'messageTemplateForm.buttonAddContentForm'
                                                )}
                                            </Button>
                                        </FormSection>
                                    );
                                }}
                            />

                            <FormFooter
                                actions={{
                                    primary: (
                                        <Button
                                            marginBottom={20}
                                            onClick={() =>
                                                formik.handleSubmit()
                                            }
                                            disabled={
                                                !formik.isValid || !formik.dirty
                                            }
                                            loading={formik.isSubmitting}
                                        >
                                            {__(
                                                'messageTemplateForm.buttonSaveContentForm'
                                            )}
                                        </Button>
                                    ),
                                    secondary: (
                                        <Button onClick={handleCancel}>
                                            {__(
                                                'messageTemplateForm.buttonCancelContentForm'
                                            )}
                                        </Button>
                                    )
                                }}
                            />
                        </Stack>
                        <TemplatePreview
                            headerContent={formik.values.headerContent}
                            headerType={formik.values.headerType.value}
                            showHeader={
                                formik.values.headerType.value !== 'none'
                            }
                            bodyText={formik.values.bodyText}
                            showFooter={formik.values.footerText !== ''}
                            footerText={formik.values.footerText}
                            showButtons={formik.values.buttons.length > 0}
                            buttons={formik.values.buttons}
                        />
                    </Inline>
                </Form>
            </FormikProvider>
        </Page>
    );
}
