import classNames from 'classnames';
import { ReactNode } from 'react';
import './WhatsAppHeader.scss';

type Props = {
    showBorder: boolean;
    children: ReactNode;
};

const WhatsAppHeader = ({ children, showBorder }: Props) => {
    return (
        <div
            className={classNames('whats-app-header', {
                'whats-app-header--with-border': showBorder
            })}
        >
            {children}
        </div>
    );
};

export default WhatsAppHeader;
