import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Attendants } from 'api/dashboardApi';
import { EmptyState, Spinner } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import tagsEmptyStateIllustration from 'assets/chart-empty-state.svg';

type Props = {
    attendantsPerQueue: Attendants[];
    loading: boolean;
};
const TicketsPerDepartmentChart = ({ attendantsPerQueue, loading }: Props) => {
    if (loading) {
        return <Spinner block />;
    }

    if (attendantsPerQueue.length === 0) {
        return (
            <EmptyState
                mediaUrl={tagsEmptyStateIllustration}
                size="small"
                title={__('dashboardPage.noData')}
            />
        );
    }

    const chartData: { series: ApexOptions['series']; options: ApexOptions } = {
        series: attendantsPerQueue.map((el) => +el.total),
        options: {
            labels: attendantsPerQueue.map((el) => el.name),
            chart: {
                type: 'donut'
            },
            colors: attendantsPerQueue.map((el) => el.color),
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ],
            title: {
                text: __('dashboardPage.ticketsByDepartment'),
                align: 'left'
            }
        }
    };

    return (
        <div id="chart">
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height={250}
            />
        </div>
    );
};

export default TicketsPerDepartmentChart;
