import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import ChannelsListPage from './pages/ChannelsListPage';

export const ROUTES = [
    {
        path: '/channels',
        element: <ChannelsListPage />,
        errorElement: <ErrorBoundary />
    }
];
