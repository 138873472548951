export const SEGMENTS = [
    'Agro',
    'Alimentação',
    'Clínicas',
    'Comércio',
    'Construção civil',
    'Educação',
    'Franquias',
    'Indústria',
    'MKT Digital',
    'Saúde',
    'Serviços',
    'Varejo',
    'Tecnologia',
    'Transportadora',
    'E-commerce'
];
