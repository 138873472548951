import { format } from 'date-fns/esm';
import { Message } from 'types';
import { parseISO } from 'date-fns/esm';
import { isSameDay } from 'date-fns/esm';
import './DailyTimeStamps.scss';

type Props = {
    index: number;
    message: Message;
    previousMessage: Message | null;
    isLastMessage: boolean;
};

const DailyTimeStamps = ({
    index,
    message,
    isLastMessage,
    previousMessage
}: Props) => {
    if (index === 0) {
        return (
            <span className="daily-time-stamps" key={`timestamp-${message.id}`}>
                <div className="daily-time-stamps__text">
                    {format(parseISO(message.createdAt), 'dd/MM/yyyy')}
                </div>
            </span>
        );
    }
    if (!isLastMessage) {
        const messageDay = parseISO(message.createdAt);

        if (previousMessage) {
            const previousMessageDay = parseISO(previousMessage.createdAt);

            if (!isSameDay(messageDay, previousMessageDay)) {
                return (
                    <span
                        className="daily-time-stamps"
                        key={`timestamp-${message.id}`}
                    >
                        <div className="daily-time-stamps__text">
                            {format(parseISO(message.createdAt), 'dd/MM/yyyy')}
                        </div>
                    </span>
                );
            }
        }
    }
    return (
        <div
            key={`ref-${message.createdAt}`}
            style={{ float: 'left', clear: 'both' }}
        />
    );
};

export default DailyTimeStamps;
