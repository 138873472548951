import { Modal, ModalBody, Spinner } from '@7shifts/sous-chef';
import { useSettings } from 'hooks/useSettings';
import FlowSettingsForm from '../FlowSettingsForm';
import { __ } from 'i18n/translate';

type Props = {
    onClose: () => void;
};

const FlowSettingsModal = ({ onClose }: Props) => {
    const { isLoadingSettings, settings } = useSettings();

    if (isLoadingSettings) {
        return (
            <Modal header={__('settingsPage.flowSettings')}>
                <ModalBody>
                    <Spinner block />
                </ModalBody>
            </Modal>
        );
    }

    return (
        <Modal header={__('settingsPage.flowSettings')} onClose={onClose}>
            <FlowSettingsForm settings={settings} onClose={onClose} />
        </Modal>
    );
};

export default FlowSettingsModal;
