import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import SearchFilter from 'sharedComponents/SearchFilter';
import TemplatesTable from '../components/TemplatesTable';
import TemplatesPageActions from '../components/TemplatesPageActions';

const TemplatesPage = () => {
    return (
        <Page
            title={__('main.templateInfoContact')}
            actions={<TemplatesPageActions />}
            filterBar={<SearchFilter />}
        >
            <TemplatesTable />
        </Page>
    );
};

export default TemplatesPage;
