import { handleError } from 'api/apiUtils';
import { getSettings } from 'api/settingsApi';
import { useEffect, useState } from 'react';
import { Setting } from 'types';

export const useSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState<Setting[]>([]);
    useEffect(() => {
        getSettings()
            .then(setSettings)
            .catch(handleError)
            .finally(() => setIsLoading(false));
    }, []);

    return { isLoadingSettings: isLoading, settings };
};
