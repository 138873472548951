import {
    Button,
    DateField,
    Form,
    FormRow,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextAreaField,
    TimeField,
    toast
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { Schedule } from 'types';
import { FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import ContactSelectField from 'sharedComponents/ContactSelectField';
import { addDays, format } from 'date-fns/esm';
import { useRefetchList } from 'hooks/useRefetchList';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { addSchedule, editSchedule } from 'api/schedulesApi';
import { handleError } from 'api/apiUtils';
import { formatTimeAMPM, parseAMPMTo24hrs } from '../../../../utils/date';

type Props = {
    onClose: () => void;
    editingSchedule?: Schedule;
    initialContactId?: number;
};

const ManageScheduleModal = ({
    onClose,
    editingSchedule,
    initialContactId
}: Props) => {
    const { user } = useAuthContext();
    const { refreshList } = useRefetchList();

    const getInitialValues = () => {
        if (editingSchedule) {
            const [date, time] = editingSchedule.sendAt.split(' ');
            return {
                contact: {
                    value: editingSchedule.contact.id,
                    label: editingSchedule.contact.name
                },
                message: editingSchedule.body,
                date: new Date(date),
                time: formatTimeAMPM(time)
            };
        }
        return {
            contact: null,
            message: '',
            date: addDays(new Date(), 1),
            time: '9:00 AM'
        };
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            const scheduleData = {
                body: values.message,
                contactId: values.contact?.value as number,
                sendAt:
                    format(values.date, 'yyyy-MM-dd') +
                    'T' +
                    parseAMPMTo24hrs(values.time),
                userId: user.id
            };

            const request = editingSchedule
                ? editSchedule(editingSchedule.id, {
                      ...editingSchedule,
                      ...scheduleData
                  })
                : addSchedule(scheduleData);

            request
                .then(() => {
                    toast(
                        editingSchedule
                            ? __('schedulesPage.messageEdited')
                            : __('schedulesPage.messageScheduled')
                    );
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingSchedule
                    ? __('schedulesPage.editSchedule')
                    : __('schedulesPage.addSchedule')
            }
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form stackContent={false} formik={formik}>
                    <ModalBody>
                        <Stack>
                            <ContactSelectField
                                name="contact"
                                label={__('contactsPage.contact')}
                                initialContactId={
                                    editingSchedule?.contact.id ||
                                    initialContactId ||
                                    null
                                }
                                onInitialOptionLoaded={(option) =>
                                    formik.setFieldValue('contact', option)
                                }
                            />
                            <TextAreaField
                                name="message"
                                label={__('schedulesPage.message')}
                            />
                            <FormRow>
                                <DateField
                                    name="date"
                                    label={__('schedulesPage.date')}
                                />
                                <TimeField
                                    name="time"
                                    label={__('schedulesPage.time')}
                                />
                            </FormRow>
                        </Stack>
                    </ModalBody>
                    <ModalFooter
                        actions={{
                            primary: (
                                <Button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    loading={formik.isSubmitting}
                                >
                                    {editingSchedule
                                        ? __('schedulesPage.editSchedule')
                                        : __('schedulesPage.addSchedule')}
                                </Button>
                            ),
                            secondary: (
                                <Button onClick={onClose}>
                                    {__('main.cancel')}
                                </Button>
                            )
                        }}
                    />
                </Form>
            </FormikProvider>
        </Modal>
    );
};

export default ManageScheduleModal;
