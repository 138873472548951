import { Setting } from 'types';

type ScheduleTypeSetting = 'queue' | 'company' | 'disabled';
export const getScheduleTypeSetting = (
    settings: Setting[]
): ScheduleTypeSetting => {
    const scheduleType = settings.find((d) => d.key === 'scheduleType');
    if (scheduleType) {
        return scheduleType.value as 'queue' | 'company';
    }
    return 'disabled';
};
