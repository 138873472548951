import { DataTable, DataTableColumn } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useWhatsContext } from '../../../../contexts/WhatsAppContext/WhatsAppContext';
import emptyStateIllustration from 'assets/empty-state.svg';

const ChannelsTable = () => {
    const { whatsApps, loading } = useWhatsContext();

    const COLUMNS: DataTableColumn[] = [
        {
            name: 'name',
            label: __('queuesPage.name'),
            size: 2
        },
        {
            name: 'status',
            label: 'Status',
            size: 0.5
        },
        {
            name: 'number',
            label: __('queuesPage.phoneNumber'),
            size: 1.5
        },
        {
            name: 'session',
            label: '',
            isRightAligned: true
        }
    ];

    return (
        <DataTable
            items={whatsApps}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={loading}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    context: 'female',
                    domainName: __('main.queue')
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default ChannelsTable;
