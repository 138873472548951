import {
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    Pill,
    Tooltip
} from '@7shifts/sous-chef';
import { Schedule } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageScheduleModal from '../../ManageScheduleModal';
import DeleteScheduleModal from '../../DeleteScheduleModal';
import { format } from 'date-fns/esm';

type Props = DataTableCustomComponent<Schedule>;

const TableRow = ({ item }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const actions: DataTableAction[] = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeleting(true)
        }
    ];

    const [date, time] = item.sendAt.split(' ');
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>
                    {item.contact.name}
                </DataTableCell>
                <DataTableCell columnIndex={1}>
                    {item.body.length < 50 ? (
                        item.body
                    ) : (
                        <Tooltip overlay={item.body}>
                            <span>{item.body.slice(0, 40)}...</span>
                        </Tooltip>
                    )}
                </DataTableCell>
                <DataTableCell columnIndex={1}>
                    {format(new Date(date), 'dd/MM/yyyy')} - {time}
                </DataTableCell>
                <DataTableCell columnIndex={1}>
                    {item.status === 'PENDENTE' && (
                        <Pill theme="info">{__('schedulesPage.pending')}</Pill>
                    )}
                    {item.status === 'ENVIADA' && (
                        <Pill theme="success">{__('schedulesPage.sent')}</Pill>
                    )}
                    {item.status === 'AGENDADA' && (
                        <Pill theme="info">
                            {__('schedulesPage.scheduled')}
                        </Pill>
                    )}
                </DataTableCell>
            </DataTableRow>

            {isEditing && (
                <ManageScheduleModal
                    editingSchedule={item}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isDeleting && (
                <DeleteScheduleModal
                    deletingSchedule={item}
                    onClose={() => setIsDeleting(false)}
                />
            )}
        </>
    );
};

export default TableRow;
