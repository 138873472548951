import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    Stack,
    TextField
} from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { handleError } from 'api/apiUtils';
import { useFormik } from 'formik';
import { getSchema } from './schema';
import { addQueueOption, editQueueOption } from 'api/queueOptionsApi';
import { useRefetchList } from 'hooks/useRefetchList';
import { QueueOption } from 'types';

type Props = {
    onClose: () => void;
    queueId: number;
    amountOfOptions?: number;
    editingQueueOption?: QueueOption;
};

const ManageQueueOptionModal = ({
    onClose,
    queueId,
    amountOfOptions = 0,
    editingQueueOption
}: Props) => {
    const { refreshList } = useRefetchList();
    const formik = useFormik({
        initialValues: {
            title: editingQueueOption ? editingQueueOption.title : '',
            message: editingQueueOption ? editingQueueOption.message : ''
        },
        validationSchema: getSchema(),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);

            const optionData = {
                ...values,
                edition: false,
                option: editingQueueOption
                    ? editingQueueOption.option
                    : amountOfOptions + 1,
                queueId,
                parentId: null,
                children: []
            };

            const request = editingQueueOption
                ? editQueueOption(editingQueueOption.id, optionData)
                : addQueueOption(optionData);

            request
                .then(() => {
                    refreshList();
                    onClose();
                })
                .catch(handleError)
                .finally(() => {
                    setSubmitting(false);
                });
        }
    });

    return (
        <Modal
            header={
                editingQueueOption
                    ? __('queuesPage.editOption')
                    : __('queuesPage.addOption')
            }
            onClose={onClose}
            width={400}
        >
            <Form stackContent={false} formik={formik}>
                <ModalBody>
                    <Stack>
                        <TextField
                            name="title"
                            label={__('queuesPage.optionTitle')}
                            placeholder={__('queuesPage.typeOptionTitle')}
                        />
                        <TextField
                            name="message"
                            label={__('queuesPage.optionDescription')}
                            placeholder={__('queuesPage.typeOptionDescription')}
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter
                    actions={{
                        primary: (
                            <Button
                                type="submit"
                                disabled={!formik.isValid || !formik.dirty}
                                loading={formik.isSubmitting}
                            >
                                {editingQueueOption
                                    ? __('queuesPage.editOption')
                                    : __('queuesPage.addOption')}
                            </Button>
                        ),
                        secondary: <Button>{__('main.cancel')}</Button>
                    }}
                />
            </Form>
        </Modal>
    );
};

export default ManageQueueOptionModal;
