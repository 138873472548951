import { Button, Page } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import MessageTemplateTable from '../components/MessageTemplateTable/MessageTemplateTable';
import { useNavigate } from 'react-router-dom';

const MessageTemplatesPage = () => {
    const navigate = useNavigate();
    return (
        <Page
            title={__('main.modelMessages')}
            actions={
                <Button
                    onClick={() => navigate('/message-templates/new')}
                    theme="primary"
                >
                    {__('templatesPage.addModel')}
                </Button>
            }
        >
            <MessageTemplateTable />
        </Page>
    );
};

export default MessageTemplatesPage;
