import { useEffect, useState } from 'react';
import { GetTemplatesResponse, getTemplates } from 'api/templatesApi';
import { handleError } from 'api/apiUtils';
import { AxiosError } from 'axios';
import ManageContactModal from '../ManageContactModal';

type Props = {
    onClose: () => void;
};

const AddContactModal = ({ onClose }: Props) => {
    const [templates, setTemplates] = useState<GetTemplatesResponse>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getTemplates('', 1)
            .then((data) => setTemplates(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setTemplates(undefined);
            })
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return null;
    }

    return (
        <ManageContactModal
            defaultTemplates={templates?.templateExtraInfos}
            onClose={onClose}
        />
    );
};

export default AddContactModal;
