import { useEffect } from 'react';

const useScriptInjection = (
    url: string,
    async: boolean = true,
    defer: boolean = false
) => {
    useEffect(() => {
        console.log('chamou', url);
        console.trace();
        const script = document.createElement('script');

        script.src = url;
        if (async) {
            script.async = async;
        }
        if (defer) {
            script.defer = true;
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url, async, defer]);
};

export default useScriptInjection;
