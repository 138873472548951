import api from '../services/api';
import { QuickMessage } from 'types';

export const getQuickMesssages = (
    companyId: number,
    userId: number
): Promise<QuickMessage[]> => {
    return api
        .get<QuickMessage[]>(
            `/quick-messages/list?companyId=${companyId}&userId=${userId}`
        )
        .then(({ data }) => data);
};

type NewQuickMesssage = { 
    shortcode: string;
    message: string;
    isGlobal: boolean;
};
type QuickMesssageParams = { 
    shortcode: string;
    message: string;
    isGlobal: boolean;
};

export const addQuickMesssage = (
    NewQuickMesssage: NewQuickMesssage
): Promise<QuickMessage> => {
    return api
        .post('/quick-messages', NewQuickMesssage)
        .then(({ data }) => data);
};

export const deleteQuickMesssage = (QuickMesssageId: number): Promise<void> => {
    return api.delete('/quick-messages/' + QuickMesssageId);
};

export const getQuickMesssage = (
    QuickMesssageId: number
): Promise<QuickMessage> => {
    return api
        .get('/quick-messages/' + QuickMesssageId)
        .then(({ data }) => data);
};

export const editQuickMesssage = (
    QuickMesssageId: number,
    params: QuickMesssageParams
) => {
    return api
        .request({
            url: '/quick-messages/' + QuickMesssageId,
            method: 'PUT',
            data: params
        })
        .then((response) => response.data);
};
