import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        title: Yup.string().required(
            __('main.required', { fieldName: __('queuesPage.optionTitle') })
        ),
        message: Yup.string()
    });
};
