import { dateYearMonthDay } from 'utils/date';
import api from '../services/api';
import { endOfWeek, format, startOfWeek } from 'date-fns/esm';

export type TicketsPerDay = { [day: string]: number };

export type Attendants = {
    name: string;
    total: number;
    data: string;
    color: string;
};

export type WeeklyOverview = {
    attendantsPerQueue: Attendants[];
    ticketsPerDay: TicketsPerDay;
    averageAwaiting?: number;
    averageAttendance?: number;
    totalUniqueContactsConversations?: number;
    totalContactsConversations?: number;
};

type APIResponse = {
    attendantsPerDay: Attendants[];
} & WeeklyOverview;

export const getTicketsPerDepartment = (
    week: string,
    userId?: number
): Promise<WeeklyOverview> => {
    const mapTicketsByDepartment = (
        attendantsPerQueue: Attendants[]
    ): Attendants[] => {
        return attendantsPerQueue.reduce((acc, item) => {
            const index = acc.findIndex((el) => el.name === item.name);
            if (index !== -1) {
                const oldItems = acc;
                oldItems[index].total = +oldItems[index].total + +item.total;
                return [...oldItems];
            }
            return [...acc, item];
        }, [] as Attendants[]);
    };

    const mapTicketsPerDay = (
        attendantsPerQueue: Attendants[]
    ): TicketsPerDay => {
        const ticketsPerDay: TicketsPerDay = {
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0
        };

        return attendantsPerQueue.reduce((acc, item) => {
            const day = format(
                new Date(`${item.data} 00:00:00`),
                'EEE'
            ).toLocaleLowerCase();
            console.log(day, item.data);
            acc[day] += +item.total;
            return acc;
        }, ticketsPerDay);
    };

    return api
        .get('/dashboard/weekly', {
            params: {
                date_from: dateYearMonthDay(
                    startOfWeek(new Date(`${week} 00:00:00`))
                ),
                date_to: dateYearMonthDay(
                    endOfWeek(new Date(`${week} 00:00:00`))
                ),
                userId
            }
        })
        .then(({ data }: { data: APIResponse }) => {
            const averageAwaiting =
                data?.averageAwaiting &&
                +(+data?.averageAwaiting / 60).toFixed(2);
            const averageAttendance =
                data?.averageAttendance &&
                +(+data?.averageAttendance / 60).toFixed(2);

            return {
                attendantsPerQueue: mapTicketsByDepartment(
                    data.attendantsPerQueue
                ),
                ticketsPerDay: mapTicketsPerDay(data.attendantsPerDay),
                averageAwaiting:
                    averageAwaiting && averageAwaiting >= 1
                        ? Math.trunc(averageAwaiting)
                        : averageAwaiting,
                averageAttendance:
                    averageAttendance && averageAttendance >= 1
                        ? Math.trunc(averageAttendance)
                        : averageAttendance,
                totalUniqueContactsConversations:
                    data.totalUniqueContactsConversations,
                totalContactsConversations: data.totalContactsConversations
            };
        });
};

export type DashboardCounts = {
    totalAwaiting: number;
    totalAttendance: number;
    totalClosed: number;
};

export const getDashboardCounts = (
    userId?: number
): Promise<DashboardCounts> => {
    return api
        .get('/dashboard/general', {
            params: {
                date_from: dateYearMonthDay(),
                date_to: dateYearMonthDay(),
                userId
            }
        })
        .then(({ data }) => data);
};
