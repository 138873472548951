import { SelectOption } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserSelectField from 'sharedComponents/UserSelectField';

const TicketsFilterUsers = () => {
    const [value, setValue] = useState<SelectOption<number>>();
    const [params, setParams] = useSearchParams();
    const userFilter = params.get('user');

    return (
        <UserSelectField
            label={__('tickets.user')}
            name="user"
            initialuserId={userFilter ? +userFilter : undefined}
            onInitialOptionLoaded={(selectedOption) => setValue(selectedOption)}
            onChange={(selectedOption) => {
                if (!selectedOption) {
                    params.delete('user');
                } else {
                    params.set('user', selectedOption.value.toString());
                }

                setParams(params);
                setValue(selectedOption);
            }}
            value={value}
        />
    );
};

export default TicketsFilterUsers;
