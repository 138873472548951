import { format, parseISO } from 'date-fns/esm';
import { Message } from 'types';
import MarkdownWrapper from '../../MarkdownWrapper/MarkdownWrapper';
import MessageLocation from '../MessageLocation/MessageLocation';
import VCard from '../VCard/VCard';
import QuotedMessage from '../QuotedMessage/QuotedMessage';
import classNames from 'classnames';
import MessageAck from '../MessageAck/MessageAck';
import './MessageContent.scss';
import { Block } from '@material-ui/icons';
import { __ } from 'i18n/translate';

type Props = { message: Message };

const MessageContent = ({ message }: Props) => {
    if (message.fromMe) {
        return <MessageContentFromMe message={message} />;
    }

    if (
        message.mediaType === 'locationMessage' ||
        message.mediaType === 'location'
    ) {
        return <MessageLocation message={message} />;
    }

    if (message.body.includes('BEGIN:VCARD')) {
        return (
            <div
                className={classNames(
                    'message-content__content-item',
                    'message-content__content-item--contact'
                )}
                style={{ marginRight: 0 }}
            >
                <VCard message={message} />
            </div>
        );
    }

    return (
        <div className="message-content__content-item">
            {message.quotedMsg && <QuotedMessage message={message} />}
            <MarkdownWrapper>{message.body}</MarkdownWrapper>
            <span className="message-content__timestamp">
                {message.timestampEditedAt ? `${__('tickets.edited')} ` : ''}
                {format(parseISO(message.createdAt), 'HH:mm')}
            </span>
        </div>
    );
};

const MessageContentFromMe = ({ message }: Props) => {
    const Content = () => {
        if (
            message.mediaType === 'locationMessage' ||
            message.mediaType === 'location'
        ) {
            return <MessageLocation message={message} />;
        }
        if (message.body.includes('BEGIN:VCARD')) {
            <div
                className={classNames(
                    'message-content__content-item',
                    'message-content__content-item--contact'
                )}
            >
                <VCard message={message} />
            </div>;
        }
        if (message.quotedMsg) {
            return <QuotedMessage message={message} />;
        }
        return null;
    };

    return (
        <div
            className={classNames('message-content__content-item', {
                ['message-content__content-item--deleted']: message.isDeleted
            })}
        >
            {message.isDeleted && (
                <Block
                    color="disabled"
                    fontSize="small"
                    className="message-content__deleted-icon"
                />
            )}
            <Content />
            <MarkdownWrapper>{message.body}</MarkdownWrapper>
            <span className="message-content__timestamp">
                {format(parseISO(message.createdAt), 'HH:mm')}
                <MessageAck ack={message.ack} />
            </span>
        </div>
    );
};

export default MessageContent;
