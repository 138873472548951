import {
    Card,
    DateFilter,
    Inline,
    Skeleton,
    Stack,
    Text
} from '@7shifts/sous-chef';
import { useAuthContext } from 'contexts/AuthContext';
import { format, isValid } from 'date-fns/esm';
import { __ } from 'i18n';
import TicketsPerDayChart from 'modules/Dashboard/components/TicketsPerDayChart';
import TicketsPerDepartmentChart from 'modules/Dashboard/components/TicketsPerDepartmentChart';
import { useWeeklyOverview } from 'modules/Dashboard/hooks/useWeeklyOverview';
import { useSearchParams } from 'react-router-dom';

const WeeklyOverview = () => {
    const { user } = useAuthContext();
    const isAdmin = user && user.profile === 'admin';

    const [params, setParams] = useSearchParams();
    const selectedWeek: string = params.has('week')
        ? (params.get('week') as string)
        : format(new Date(), 'yyyy-MM-dd');
    const selectedWeekDate = isValid(new Date(`${selectedWeek} 00:00:00`))
        ? new Date(`${selectedWeek} 00:00:00`)
        : new Date();

    const selectedUserId = params.has('userId')
        ? parseInt(params.get('userId') as string)
        : undefined;

    const { loading: isLoadingWeeklyOverview, data: weeklyOverviewData } =
        useWeeklyOverview(selectedWeek, isAdmin ? selectedUserId : user.id);

    return (
        <Stack>
            <Text mt={40} as="h2">
                {__('dashboardPage.weeklyOverview')}
            </Text>
            <Inline>
                <DateFilter
                    mode="week"
                    value={selectedWeekDate}
                    onChange={(date) => {
                        if (date <= new Date()) {
                            params.set('week', format(date, 'yyyy-MM-dd'));
                            setParams(params);
                        }
                    }}
                />
            </Inline>
            <Inline flex={[2, 1, 1]} alignItems="stretch">
                <Card>
                    <TicketsPerDepartmentChart
                        attendantsPerQueue={
                            weeklyOverviewData?.attendantsPerQueue || []
                        }
                        loading={isLoadingWeeklyOverview}
                    />
                </Card>
                <Card>
                    <div style={{ height: '100%', display: 'flex' }}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            flexGrow={100}
                        >
                            {isLoadingWeeklyOverview && (
                                <Skeleton width={40} height={30} />
                            )}
                            {!isLoadingWeeklyOverview &&
                                weeklyOverviewData?.averageAwaiting !==
                                    undefined && (
                                    <Text as="insight">
                                        {weeklyOverviewData.averageAwaiting} min
                                    </Text>
                                )}
                            {!isLoadingWeeklyOverview &&
                                weeklyOverviewData?.averageAwaiting ==
                                    undefined && <Text as="insight">N/A</Text>}
                            <Text as="h3" alignment="center">
                                {__('dashboardPage.averageWaitingTime')}
                            </Text>
                        </Stack>
                    </div>
                </Card>
                <Card>
                    <div style={{ height: '100%', display: 'flex' }}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            flexGrow={100}
                        >
                            {isLoadingWeeklyOverview && (
                                <Skeleton width={40} height={30} />
                            )}
                            {!isLoadingWeeklyOverview &&
                                weeklyOverviewData?.averageAttendance && (
                                    <Text as="insight">
                                        {weeklyOverviewData.averageAttendance}{' '}
                                        min
                                    </Text>
                                )}
                            {!isLoadingWeeklyOverview &&
                                !weeklyOverviewData?.averageAttendance && (
                                    <Text as="insight">N/A</Text>
                                )}
                            <Text as="h3" alignment="center">
                                {__(
                                    'dashboardPage.averageTicketResolutionTime'
                                )}
                            </Text>
                        </Stack>
                    </div>
                </Card>
            </Inline>
            <Inline flex={[1, 1, 1]} alignItems="stretch">
                <Card>
                    <Stack alignItems="center">
                        {!isLoadingWeeklyOverview &&
                            weeklyOverviewData?.totalContactsConversations && (
                                <Text as="insight">
                                    {weeklyOverviewData.totalContactsConversations}
                                </Text>
                            )}
                        {!isLoadingWeeklyOverview &&
                            !weeklyOverviewData?.totalContactsConversations && (
                                <Text as="insight">N/A</Text>
                            )}
                        <Text as="h3" alignment="center">
                            {__('dashboardPage.ticketsTotal')}
                        </Text>
                    </Stack>
                </Card>
                <Card>
                    <Stack alignItems="center">
                        {!isLoadingWeeklyOverview &&
                            weeklyOverviewData?.totalUniqueContactsConversations && (
                                <Text as="insight">
                                    {
                                        weeklyOverviewData.totalUniqueContactsConversations
                                    }
                                </Text>
                            )}
                        {!isLoadingWeeklyOverview &&
                            !weeklyOverviewData?.totalUniqueContactsConversations && (
                                <Text as="insight">N/A</Text>
                            )}
                        <Text as="h3" alignment="center">
                            {__('dashboardPage.ticketsTotalUnique')}
                        </Text>
                    </Stack>
                </Card>
            </Inline>
            <Inline flex={[1, 1]} alignItems="stretch" mb={60}>
                <Card>
                    <TicketsPerDayChart
                        loading={isLoadingWeeklyOverview}
                        ticketsPerDay={weeklyOverviewData?.ticketsPerDay}
                    />
                </Card>
            </Inline>
        </Stack>
    );
};

export default WeeklyOverview;
