import { handleError } from 'api/apiUtils';
import { listNotes } from 'api/notesApi';
import { useCallback, useEffect, useState } from 'react';
import { Ticket } from 'types';

export const useNotesCount = (ticket: Ticket | undefined) => {
    const [amountOfNotes, setAmountOfNotes] = useState(0);

    const loadNotes = useCallback(() => {
        if (ticket) {
            listNotes({
                contactId: ticket.contactId,
                ticketId: ticket.id
            })
                .then((data) => {
                    setAmountOfNotes(data.length);
                })
                .catch(handleError);
        }
    }, [ticket]);

    useEffect(() => {
        loadNotes();
    }, [loadNotes]);

    return { amountOfNotes, refreshNotesCount: loadNotes };
};
