import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = (isAdding: boolean) => {
    return Yup.object().shape({
        name: Yup.string().required(
            __('main.required', {
                fieldName: __('usersPage.name')
            })
        ),
        profile: Yup.object().required(
            __('main.required', {
                fieldName: __('usersPage.profile')
            })
        ),
        email: Yup.string()
            .email(__('loginPage.emailInvalid'))
            .required(__('loginPage.emailRequired')),
        password: isAdding
            ? Yup.string()
                  .min(5, __('loginPage.passwordTooShort'))
                  .required(
                      __('main.required', {
                          fieldName: __('loginPage.password')
                      })
                  )
            : Yup.string()
    });
};
