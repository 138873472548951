import { Button, IconUserTag } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageQuickMessageModal from '../ManageQuickMessageModal';

const QuickMessagesAction = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconUserTag size="medium" />
                {__('quickMessagesPage.addQuickMessage')}
            </Button>
            {showModal && (
                <ManageQuickMessageModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default QuickMessagesAction;
