export const ACTIONS = {
    'dashboard:view': 'dashboard:view',
    'drawer-admin-items:view': 'drawer-admin-items:view',
    'tickets-manager:showall': 'tickets-manager:showall',
    'user-modal:editProfile': 'user-modal:editProfile',
    'user-modal:editQueues': 'user-modal:editQueues',
    'ticket-options:deleteTicket': 'ticket-options:deleteTicket',
    'contacts-page:deleteContact': 'contacts-page:deleteContact',
    'connections-page:actionButtons': 'connections-page:actionButtons',
    'connections-page:addConnection': 'connections-page:addConnection',
    'connections-page:editOrDeleteConnection':
        'connections-page:editOrDeleteConnection'
} as const;

type Action = keyof typeof ACTIONS;

export const RULES = {
    user: {
        static: [] as Action[]
    },

    admin: {
        static: [
            ACTIONS['dashboard:view'],
            ACTIONS['drawer-admin-items:view'],
            ACTIONS['tickets-manager:showall'],
            ACTIONS['user-modal:editProfile'],
            ACTIONS['user-modal:editQueues'],
            ACTIONS['ticket-options:deleteTicket'],
            ACTIONS['contacts-page:deleteContact'],
            ACTIONS['connections-page:actionButtons'],
            ACTIONS['connections-page:addConnection'],
            ACTIONS['connections-page:editOrDeleteConnection']
        ]
    }
};
