import { format, parseISO } from 'date-fns/esm';
import { Message } from 'types';
import './MessageLocation.scss';
import { __ } from 'i18n';

type Props = {
    message: Message;
};

const MessageLocation = ({ message }: Props) => {
    const messageContent = JSON.parse(message.body); 
    return (
        <div
            style={{ display: 'flex', padding: 5 }}
            className="message-location"
        >
            <img src={"https://selene-producao.s3.sa-east-1.amazonaws.com/location.jpg"} />
            <a target="_blank" href={`https://maps.google.com/maps?q=${messageContent.latitude},${messageContent.longitude}&z=17&hl=pt-BR`}>
                {' ' + __('channelsPage.clickToSeeLocation')}
            </a>
            <span className="message-location__timestamp">
                {format(parseISO(message.createdAt), 'HH:mm')}
            </span>
        </div>
    );
};

export default MessageLocation;
