import { Badge, Button, IconStickyNoteLines } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import './WhatsAppNotesButton.scss';

type Props = {
    onNotesClick: () => void;
    amountOfNotes: number;
};

const WhatsAppNotesButton = ({ onNotesClick, amountOfNotes }: Props) => {
    return (
        <div className="whats-app-notes-button">
            <Button
                theme="link-icon"
                onClick={onNotesClick}
                title={__('tickets.notes')}
            >
                <IconStickyNoteLines />
            </Button>
            {amountOfNotes > 0 && (
                <div className="whats-app-notes-button__badge">
                    <Badge>{amountOfNotes}</Badge>
                </div>
            )}
        </div>
    );
};

export default WhatsAppNotesButton;
