import './ColorDisplay.scss';

type Props = {
    color: string;
};

const ColorDisplay = ({ color }: Props) => {
    return <div className="color-display" style={{ backgroundColor: color }} />;
};

export default ColorDisplay;
