import { DataTable } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TableRow from './TableRow/TableRow';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { handleError } from 'api/apiUtils';
import { listTags } from 'api/tagsApi';
import { Tag } from 'types';
import { useRefetchList } from 'hooks/useRefetchList';
import emptyStateIllustration from 'assets/empty-state.svg';

const TagsTable = () => {
    const [tags, setTags] = useState<Tag[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { refreshToggle } = useRefetchList();

    useEffect(() => {
        listTags()
            .then((data) => setTags(data))
            .catch((err: AxiosError) => {
                handleError(err);
                setTags([]);
            })
            .finally(() => setIsLoading(false));
    }, [refreshToggle]);

    const COLUMNS = [
        {
            name: 'name',
            label: __('tagsPage.name')
        },
        {
            name: 'color',
            label: __('tagsPage.color')
        },
        {
            name: 'contacts',
            label: __('tagsPage.contacts')
        }
    ];

    return (
        <DataTable
            items={tags}
            columns={COLUMNS}
            itemComponent={TableRow}
            showActionMenu
            isLoading={isLoading}
            emptyState={{
                title: __('main.nothingFound'),
                caption: __('main.nothingFoundDescription', {
                    context: 'female',
                    domainName: __('main.tags')
                }),
                image: emptyStateIllustration
            }}
        />
    );
};

export default TagsTable;
