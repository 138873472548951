import openApi from '../services/api';

type NewAccountData = {
    name: string;
    email: string;
    password: string;
    recurrence: 'MENSAL';
    dueDate: Date;
    status: 't';
    campaignsEnabled: boolean;
    planId: number;
};

export const register = (newAccountData: NewAccountData): Promise<void> => {
    return openApi.post('/companies/cadastro', newAccountData);
};
