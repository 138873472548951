import { Button, IconSearch, Inline, TextField } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useSearchField } from 'hooks/useSearchField';
import { useRef } from 'react';
import { useResetFilters } from 'hooks/useResetFilters';
import './SearchFilter.scss';

const SearchFilter = () => {
    const { searchValue, setSearchValue } = useSearchField();

    const searchRef = useRef<HTMLInputElement>(null);
    const resetFilters = useResetFilters(searchRef);

    const hasFilter = searchValue !== '';

    return (
        <Inline space={12}>
            <div className="search-filter__search">
                <TextField
                    name="search"
                    placeholder={__('main.search') + '...'}
                    prefix={<IconSearch size="medium" />}
                    defaultValue={searchValue}
                    onChange={setSearchValue}
                    ref={searchRef}
                />
            </div>
            {hasFilter && (
                <Button theme="link-primary" onClick={resetFilters}>
                    {__('main.resetFilters')}
                </Button>
            )}
        </Inline>
    );
};

export default SearchFilter;
