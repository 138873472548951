import { Avatar, Button, Inline, Stack, Text } from '@7shifts/sous-chef';
import { getContactFromMessage } from '../../MessageDisplay/VCard/domain';
import './MultipleContacts.scss';
import { useState } from 'react';
import ContactsModal from 'modules/Tickets/components/MessageDisplay/ContactsModal';
import { __ } from 'i18n/translate';

type Props = {
    contactsBody: string[];
    onChat: (phoneNumber: string) => void;
};

const MultipleContacts = ({ contactsBody, onChat }: Props) => {
    const [showContactsModal, setShowContactsModal] = useState(false);
    const contacts = contactsBody.map((contactMessage) =>
        getContactFromMessage(contactMessage)
    );
    return (
        <div className="multiple-contacts">
            <Stack space={4}>
                <Inline ml={20} mb={20} alignItems="center">
                    <Avatar size="large" />
                    <Stack space={4}>
                        <Text as="h3">
                            {__('tickets.contactAndMoreContacts', {
                                contactName: contacts[0].name,
                                count: contacts.length - 1
                            })}
                        </Text>
                    </Stack>
                </Inline>
                <div className="multiple-contacts__divider" />
                <Button
                    size="full-width"
                    theme="link-primary"
                    onClick={() => setShowContactsModal(true)}
                >
                    {__('tickets.seeAll')}
                </Button>
            </Stack>
            {showContactsModal && (
                <ContactsModal
                    onClose={() => setShowContactsModal(false)}
                    contacts={contacts}
                    onChat={(phoneNumber) => {
                        setShowContactsModal(false);
                        onChat(phoneNumber);
                    }}
                />
            )}
        </div>
    );
};

export default MultipleContacts;
