import * as React from 'react';
import './color-picker.scss';
import {
    Button,
    Dropdown,
    IconCheck,
    IconChevronDown,
    Inline
} from '@7shifts/sous-chef';
import { COLORS } from './constants';
import useOnClickOutside from 'hooks/useOnClickOutside';

type Props = {
    value: string;
    onChange: (value: string) => void;
    colors?: string[];
    disabled?: boolean;
    mt?: number;
};

const ColorPicker = ({
    value,
    onChange,
    disabled,
    colors = COLORS,
    mt = 0
}: Props): React.ReactElement<'div'> => {
    return (
        <div className="color-picker" style={{ marginTop: mt }}>
            <Dropdown
                width={220}
                trigger={
                    <Button
                        type="button"
                        disabled={disabled}
                        id="color-picker-button"
                    >
                        <Inline alignItems="center" space={8}>
                            <div
                                className="color-picker__circle"
                                style={{
                                    background: `#${value}`
                                }}
                            />
                            <IconChevronDown size="small" />
                        </Inline>
                    </Button>
                }
            >
                <ColorOptions
                    colors={colors}
                    onChange={onChange}
                    selectedColor={value}
                    closePane={() => {
                        const button = document.getElementById(
                            'color-picker-button'
                        );
                        if (button) {
                            button.click();
                        }
                    }}
                />
            </Dropdown>
        </div>
    );
};

type ColorOptionsProps = {
    colors: string[];
    onChange: (color: string) => void;
    selectedColor: string;
    closePane: () => void;
};
const ColorOptions = ({
    colors,
    onChange,
    selectedColor,
    closePane
}: ColorOptionsProps) => {
    const ref = React.useRef(null);
    useOnClickOutside(ref, closePane);
    return (
        <div ref={ref}>
            {colors.map((color) => (
                <button
                    type="button"
                    className="color-picker__color-button"
                    onClick={() => {
                        onChange(color);
                        closePane();
                    }}
                    key={`color-picker-color-${color}`}
                >
                    <div
                        className="color-picker__circle"
                        style={{
                            background: `#${color}`
                        }}
                    />
                    {color === selectedColor && (
                        <div className="color-picker__button-check-container">
                            <IconCheck color="#fff" />
                        </div>
                    )}
                </button>
            ))}
        </div>
    );
};

export default ColorPicker;
