/* eslint-disable no-case-declarations */
import { Button, IconDownload } from '@7shifts/sous-chef';
import { Message } from 'types';
import ModalImageCors from '../../ModalImageCors';
import './MessageMedia.scss';

type Props = {
    message: Message;
};

const validateByExtension = (requestedRender: string, extension: string) => {
    if (!extension) {
        return false;
    }
    switch (requestedRender) {
        case 'image':
            const images = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
            return images.indexOf(extension) > -1;
        case 'audio':
            const audios = ['ogg', 'mp3'];
            return audios.indexOf(extension) > -1;
        case 'video':
            const videos = ['mp4', '3gp'];
            return videos.indexOf(extension) > -1;
    }
};

const extractExtensionFromURL = (url: string): string => {
    if (!url) {
        return '';
    }
    try {
        return url.split(/[#?]/)![0].split('.')!.pop()!.trim();
    } catch {
        return '';
    }
};

const MessageMedia = ({ message }: Props) => {
    const extractedExtension = extractExtensionFromURL(message.mediaUrl);
    if (
        message.mediaType.includes('image') ||
        validateByExtension('image', extractedExtension)
    ) {
        return <ModalImageCors imageUrl={message.mediaUrl} />;
    }
    if (
        message.mediaType.includes('audio') ||
        validateByExtension('audio', extractedExtension)
    ) {
        return (
            <audio controls>
                <source src={message.mediaUrl} type="audio/ogg"></source>
            </audio>
        );
    }
    if (
        message.mediaType.includes('video') ||
        validateByExtension('video', extractedExtension)
    ) {
        return (
            <video
                className="message-media__video"
                src={message.mediaUrl}
                controls
            />
        );
    }

    return (
        <>
            <div className="message-media__download">
                <Button href={message.mediaUrl}>
                    <IconDownload size="medium" />
                    Download
                </Button>
            </div>
            <hr />
        </>
    );
};

export default MessageMedia;
