import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import TagsPage from './pages/TagsPage';

export const ROUTES = [
    {
        path: '/tags',
        element: <TagsPage />,
        errorElement: <ErrorBoundary />
    }
];
