import { /*useEffect, */ useState } from 'react';
// import api from '../../../../services/api';
import { Lightbox } from 'react-modal-image';
import './ModalImageCors.scss';
import Portal from 'sharedComponents/Portal';

type Props = {
    imageUrl: string;
};

const ModalImageCors = ({ imageUrl }: Props) => {
    const [isViewingImageModal, setIsViewingImageModal] = useState(false);
    return (
        <>
            <img
                className="modal-image-cors"
                src={imageUrl}
                alt="image"
                onClick={() => setIsViewingImageModal(true)}
            />
            {isViewingImageModal && (
                <Portal>
                    <Lightbox
                        smallSrcSet={imageUrl}
                        medium={imageUrl}
                        large={imageUrl}
                        small={imageUrl}
                        alt="image"
                        // @ts-expect-error It seems it is missing TS Types because it exists
                        onClose={() => setIsViewingImageModal(false)}
                    />
                </Portal>
            )}
        </>
    );
    // console.log("ModalImageCors", imageUrl)
    // const [isViewingImageModal, setIsViewingImageModal] = useState(false);
    // const [fetching, setFetching] = useState(true);
    // const [blobUrl, setBlobUrl] = useState('');

    // useEffect(() => {
    //     if (!imageUrl) return;
    //     const fetchImage = async () => {
    //         const { data, headers } = await api.get(imageUrl, {
    //             responseType: 'blob'
    //         });
    //         const url = window.URL.createObjectURL(
    //             new Blob([data], { type: headers['content-type'] })
    //         );
    //         setBlobUrl(url);
    //         setFetching(false);
    //     };
    //     fetchImage();
    // }, [imageUrl]);

    // return (
    //     <>
    //         <img
    //             className="modal-image-cors"
    //             src={fetching ? imageUrl : blobUrl}
    //             alt="image"
    //             onClick={() => setIsViewingImageModal(true)}
    //         />
    //         {isViewingImageModal && (
    //             <Portal>
    //                 <Lightbox
    //                     smallSrcSet={fetching ? imageUrl : blobUrl}
    //                     medium={fetching ? imageUrl : blobUrl}
    //                     large={fetching ? imageUrl : blobUrl}
    //                     small={fetching ? imageUrl : blobUrl}
    //                     alt="image"
    //                     // @ts-expect-error It seems it is missing TS Types because it exists
    //                     onClose={() => setIsViewingImageModal(false)}
    //                 />
    //             </Portal>
    //         )}
    //     </>
    // );
};

export default ModalImageCors;
