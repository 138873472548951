import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        shortcode: Yup.string().required(
            __('main.required', { fieldName: __('quickMessagesPage.shortcut') })
        ),
        message: Yup.string().required(
            __('main.required', { fieldName: __('quickMessagesPage.message') })
        )
    });
};
