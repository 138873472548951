import { usePortalContainer } from 'hooks/usePortalContainer';
import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
    children: React.ReactNode;
};

const Portal: React.FC<Props> = ({ children }) => {
    const container = usePortalContainer();

    if (!container) {
        return null;
    }
    return ReactDOM.createPortal(children, container);
};

export default Portal;
