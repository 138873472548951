import { QueueSchedule } from 'types';

export const DEFAULT_SCHEDULES: QueueSchedule[] = [
    {
        weekday: 'Segunda-feira',
        weekdayEn: 'monday',
        startTime: '08:00',
        endTime: '18:00'
    },
    {
        weekday: 'Terça-feira',
        weekdayEn: 'tuesday',
        startTime: '08:00',
        endTime: '18:00'
    },
    {
        weekday: 'Quarta-feira',
        weekdayEn: 'wednesday',
        startTime: '08:00',
        endTime: '18:00'
    },
    {
        weekday: 'Quinta-feira',
        weekdayEn: 'thursday',
        startTime: '08:00',
        endTime: '18:00'
    },
    {
        weekday: 'Sexta-feira',
        weekdayEn: 'friday',
        startTime: '08:00',
        endTime: '18:00'
    },
    {
        weekday: 'Sábado',
        weekdayEn: 'saturday',
        startTime: '08:00',
        endTime: '12:00'
    },
    {
        weekday: 'Domingo',
        weekdayEn: 'sunday',
        startTime: '00:00',
        endTime: '00:00'
    }
];
