import * as Yup from 'yup';
import { __ } from 'i18n';
import { SelectOption } from '@7shifts/sous-chef';
import { Queue, WhatsApp } from 'types';

export const getSchema = () => {
    return Yup.object().shape({
        contact: Yup.object().required(
            __('main.required', { fieldName: __('contactsPage.contact') })
        ),
        channel: Yup.object().required(
            __('main.required', { fieldName: __('main.channel') })
        ),
        department: Yup.object().required(
            __('main.required', { fieldName: __('main.queue') })
        )
    });
};

export type FormValues = {
    contact: SelectOption<number> | null;
    channel: SelectOption<WhatsApp>;
    department: SelectOption<Queue> | null;
};
