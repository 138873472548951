import { IconLock, Inline, Tooltip } from '@7shifts/sous-chef';
import classNames from 'classnames';
import { useAuthContext } from 'contexts/AuthContext';
import { __ } from 'i18n/translate';
import { Ticket } from 'types';
import './TicketBadges.scss';

type Props = {
    ticket: Ticket;
};

const TicketBadges = ({ ticket }: Props) => {
    const { user } = useAuthContext();

    const getInitials = (name: string) => {
        const nameArray = name.split(' ');
        if (nameArray.length === 1) {
            return nameArray[0].substring(0, 3);
        }
        return nameArray
            .map((word: string) => word[0])
            .slice(0, 3)
            .join('');
    };

    return (
        <Inline space={4}>
            {user.profile === 'admin' && ticket.user && (
                <Tooltip
                    overlay={__('tickets.talkingWith', {
                        userName: ticket.user.name
                    })}
                >
                    <div
                        className={classNames(
                            'ticket-badges__badge',
                            'ticket-badges__attendant-badge'
                        )}
                    >
                        {getInitials(ticket.user.name)}
                    </div>
                </Tooltip>
            )}
            {ticket.whatsapp && (
                <Tooltip
                    header={ticket.whatsapp.name}
                    overlay={
                        ticket.whatsapp.actualNumber
                            ? `${ticket.whatsapp.actualName} - ${ticket.whatsapp.actualNumber}`
                            : ' '
                    }
                >
                    <div
                        className={classNames(
                            'ticket-badges__badge',
                            'ticket-badges__channel-badge'
                        )}
                    >
                        {getInitials(ticket.whatsapp.name)}
                    </div>
                </Tooltip>
            )}
            {ticket.queue ? (
                <Tooltip overlay={ticket.queue.name}>
                    <div
                        className="ticket-badges__badge"
                        style={{
                            backgroundColor: ticket.queue.color
                        }}
                    >
                        {getInitials(ticket.queue.name)}
                    </div>
                </Tooltip>
            ) : (
                <Tooltip overlay={__('tickets.noQueue')}>
                    <div className="ticket-badges__badge">
                        {getInitials(__('tickets.noQueue'))}
                    </div>
                </Tooltip>
            )}
            {ticket.officialTicket && !ticket.enabledMessages && (
                <Tooltip overlay={__('tickets.conversationNotAllowed')}>
                    <IconLock size="medium" color="grey-400" />
                </Tooltip>
            )}
        </Inline>
    );
};

export default TicketBadges;
