import useScriptInjection from 'hooks/useScriptInjection';
import { useEffect } from 'react';

export const useMetaAuthentication = () => {
    useScriptInjection('https://connect.facebook.net/en_US/sdk.js', true, true);

    useEffect(() => {
        window.fbAsyncInit = function () {
            FB.init({
                appId: '702781048654960',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v19.0'
            });
        };

        const sessionInfoListener = (event: MessageEvent) => {
            if (event.origin !== 'https://www.facebook.com') return;
            try {
                const data = JSON.parse(event.data);
                console.log(data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    window.localStorage.setItem('event', data.event);
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        console.log(phone_number_id);
                        console.log(waba_id);
                        window.localStorage.setItem('waba_id', waba_id);
                        window.localStorage.setItem(
                            'phone_number_id',
                            phone_number_id
                        );
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                        console.log(current_step);
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                console.log('Non JSON Response', event.data);
            }
        };

        window.addEventListener('message', sessionInfoListener);

        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, []);

    const launchWhatsAppSignup = () => {
        // Launch Facebook login
        FB.login(
            function (response) {
                if (response.authResponse) {
                    console.log(response.authResponse);
                    const accessToken = response.authResponse.code;
                    const waba_id = window.localStorage.getItem('waba_id');
                    const phone_number_id =
                        window.localStorage.getItem('phone_number_id');
                    const token = window.localStorage.getItem('token');

                    fetch(`${window.apiUrl}/meta/createWhatsapp`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            waPhoneID: phone_number_id,
                            tokenMeta: accessToken,
                            wabaID: waba_id
                        })
                    }).then((res) => {
                        console.log(res);
                        window.location.reload();
                    });
                } else {
                    console.log(
                        'User cancelled login or did not fully authorize.'
                    );
                }
            },
            {
                config_id: '380185728273228', // configuration ID obtained in the previous step goes here
                response_type: 'code', // must be set to 'code' for System User access token
                override_default_response_type: true,
                extras: {
                    setup: {},
                    sessionInfoVersion: 2
                }
            }
        );
    };

    return { launchWhatsAppSignup };
};
