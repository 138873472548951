import { Button, IconUserPlus } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageTemplatesModal from '../ManageTemplatesModal';

const TemplatesPageActions = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Button theme="primary" onClick={() => setShowModal(true)}>
                <IconUserPlus size="medium" />
                {__('templatesPage.addTemplate')}
            </Button>
            {showModal && (
                <ManageTemplatesModal onClose={() => setShowModal(false)} />
            )}
        </>
    );
};

export default TemplatesPageActions;
