import { Avatar, Button, Inline, Stack, Text } from '@7shifts/sous-chef';
import { getContactFromMessage } from './domain';
import { Contact, Message } from 'types';
import { __ } from 'i18n/translate';
import MultipleContacts from '../MultipleContacts';
import './vcard.scss';
import CreateTicketModal from 'modules/Tickets/components/CreateTicketModal';
import { useState } from 'react';
import { getContactByNumber } from 'api/contactsApi';
import { handleError } from 'api/apiUtils';

type Props = {
    message: Message;
};

const VCard = ({ message }: Props) => {
    const contacts = message.body.split('##');
    const { name, number } = getContactFromMessage(message.body);
    const [isCreatingTicket, setIsCreatingTicket] = useState(false);
    const [contactInfoFromNumber, setContactInfoFromNumber] =
        useState<Contact>();

    const handleChat = (phoneNumber: string) => {
        getContactByNumber(phoneNumber)
            .then((contactInfo) => {
                setContactInfoFromNumber(contactInfo);
                setIsCreatingTicket(true);
            })
            .catch(handleError);
    };

    return (
        <>
            {contacts.length > 1 ? (
                <MultipleContacts
                    contactsBody={contacts}
                    onChat={(phoneNumber) => handleChat(phoneNumber)}
                />
            ) : (
                <div className="vcard">
                    <Stack space={4}>
                        <Inline ml={20} mb={20}>
                            <Avatar size="large" />
                            <Stack space={4}>
                                <Text as="h3">{name}</Text>
                                <Text>{number}</Text>
                            </Stack>
                        </Inline>
                        <div className="vcard__divider" />
                        <Button
                            size="full-width"
                            theme="link-primary"
                            onClick={() => handleChat(number)}
                        >
                            {__('tickets.chat')}
                        </Button>
                    </Stack>
                </div>
            )}
            {isCreatingTicket && contactInfoFromNumber && (
                <CreateTicketModal
                    onClose={() => setIsCreatingTicket(false)}
                    initialContact={contactInfoFromNumber}
                />
            )}
        </>
    );
};

export default VCard;
