import { Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import TagsTable from '../components/TagsTable';
import TagsPageActions from '../components/TagsPageAction';

const TagsPage = () => {
    return (
        <Page title={__('main.tags')} actions={<TagsPageActions />}>
            <TagsTable />
        </Page>
    );
};

export default TagsPage;
