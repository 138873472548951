import { Button, IconUserPlus } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import CreateTicketModal from 'modules/Tickets/components/CreateTicketModal';
import { useState } from 'react';

const NewTicketButton = () => {
    const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] =
        useState(false);
    return (
        <>
            <Button
                theme="hollow"
                onClick={() => setIsCreateTicketModalOpen(true)}
            >
                <IconUserPlus />
                {__('tickets.newTicket')}
            </Button>
            {isCreateTicketModalOpen && (
                <CreateTicketModal
                    onClose={() => setIsCreateTicketModalOpen(false)}
                />
            )}
        </>
    );
};

export default NewTicketButton;
