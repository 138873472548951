import api from '../services/api';
import { Tag } from 'types';

type TagParams = {
    name: string;
    color: string;
};
export const addTag = (params: TagParams) => {
    return api
        .request({
            url: '/tags',
            method: 'POST',
            data: params
        })
        .then((response) => response.data);
};

type AssignTagsToTicketParams = {
    ticketId: number;
    tags: Tag[];
};
export const assignTagsToTicket = (params: AssignTagsToTicketParams) => {
    return api
        .request({
            url: '/tags/sync',
            method: 'POST',
            data: params
        })
        .then((response) => response.data);
};

export const listTags = (): Promise<Tag[]> => {
    return api
        .request({
            url: '/tags/list',
            method: 'GET'
        })
        .then((response) => response.data);
};

export const getTag = (tagId: number): Promise<Tag> => {
    return api.get('/tags/' + tagId).then(({ data }) => data);
};

export const deleteTags = (tagsId: number): Promise<void> => {
    return api.delete('/tags/' + tagsId);
};

export const editTag = (tagId: number, params: TagParams) => {
    return api
        .request({
            url: '/tags/' + tagId,
            method: 'PUT',
            data: params
        })
        .then((response) => response.data);
};
