import { handleError } from 'api/apiUtils';
import { WeeklyOverview, getTicketsPerDepartment } from 'api/dashboardApi';
import { useEffect, useState } from 'react';

export const useWeeklyOverview = (week: string, userId?: number) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<WeeklyOverview>();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = () => {
                getTicketsPerDepartment(week, userId)
                    .then(setData)
                    .catch(handleError)
                    .finally(() => {
                        setLoading(false);
                    });
            };
            fetchData();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [userId, week]);

    return {
        data,
        loading
    };
};
