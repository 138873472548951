import {
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow
} from '@7shifts/sous-chef';
import { QueueOption } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteQueueOptionModal from '../../DeleteQueueOptionModal';
import ManageQueueOptionModal from '../../ManageQueueOptionModal';

type Props = DataTableCustomComponent<QueueOption>;

const TableRow = ({ item }: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const actions = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeleting(true)
        }
    ];
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>{item.title}</DataTableCell>
                <DataTableCell columnIndex={2}>{item.message}</DataTableCell>
            </DataTableRow>
            {isDeleting && (
                <DeleteQueueOptionModal
                    deletingQueueOption={item}
                    onClose={() => setIsDeleting(false)}
                />
            )}
            {isEditing && (
                <ManageQueueOptionModal
                    editingQueueOption={item}
                    queueId={item.queueId}
                    onClose={() => setIsEditing(false)}
                />
            )}
        </>
    );
};

export default TableRow;
