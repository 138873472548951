import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import QuickMessagesPage from './pages/QuickMessagesPage';

export const ROUTES = [
    {
        path: '/quick-messages',
        element: <QuickMessagesPage />,
        errorElement: <ErrorBoundary />
    }
];
