import api from '../services/api';
import { Queue, QueueSchedule } from 'types';

export const getQueues = (): Promise<Queue[]> => {
    return api.get<Queue[]>('/queue').then(({ data }) => data);
};

type QueueData = {
    name: string;
    color: string;
    greetingMessage: string;
    outOfHoursMessage: string;
    schedules: QueueSchedule[];
};
export const addQueue = (newQueue: QueueData): Promise<Queue> => {
    return api.post('/queue', newQueue).then(({ data }) => data);
};

export const editQueue = (id: number, queue: QueueData): Promise<Queue> => {
    return api.put('/queue/' + id, queue).then(({ data }) => data);
};

export const deleteQueue = (queueId: number): Promise<void> => {
    return api.delete('/queue/' + queueId);
};

export const getQueue = (queueId: number): Promise<Queue> => {
    return api.get('/queue/' + queueId).then(({ data }) => data);
};
