import { Button, IconPlus, Page } from '@7shifts/sous-chef';
import { __ } from 'i18n';
import { useEffect, useState } from 'react';
import { getQueue } from 'api/queuesApi';
import { Queue, QueueOption } from 'types';
import { useParams } from 'react-router-dom';
import ManageQueueOptionModal from '../components/ManageQueueOptionModal';
import QueueOptionsTable from '../components/QueueOptionsTable';
import { getQueueOptions } from 'api/queueOptionsApi';
import { useRefetchList } from 'hooks/useRefetchList';

const EditQueueOptionsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingOption, setIsAddingOption] = useState(false);
    const [queue, setQueue] = useState<Queue>();
    const [queueOptions, setQueueOptions] = useState<QueueOption[]>([]);

    const { refreshToggle } = useRefetchList();

    const { id } = useParams();
    useEffect(() => {
        if (!id) {
            throw new Error('No queue ID present');
        }

        getQueue(+id).then((queue) => {
            setQueue(queue);
        });
    }, [id]);

    useEffect(() => {
        if (!id) {
            throw new Error('No queue ID present');
        }
        getQueueOptions(+id).then((options) => {
            setIsLoading(false);
            setQueueOptions(options);
        });
    }, [id, refreshToggle]);

    return (
        <Page
            title={__('queuesPage.queueOptions')}
            breadcrumbs={[
                { label: __('main.queues'), url: '/queues' },
                { label: queue ? queue.name : '', url: '/queues/' + id }
            ]}
            actions={
                <Button theme="primary" onClick={() => setIsAddingOption(true)}>
                    <IconPlus size="medium" />
                    Add option
                </Button>
            }
        >
            <QueueOptionsTable
                isLoading={isLoading}
                queueOptions={queueOptions}
            />
            {isAddingOption && queue && (
                <ManageQueueOptionModal
                    queueId={queue.id}
                    onClose={() => setIsAddingOption(false)}
                    amountOfOptions={queueOptions.length}
                />
            )}
        </Page>
    );
};

export default EditQueueOptionsPage;
