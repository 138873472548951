import api from '../services/api';
import { Note } from 'types';

type AddNotePrams = {
    note: string;
    ticketId: number;
    contactId: number;
};
export const addNote = (params: AddNotePrams) => {
    return api
        .request({
            url: '/ticket-notes',
            method: 'POST',
            data: params
        })
        .then((response) => response.data);
};

export const deleteNote = (id: number) => {
    return api
        .request({
            url: `/ticket-notes/${id}`,
            method: 'DELETE'
        })
        .then((response) => response.data);
};

type ListNotesParams = {
    ticketId: number;
    contactId: number;
};
export const listNotes = (params: ListNotesParams): Promise<Note[]> => {
    return api
        .request({
            url: '/ticket-notes/list',
            method: 'GET',
            params
        })
        .then((response) => response.data);
};
