import { useState, useEffect } from 'react';

/**
 * Used when you need to create a DOM element and remove it after the component unmounts
 */
export const usePortalContainer = (): HTMLElement | undefined => {
    const [container, setContainer] = useState<HTMLElement | undefined>();

    useEffect(() => {
        const containerElement = document.createElement('div');
        containerElement.setAttribute('style', `position: absolute;`);
        document.body?.prepend(containerElement);
        setContainer(containerElement);

        return () => {
            containerElement.remove();
        };
    }, []);

    return container;
};
