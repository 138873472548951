import * as Yup from 'yup';
import { __ } from 'i18n';

export const getSchema = () => {
    return Yup.object().shape({
        contact: Yup.object().required(
            __('main.required', {
                fieldName: __('contactsPage.contact')
            })
        ),
        message: Yup.string()
            .min(
                5,
                __('main.minAmountOfCharacters', {
                    amount: 5
                })
            )
            .required(
                __('main.required', {
                    fieldName: __('schedulesPage.message')
                })
            ),
        date: Yup.date().required(
            __('main.required', {
                fieldName: __('schedulesPage.date')
            })
        ),
        time: Yup.string().required(
            __('main.required', {
                fieldName: __('schedulesPage.time')
            })
        )
    });
};
