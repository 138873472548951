import {
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow
} from '@7shifts/sous-chef';
import { TemplateExtraInfo } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import DeleteTemplateModal from '../../DeleteTemplateModal';
import ManageTemplatesModal from '../../ManageTemplatesModal';

type Props = DataTableCustomComponent<TemplateExtraInfo>;

const TableRow = ({ item }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const actions: DataTableAction[] = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: () => setIsEditing(true)
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeleting(true)
        }
    ];
    return (
        <>
            <DataTableRow actions={actions} onClick={() => setIsEditing(true)}>
                <DataTableCell columnIndex={0}>{item.name}</DataTableCell>
                <DataTableCell columnIndex={1}>{item.value}</DataTableCell>
            </DataTableRow>

            {isEditing && (
                <ManageTemplatesModal
                    editingTemplate={item}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {isDeleting && (
                <DeleteTemplateModal
                    deletingTemplate={item}
                    onClose={() => setIsDeleting(false)}
                />
            )}
        </>
    );
};

export default TableRow;
