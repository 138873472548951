import { Button, IconTimes, Inline, Text } from '@7shifts/sous-chef';
import WhatsAppHeader from '../WhatsAppHeader';
import './WhatsAppSidePane.scss';
import { ReactNode } from 'react';

type Props = {
    onClose: () => void;
    title: string;
    children: ReactNode;
};

const WhatsAppSidePane = ({ onClose, title, children }: Props) => {
    return (
        <div className="whats-app-side-pane">
            <WhatsAppHeader showBorder={false}>
                <Inline alignItems="center" justifyContent="space-between">
                    <Inline alignItems="center" space={12}>
                        <Button theme="link-icon" onClick={onClose}>
                            <IconTimes size="medium" />
                        </Button>
                        <Text emphasis="bold">{title}</Text>
                    </Inline>
                </Inline>
            </WhatsAppHeader>
            <div className="whats-app-side-pane__content">{children}</div>
        </div>
    );
};

export default WhatsAppSidePane;
