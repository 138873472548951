import {
    Avatar,
    Button,
    Card,
    IconEnvelope,
    IconPencil,
    IconPhone,
    Inline,
    Stack,
    Text
} from '@7shifts/sous-chef';
import { Contact } from 'types';
import WhatsAppSidePane from '../WhatsAppSidePane';
import MarkdownWrapper from '../MarkdownWrapper';
import './WhatsAppContactInfoPane.scss';
import { __ } from 'i18n';
import { formmatRelative } from 'utils/date';
import EditContactModal from 'modules/Contacts/components/EditContactModal';
import { useState } from 'react';

type Props = {
    onClose: () => void;
    contact: Contact;
};

const WhatsAppContactInfoPane = ({ onClose, contact }: Props) => {
    const [isEditingContact, setIsEditingContact] = useState(false);
    const extraInfo = (contact.extraInfo || []).filter(
        (info) => info.value !== ''
    );
    return (
        <>
            <WhatsAppSidePane
                title={__('tickets.contactInfo')}
                onClose={onClose}
            >
                <Stack alignItems="center">
                    <Avatar url={contact.profilePicUrl} size="extra-large" />
                    <Stack space={8} alignItems="center">
                        <Text as="h1">{contact.name}</Text>
                        {contact.updatedAt && (
                            <Text as="caption" color="grey-300">
                                {__('channelsPage.lastUpdatedOn', {
                                    date: formmatRelative(
                                        new Date(contact.updatedAt)
                                    )
                                })}
                            </Text>
                        )}
                    </Stack>
                </Stack>
                <Inline space={12} flex={[1]} mb={12}>
                    <Stack space={8}>
                        <Inline space={12}>
                            <IconPhone color="grey-300" size="medium" />
                            <Text as="body" color="grey-300">
                                {contact.number}
                            </Text>
                        </Inline>
                        <Inline space={12}>
                            <IconEnvelope color="grey-300" size="medium" />
                            <Text as="body" color="grey-300">
                                {contact.email || __('tickets.noEmailDefined')}
                            </Text>
                        </Inline>
                    </Stack>
                    <Button
                        theme="link-icon"
                        title={__('tickets.editContact')}
                        onClick={() => setIsEditingContact(true)}
                    >
                        <IconPencil size="medium" />
                    </Button>
                </Inline>
                <div className="whats-app-contact-info-pane__content">
                    <Stack>
                        {extraInfo.length > 0 && (
                            <Stack space={4}>
                                <Text emphasis="bold">
                                    {__('tickets.aditionalInformation')}
                                </Text>
                                {extraInfo.map((info) => (
                                    <Card key={info.id}>
                                        <Text emphasis="bold">{info.name}</Text>
                                        <MarkdownWrapper>
                                            {info.value}
                                        </MarkdownWrapper>
                                    </Card>
                                ))}
                            </Stack>
                        )}
                    </Stack>
                </div>
            </WhatsAppSidePane>
            {isEditingContact && (
                <EditContactModal
                    editingContactId={contact.id}
                    onClose={() => setIsEditingContact(false)}
                />
            )}
        </>
    );
};

export default WhatsAppContactInfoPane;
