import {
    Avatar,
    DataTableAction,
    DataTableCell,
    DataTableCustomComponent,
    DataTableRow,
    IconBullseyeArrow,
    IconCheck,
    Inline,
    Stack,
    Text,
    Tooltip
} from '@7shifts/sous-chef';
import { WhatsApp } from 'types';
import { __ } from 'i18n';
import { useState } from 'react';
import ManageChannelModal from '../../ManageChannelModal';
import ChannelStatus from '../../ChannelStatus';
import DeleteChannelModal from '../../DeleteChannelModal';
import ChannelSessionActions from '../../ChannelSessionActions';
import { editWhatsApp } from 'api/whatsAppApi';
import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt-BR';
import './TableRow.scss';
import { formatRelative } from 'date-fns/esm';
import i18n from 'i18n/i18n';
import QRCodeModal from '../../QRCodeModal';
import ChannelFlowSettingsModal from 'modules/Channels/components/ChannelFlowSettingsModal';

type Props = DataTableCustomComponent<WhatsApp>;

const TableRow = ({ item }: Props) => {
    const [isEditingWhatsApp, setIsEditingWhatsApp] = useState(false);
    const [isEditingFlowSettings, setIsEditingFlowSettings] = useState(false);
    const [isDeletingQueue, setIsDeletingQueue] = useState(false);
    const [isViewingQRCode, setIsViewingQRCode] = useState(false);
    const handleEdit = () => setIsEditingWhatsApp(true);
    const actions: DataTableAction[] = [
        {
            action: 'edit',
            label: __('main.edit'),
            onAction: handleEdit
        },
        {
            action: 'editFlow',
            label: <IconBullseyeArrow />,
            onAction: () => setIsEditingFlowSettings(true),
            buttonProps: {
                title: __('channelsPage.channelFlow')
            },
            showInKebab: false
        },
        {
            action: 'remove',
            label: __('main.delete'),
            onAction: () => setIsDeletingQueue(true)
        },
        {
            action: 'setDefault',
            label: __('channelsPage.setAsDefault'),
            hidden: item.isDefault,
            onAction: () => {
                editWhatsApp(item.id, {
                    isDefault: true,
                    complationMessage: item.complationMessage,
                    greetingMessage: item.greetingMessage,
                    name: item.name,
                    outOfHoursMessage: item.outOfHoursMessage,
                    queueIds: item.queues.map((queue) => queue.id),
                    ratingMessage: item.ratingMessage,
                    flowIdentifier: item.flowIdentifier || '',
                    token: item.token
                });
            }
        }
    ];
    return (
        <>
            <DataTableRow actions={actions}>
                <DataTableCell columnIndex={0}>
                    <Inline alignItems="center" space={12}>
                        {item.isDefault ? (
                            <Tooltip
                                overlay={__('channelsPage.defaultChannel')}
                            >
                                <span>
                                    <Avatar size="medium" color="mint-300">
                                        <IconCheck size="medium" />
                                    </Avatar>
                                </span>
                            </Tooltip>
                        ) : (
                            <div className="whatsapp-table-row__default-empty-spot" />
                        )}
                        <Stack space={4}>
                            <Text emphasis="bold">{item.name}</Text>
                            <Text color="grey-300">
                                {__('channelsPage.lastUpdatedOn', {
                                    date: formatRelative(
                                        new Date(item.updatedAt),
                                        new Date(),
                                        {
                                            locale:
                                                i18n.language === 'en'
                                                    ? enLocale
                                                    : ptLocale
                                        }
                                    )
                                })}
                            </Text>
                        </Stack>
                    </Inline>
                </DataTableCell>
                <DataTableCell columnIndex={1}>
                    <ChannelStatus status={item.status} />
                </DataTableCell>
                <DataTableCell columnIndex={2}>
                    {item.actualNumber}
                </DataTableCell>
                <DataTableCell columnIndex={3}>
                    <Inline>
                        <ChannelSessionActions
                            status={item.status}
                            whatsAppId={item.id}
                            showQRCode={() => setIsViewingQRCode(true)}
                        />
                    </Inline>
                </DataTableCell>
            </DataTableRow>
            {isDeletingQueue && (
                <DeleteChannelModal
                    deletingWhatsApp={item}
                    onClose={() => setIsDeletingQueue(false)}
                />
            )}
            {isEditingWhatsApp && (
                <ManageChannelModal
                    editingWhatsApp={item}
                    onClose={() => setIsEditingWhatsApp(false)}
                />
            )}
            {isEditingFlowSettings && (
                <ChannelFlowSettingsModal
                    onClose={() => setIsEditingFlowSettings(false)}
                    whatsApp={item}
                />
            )}
            {isViewingQRCode && item && (
                <QRCodeModal
                    onClose={() => setIsViewingQRCode(false)}
                    whatsApp={item}
                />
            )}
        </>
    );
};

export default TableRow;
