import { Button, IconUndo } from '@7shifts/sous-chef';
import { __ } from 'i18n/translate';
import ReopenTicketModal from 'modules/Tickets/components/ReopenTicketModal';
import { useState } from 'react';
import { Ticket } from 'types';

type Props = {
    ticket: Ticket;
};

const ReopenTicketButton = ({ ticket }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Button
                theme="link-icon"
                title={__('tickets.reopenTicket')}
                onClick={() => setIsModalOpen(true)}
            >
                <IconUndo size="medium" />
            </Button>
            {isModalOpen && (
                <ReopenTicketModal
                    onClose={() => setIsModalOpen(false)}
                    ticket={ticket}
                />
            )}
        </>
    );
};

export default ReopenTicketButton;
